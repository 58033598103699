<template>
    <nav class="navbar navbar-vertical navbar-expand-xl mt-8 border-top border-600">

        <div id="navbarVerticalCollapse"  class="mt-3">
            <ul class="navbar-nav flex-column my-3">
              <li v-for="item in menuItems" :key="item.title" 
                class="nav-item mt-1">
                <router-link :to="item.link"
                  class="btn btn-outline-secondary btn-sm btn-block border-0 px-0"
                  :class="{'active': activeRoute == item.link}">
                  <div class="d-flex align-items-center">
                    <span class="nav-link-icon"
                    style="width: 2.5rem;">
                      <i v-html="item.icon" class="mr-0"></i>
                    </span>               
                    <span>{{item.title}}</span>
                  </div>
                </router-link>
              </li>
            </ul>
            
            <div class="px-3 px-xl-0">
              <hr class="border-300 my-2">
            </div>

            <b-col sm="12" class="d-flex mt-4">
              <button v-if="saveData"
                class="btn btn-outline-primary btn-sm btn-block"
                disabled>
                  <b-spinner small type="grow"></b-spinner>
                  Сохраняем...
                </button>
              <div v-if="!saveData"
                class="btn btn-outline-secondary btn-sm btn-block"
                @click="savePitchData">
                Сохранить
              </div>
              
            </b-col>
          </div>          

    </nav>

    
</template>

<script>

export default {
  data: () => ({
    saveData: false
  }),

  destroyed() {
    this.savePitchData();
  },
  
  computed: {
    activeRoute() {
      return this.$route.fullPath;
    },

    menuItems() {
      var menu = [
        {
          title: 'О компании',
          icon: '<i class="far fa-building"></i>',
          link: '/new-pitch?builder=company'
        }, {
          title: 'Краткое описание',
          icon: '<i class="far fa-dot-circle"></i>',
          link: '/new-pitch?builder=description'
        }, {
          title: 'Решаемая проблема',
          icon: '<i class="fas fa-exclamation-circle"></i>',
          link: '/new-pitch?builder=problem'
        }, {
          title: 'Предлагаемое решение',
          icon: '<i class="fas fa-check-circle"></i>',
          link: '/new-pitch?builder=solution'
        }, {
          title: 'Целевой рынок',
          icon: '<i class="fas fa-search-dollar"></i>',
          link: '/new-pitch?builder=market'
        }, {
          title: 'Конкурентное окружение',
          icon: '<i class="fas fa-trophy"></i>',
          link: 'new-pitch?builder=competitors'
        }, {
          title: 'Внешнее финансирование',
          icon: '<i class="fas fa-landmark"></i>',
          link: '/new-pitch?builder=investments'
        }, {
          title: 'Каналы сбыта',
          icon: '<i class="fas fa-universal-access"></i>',
          link: '/new-pitch?builder=sales'
        }, {
          title: 'Маркетинговая стратегия',
          icon: '<i class="fas fa-funnel-dollar"></i>',
          link: '/new-pitch?builder=marketing'
        }, {
          title: 'Финансовый прогноз',
          icon: '<i class="fas fa-file-invoice-dollar"></i>',
          link: '/new-pitch?builder=forecast'
        }, {
          title: 'Ключевые этапы',
          icon: '<i class="fas fa-check-double"></i>',
          link: '/new-pitch?builder=milestones'
        }, {
          title: 'Управленческая команда',
          icon: '<i class="fas fa-user-friends"></i>',
          link: '/new-pitch?builder=team'
        }, {
          title: 'Партнеры и Ресурсы',
          icon: '<i class="fas fa-handshake"></i>',
          link: '/new-pitch?builder=resources'
        },
      ]

      return menu
    }
  },
  
  methods: {
    async savePitchData() {
      this.saveData = true;
      await this.$store.dispatch('savePitch');
      this.saveData = false;
    }
  }

}
</script>

<style lang="scss" scoped>
  .btn {
    &.active {
      color: whitesmoke !important;
    }
  }
</style>

