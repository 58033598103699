import Vue from 'vue'
import firebase from 'firebase'



const state = {
  milestonesList: []
}



const getters = {

  getMilestonesList: state => {
    return state.milestonesList;
  },

}



const mutations = {

  fetchMilestonesList(state, payload) {
    state.milestonesList = payload;
  },

  setMilestoneToEdit(state, payload) {
    state.milestoneToEdit = payload
  },

  deleteMilestone(state, id) {
    const index = state.milestonesList.findIndex(item => item.id == id)
    Vue.delete(state.milestonesList, index)
  },

  createMilestone(state, payload) {
    state.milestonesList.push(payload);
  },
  
  updateMilestone(state, payload) {
    const index = state.milestonesList.findIndex(item => item.id == payload.id)
    Vue.set(state.milestonesList, index, payload)
  },
}



const actions = {

  async createMilestone(context, payload) {
    if (!context.getters.getActiveBPlan.length > 0) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const newMilestoneRef = firebase.firestore().collection(`${currentBPlanPath}/milestones`).doc()

    try {
      await newMilestoneRef.set(payload)
      payload.id = newMilestoneRef.id
      payload.path = newMilestoneRef.path

      await context.commit('createMilestone', payload)
    
    } catch (error) {
      window.console.log('Unable to write new milestone to the data-base: ', error)
    }
  },

  async fetchMilestonesList (context, payload = { 'sort': 'desc' }) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const milestonesListQuery = await firebase.firestore().collection(`${currentBPlanPath}/milestones`)
    .orderBy('deadline', payload.sort).get();
    var milestonesList = []
    
    try {
      milestonesListQuery.forEach(milestone => {
        var newMilestone = milestone.data()
        newMilestone.id = milestone.id
        newMilestone.path = milestone.ref.path
        milestonesList.push(newMilestone)
      })
  
      context.commit('fetchMilestonesList', milestonesList)

    } catch (error) {
      window.console.log('Unable to load milestones list from db: ', error)
    }
    
    
  },

  async updateMilestone (context, payload) {
    const milestoneDocRef = firebase.firestore().doc(payload.path);

    delete payload.id;
    delete payload.path;

    try { 
  
      await milestoneDocRef.set(
        payload, 
        {merge: true}
      );

      payload.id = milestoneDocRef.id;
      payload.path = milestoneDocRef.path
      context.commit('updateMilestone', payload)

    } catch (error) {
      window.console.log('Unable to update milestone in db: ', error)
    }
  },

  async deleteMilestone (context, payload) {
    const milestoneDocRef = firebase.firestore().doc(payload.path)
    
    try {
      
      await milestoneDocRef.delete()
      context.commit('deleteMilestone', payload.id)

    } catch (error) {
      window.console.log('Unable to delete milestone from db: ', error)
    }
  },

  // экспорт данных для печати в PDF
  async tableMilestonesPdfData(context) {
    // Оформляем шапку таблицы
    var table = [[
      { text: 'V', style: 'tableHeader'},
      { text: 'Наименование этапа', style: 'tableHeader'},  
      { text: 'Дедлайн', style: 'tableHeader'},  
      { text: 'Ответственный', style: 'tableHeader'}, 
      { text: 'Примечание', style: 'tableHeader'}, 
    ]];
    

    // Получаем данные таблицы
    const rawTableData = context.getters.getMilestonesList;

    const styleCategoryItemTitle = { // стиль для отрбражения названия обычных строк
      fontSize: 10,
      margin: [5, 0, 0, 0],
    }

    const styleCategoryItem = { // стиль для отрбражения прочих данны обычных строк
      fontSize: 9,
      margin: [5, 0, 0, 0],
    }

    const styleCategoryDesc = { // стиль для отрбражения прочих данны обычных строк
      fontSize: 7,
      margin: [5, 0, 0, 0],
    }

    // Переформатируем данные под формат для печати PDF
    rawTableData.map(item => {
     
      table.push([
        { 
          text: item.finished ? 'v' : ' ', 
          style: styleCategoryItem,
          fillColor: 'white',
          alignment: 'center',
        }, { 
          text: item.title, 
          style: styleCategoryItemTitle,
          fillColor: 'white'
        }, { 
          text: item.deadline, 
          style: styleCategoryItem,
          fillColor: 'white'
        }, { 
          text: item.person, 
          style: styleCategoryItemTitle,
          fillColor: 'white'
        }, { 
          text: item.desc, 
          style: styleCategoryDesc,
          fillColor: 'white'
        }
      ]);
    })

    return ({ tableData: table, widths: [10, '*', 50, 90, 150] })
  },
}



export default {
    state, getters, mutations, actions
  }