<template>
  <main class="main" id="top">
    <div class="container d-none d-lg-block" data-layout="container">
      <top-menu />
      <!-- Вертикальное меню -->
      <!-- <main-vertical-menu /> -->
      <component :is="verticalMenu">

      </component>
      <!-- <forecast-vertical-menu /> -->

      <perfect-scrollbar class="scroll-area pr-3" style="margin-left: 18rem;">
        <router-view></router-view>
      </perfect-scrollbar>
          
    </div>
    


    <!-- ИНФОРМАЦИЯ ПРИ ЗАХОДЕ С МОБИЛЬНИКА -->
    <div class="container d-block d-lg-none">
      <nav class="navbar navbar-light navbar-glass navbar-top position-fixed navbar-expand-lg navbar-glass-shadow pb-0 w-100 border-bottom">
        <router-link class="navbar-brand fs-4 ml-1 mr-6" to="/" exact>
          <div class="d-flex align-items-center text-primary mt-2">
            <img 
            class="mr-1" 
            src="@/assets/img/illustrations/bplan-logo.png" 
            style="filter: contrast(150%);" alt="" width="35" />
            <span class="text-white font-proxima">
              ТВОЙ
              <span class="text-800" 
                style="font-size: 1rem;">
                бизнес-план
              </span> 
            </span>
          </div>
        </router-link>
      </nav>
      <div class="card-body overflow-hidden mt-8 p-0">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 text-center">
            <h4 class="mb-5">
              Необходим настольный ПК!
            </h4>
          </div>
          <div class="col-12">
            <img class="img-fluid" src="@/assets/img/illustrations/5.svg" alt="">
          </div>
          <div class="col-12 text-center d-none">
            <p class="mt-5 font-weight-normal">
              На данный момент для работы необходим настольный компьютер, возможность работы с мобельного появится немного позже.  
            </p>
            <p>
              Перейти в раздел 
              <a href="https://articles.your-plan.ru">
                "Публикации"
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <app-footer class="mt-0" />
  </main>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import TopMenu from '../app/TopMenu'
import MainVerticalMenu from '../app/widgets/MainLayout/MainVerticalMenu'
import FinanceVerticalMenu from '../app/VerticalMenus/FinanceVerticalMenu'
import BPlanOutlineMenu from '../app/VerticalMenus/BPlanOutlineMenu'
import EditOutlineMenu from '../app/VerticalMenus/OutlineEditVerticalMenu';
import ProjectsVerticalMenu from '../app/VerticalMenus/ProjectsVerticalMenu';
import MilestonesVerticalMenu from '../app/VerticalMenus/MilestonesVerticalMenu';
import PitchVerticalMenu from '../app/VerticalMenus/PitchVerticalMenu';
import Underconstruction from '../app/VerticalMenus/UnderconstructionVerticalMenu';

import AppFooter from '../../views/Footer'

export default {
  data: () => ({
    navOpen: false,
    activeMenu: 0,
    showMenu: 'EditOutlineMenu'
  }),
  components: {
    TopMenu,
    MainVerticalMenu,
    FinanceVerticalMenu,
    BPlanOutlineMenu,
    EditOutlineMenu,
    ProjectsVerticalMenu,
    AppFooter,
    VuePerfectScrollbar,
    MilestonesVerticalMenu,
    PitchVerticalMenu,
    Underconstruction
  },
  async created() {
    await this.$store.dispatch('getUserInfo');
    await this.$store.dispatch('fetchActiveBPlan');
    await this.$store.dispatch('fetchAllFinancialData');
    const userInfo = this.$store.getters.userInfo;
    if (userInfo) {
      /* Проверяем была ли завершена регистрация,
      если нет, то отправляем на страницу регистрации */
      if (!userInfo.regCompleted) {
        this.$router.push('/registration');
      }
    }
  },

  computed: {
    userInfo() {
      var userInfo = this.$store.getters.userInfo
      if (userInfo) {
        return userInfo
      } else {
        return {firstName: '-'}
      }
    },
    // Отображение бокового меню в зависимости от местоположения
    verticalMenu() {
      switch (this.$route.meta.menu) {
        case 'bplan':
          return 'BPlanOutlineMenu';
        case 'outline':
          return 'EditOutlineMenu';
        case 'finance':
          return 'FinanceVerticalMenu';
        case 'projects':
          return 'ProjectsVerticalMenu';
        case 'milestones':
          return 'MilestonesVerticalMenu';
        case 'pitch':
          return 'PitchVerticalMenu';
        default:
          return 'Underconstruction';
      }
    }
  },
  watch: {
    navOpen() {
      this.navOpen ? document.body.style = 'overflow: hidden;' : document.body.style = 'overflow: none;'
    }
  },
  methods: {
    toggleNav(activeMenu) {
      if (activeMenu != 0) {
        this.navOpen = !this.navOpen
      } else {
        this.navOpen = false
      }
      
      if (this.activeMenu == activeMenu) {
        this.activeMenu = 0
      } else {
        this.activeMenu = activeMenu
      }
    },
    async logout() {
      this.toggleNav(0)
      await this.$store.dispatch('logout')
      this.$router.push('/logout')
    }
  }
  
}
</script>

<style lang="scss">

main {
  margin-top: 80px;
  margin-bottom: 60px;
}

.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 180px);
}

.second-nav {
  border-radius: 3px;
  color: #5e6e82 !important;

  &:hover {
    color: #d8e2ef!important;
    background: #5e6e82!important;
  }

  &.router-link-active {
    background: #4d5969!important;
    color: #d8e2ef!important;
  }

  &.disabled {
    color: #344050!important;
  }
}


.search-box {
  &.short {
    width: 13rem;
  }
}

.category {
  font-family: "AvenirNext-Medium-Mint","Helvetica Neue",Helvetica,Arial,sans-serif;
  margin-left: 1rem;
  font-size: 12px;
  font-weight: 500;
  color: rgb(129, 149, 168);
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    color: #d8e2ef;
    font-size: 0.8rem;
    text-decoration: none;
    padding-bottom: 10px;
    border-bottom: 1px solid #2c7ce5;
  }

  &.router-link-active {
    color: whitesmoke ;
    font-size: 0.8rem;
    padding-bottom: 12px;
    border-bottom: 2px solid #2c7ce5;
  }
}

@media (max-width: 600px) {
  footer {
    height: 75px !important;
  }

  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }

  .right-nav {
    border-bottom: 1px solid lightgray;
    margin-top: 0.5rem;
   
  }

  .right-nav-item {
    border-top: 1px solid lightgray;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .category {
    font-size: 1rem;
    color: #999;
    &.router-link-active {
      color: #333;
  }
  }
}

@media (max-width: 1199px) {
  .right-nav {
    width: 100%;
  }
}
</style>

