<template>
  <div class="">
    <nav class="navbar navbar-light navbar-glass navbar-top position-fixed navbar-expand-lg navbar-glass-shadow pb-0 w-100" style="max-width: 1250px;">
      <router-link class="navbar-brand fs-4 ml-1 mr-6" to="/" exact>
        <div class="d-flex align-items-center text-primary mt-2">
          <img 
          class="mr-1" 
          src="@/assets/img/illustrations/bplan-logo.png" 
          style="filter: contrast(150%);" alt="" width="35" />
          <span class="text-white font-proxima">
            ТВОЙ
            <span class="text-800" 
              style="font-size: 1rem;">
              бизнес-план
            </span> 
          </span>
        </div>
      </router-link>

      <router-link v-if="!this.$route.fullPath.includes('/projects')"
        to="/projects"
        class="btn btn-outline-secondary btn-sm btn-block bplans-menu">
        {{ activeBPlan ? activeBPlan.title : '' }}
      </router-link>
      <div class="d-flex align-items-center w-100">
        <h4 v-if="this.$route.fullPath.includes('/projects')"
          class="navbar-nav d-flex mr-auto font-proxima"></h4>
        <ul v-if="!this.$route.fullPath.includes('/projects')" 
          class="navbar-nav d-flex mr-auto">
          <li class="navbar-item right-nav-item mr-1" @click="toggleNav(0)">
            <router-link 
              to="/pitch" 
              class="category">
              <i class="fas fa-desktop fa-lg mr-1"></i>
              Презентация
            </router-link>
          </li>
          <li class="navbar-item right-nav-item mr-1" 
            @click="showMenu = 'PlanOutlineMenu'">
            <router-link 
              to="/bplan"
              class="category">
              <i class="fas fa-layer-group mr-1 fa-lg"></i>
              Бизнес-план
            </router-link> 
          </li>
          <li class="navbar-item right-nav-item mr-1" @click="toggleNav(0)">
            <router-link 
              to="/finance" 
              class="category">
              <i class="far fa-chart-bar fa-lg"></i>
              Финансы
            </router-link> 
          </li>
          <li class="navbar-item right-nav-item mr-1" @click="toggleNav(0)">
            <router-link 
              to="/milestones"
              class="category">
              <i class="fas fa-check-double mr-1 fa-lg"></i>
              Ключевые этапы
            </router-link> 
          </li>
          <li class="navbar-item right-nav-item mr-1" @click="toggleNav(0)">
            <router-link 
              to="/dashboard"
              class="category">
              <i class="fas fa-project-diagram mr-1 fa-lg"></i>
              Мониторинг
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav pr-3">    
          <li class="nav-item dropdown">
            <a class="nav-link pr-0" 
              id="navbarDropdownUser" 
              href="#" 
              role="button" 
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false">
              <div class="avatar avatar-xl rounded-soft fs-0 text-center bg-300">
                <a class="align-middle">
                  {{userInfo.firstName.substr(0,2)}}
                </a> 
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right py-0" aria-labelledby="navbarDropdownUser">
              <div class="bg-white rounded-soft py-2">
                <a class="dropdown-item font-weight-bold text-warning" href="#!">
                  <span class="fas fa-crown mr-1"></span>
                  <span> {{userInfo.firstName}} {{userInfo.lastName}}</span>
                </a>

                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/user-profile">
                  Данные профиля
                </router-link>
                <a class="dropdown-item" href="#!">Обратная связь</a>

                <div class="dropdown-divider"></div>
                <button class="dropdown-item" @click="logout">Выйти</button>
              </div>
            </div>
          </li>
        </ul> 
      </div>
    </nav>

    
    <div v-if="this.$route.fullPath.includes('/bpl')"
      class="row mt-0" style="margin-left: 18rem;">
        <div class="d-flex col-12 font-proxima">
          <ul class="nav">
            <li class="nav-item mb-2"
              @click="showMenu = 'PlanOutlineMenu'">
              <router-link 
                class="nav-link second-nav active py-0" 
                to="/bplan" exact>
                Обзор
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2"
              @click="showMenu = 'EditOutlineMenu'">
              <router-link 
                class="nav-link second-nav py-0" 
                to="/bplan/outline">
                Структура
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2">
              <router-link class="nav-link second-nav py-0" to="/bplan/download">
                Загрузить & Распечатать
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2">
              <a class="nav-link second-nav py-0 disabled" href="#">
                Комментарии
              </a>
            </li>
          </ul>
        </div>
    </div>

     <div v-if="this.$route.fullPath.includes('/fin')"
      class="row mt-0" style="margin-left: 18rem;">
        <div class="d-flex col-12 font-proxima">
          <ul class="nav">
            <li class="nav-item border-600 border-right mb-2 pr-2"
              @click="showMenu = 'PlanOutlineMenu'">
              <router-link 
                class="nav-link second-nav active py-0" 
                to="/finance" exact>
                Финансовые данные
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2"
              @click="showMenu = 'EditOutlineMenu'">
              <router-link 
                class="nav-link second-nav py-0" 
                to="/finance/profit-and-loss">
                Доходы и Расходы
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2">
              <router-link 
                class="nav-link second-nav py-0" 
                to="/finance/cash-flow">
                Движение денег
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2">
              <router-link class="nav-link second-nav py-0" to="/finance/balance">
                Баланс
              </router-link>
            </li>
          </ul>
        </div>
    </div>

    <div v-if="this.$route.fullPath.includes('pitch')"
      class="row mt-0" style="margin-left: 18rem;">
        <div class="d-flex col-12 font-proxima">
          <ul class="nav">
            <li class="nav-item ml-2 mb-2">
              <router-link 
                class="nav-link second-nav py-0" 
                to="/new-pitch?builder=about">
                Редактирование
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2">
              <router-link to="#"
                class="nav-link second-nav py-0">
                Публикация
              </router-link>
            </li>
            <li class="nav-item ml-2 mb-2">
              <router-link  to="#"
                class="nav-link second-nav py-0">
                Предоставить доступ
              </router-link>
            </li>
          </ul>
        </div>
    </div>
  </div>
    
</template>

<script>
export default {
  data: () => ({
    navOpen: false,
    activeMenu: 0,
    showMenu: 'MainVerticalMenu'
  }),

  async beforeMount() {
    if (!this.$store.getters.getActiveBPlan) {
      await this.$store.dispatch('fetchActiveBPlan');
      if (!this.$store.getters.getActiveBPlan) {
        this.$router.push('/projects');
      }
    }
  },

  computed: {
    userInfo() {
      var userInfo = this.$store.getters.userInfo
      if (userInfo) {
        return userInfo
      } else {
        return {firstName: '-'}
      }
    },

    activeBPlan() {
      return this.$store.getters.getActiveBPlan;
    },
  },

  watch: {
    navOpen() {
      this.navOpen ? document.body.style = 'overflow: hidden;' : document.body.style = 'overflow: none;'
    }
  },

  methods: {
    toggleNav(activeMenu) {
      if (activeMenu != 0) {
        this.navOpen = !this.navOpen
      } else {
        this.navOpen = false
      }
      
      if (this.activeMenu == activeMenu) {
        this.activeMenu = 0
      } else {
        this.activeMenu = activeMenu
      }
    },
    async logout() {
      this.toggleNav(0)
      await this.$store.dispatch('logout')
      this.$router.push('/logout')
    }
  }
  

}
</script>

<style lang="scss" scoped>
.bplans-menu {
  position: absolute;
  top: 5rem;
  left: 1rem;
  max-width: 16rem;
}


</style>