<template>
  <nav class="navbar navbar-vertical navbar-expand-xl mt-8 border-top border-600">
    <div class="alert alert-light mt-2">
      <h5 class="alert-heading font-proxima font-weight-normal border-bottom mb-2 pb-2">
        Нужна помощь?
      </h5>
      <p class="fs--1 mb-2">
        Наша служба поддержки работает с 10:00 - 20:00 <small>(МСК)</small>
      </p>
      <div class="row no-gutters py-2 border-bottom border-200">
        <div class="col py-1">
          <div class="media align-items-center">
            <div class="avatar avatar-xl mr-3">
              <div class="avatar-name rounded-circle bg-soft-primary text-dark">
                <span class="fs-0 text-primary">
                  <i class="fas fa-envelope-open-text"></i>  
                </span>
              </div>
            </div>
            <div class="media-body">
              <h6 class="mb-0 d-flex align-items-center">
                <a class="text-800 stretched-link" href="mailto:support@your-plan.ru">
                  Напишите нам
                </a>  
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters py-2 border-bottom border-200">
        <div class="col py-1">
          <div class="media align-items-center">
            <div class="avatar avatar-xl mr-3">
              <div class="avatar-name rounded-circle bg-soft-primary text-dark">
                <span class="fs-0 text-primary">
                  <i class="fas fa-mobile-alt"></i>  
                </span>
              </div>
            </div>
            <div class="media-body">
              <h6 class="mb-0 d-flex align-items-center">
                <a class="text-800 stretched-link">
                  8 (495) 660-08-70
                </a>  
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>        
  </nav>

    
</template>

<script>

export default {
  data: () => ({
    isEditing: false
  }),
  created() {
    if (this.currentOutline.length == 0) {
      this.$store.dispatch('fetchCurrentOutline')
    }    
  },
  updated() {
    this.showChapter(0)
  },
  computed: {
    currentOutline() {
       return this.$store.getters.currentOutline
    }
  },
  methods: {
    showChapter(index) {
      this.$store.commit('selectOutlineChapter', index)
    }
  },
  
}
</script>

<style lang="scss" scoped>
  i {
    &.fa-coins {
      position: absolute; 
      left: 10px; 
      top: 50%; 
      transform: translateY(-50%);
      font-size: 1.5rem;
    }
  }
  .btn-light-green {
    background-color: #00d27a1c;
    border-color: #00d27b30;
    &:hover {
      background-color: #00d27a;
      color: whitesmoke;
    }
  }
</style>

