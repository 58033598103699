<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
    
  </div>
</template>

<script>
import EmptyLayout from './components/layouts/EmptyLayout';
import MainLayout from './components/layouts/MainLayout';
import BlogLayout from './components/layouts/BlogLayout';

export default {
  name: 'vue-bplan',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'ТОЛЬКО',
    // all titles will be injected into this template
    titleTemplate: '%s | ТВОЙ бизнес-план!'
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  components: {
    EmptyLayout, MainLayout, BlogLayout
  }
  
}
</script>


