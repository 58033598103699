export const banks = {
    'sberbank': {
        id: 'sberbank',
        title: 'Сбербанк России'
    },
    'vtb': {
        id: 'vtb',
        title: 'Банк "ВТБ"'
    },
    'gazprombank': {
        id: 'gazprombank',
        title: 'Газпромбанк'
    },
    'nkc': {
        id: 'nkc',
        title: 'НКО "НКЦ"'
    },
    'rosselhozbank': {
        id: 'rosselhozbank',
        title: 'Россельхозбанк'
    },
    'alfa-bank': {
        id: 'alfa-bank',
        title: 'Альфа-Банк',
    },
    'bank-otkritie': {
        id: 'bank-otkritie',
        title: 'Банк "ФК Открытие"'
    },
    'mkb': {
        id: 'mkb',
        title: 'МКБ'
    },
    'promsviazbank': {
        id: 'promsviazbank',
        title: 'Промсвязьбанк'
    },
    'unikcredit-bank': {
        id: 'unicredit-bank',
        title: 'ЮниКредит "Банк"'
    },
    'bank-trust': {
        id: 'bank-trust',
        title: 'НБ "Траст"'
    },
    'raifaizenbank': {
        id: 'raifaizenbank',
        title: 'Райфайзенбанк'
    },
    'rosbank': {
        id: 'rosbank',
        title: 'Росбанк'
    },
    'sovkombank': {
        id: 'sovkombank',
        title: 'Совкомбанк'
    },
    'bank-rossia': {
        id: 'bank-rossia',
        title: 'Банк Россия'
    },
    'vbrr': {
        id: 'vbrr',
        title: 'ВБРР'
    },
    'saint-petersburg-bank': {
        id: 'saint-petersburg-bank',
        title: 'Банк "Санкт-Петербург"'
    },
    'citibank': {
        id: 'citibank',
        title: 'Ситибанк'
    },
    'ak-bars': {
        id: 'ak-bars',
        title: 'АК БАРС'
    },
    'mosoblbank': {
        id: 'mosoblbank',
        title: 'МосОблБанк'
    },
    'uralsib-bank': {
        id: 'uralsib-bank',
        title: 'Банк "Уралсиб"'
    },
    'bm-bank': {
        id: 'bm-bank',
        title: 'БМ-Банк'
    },
    'tinkoff-bank': {
        id: 'tinkoff-bank',
        title: 'Тинькофф Банк'
    },
    'pochta-bank': {
        id: 'pochta-bank',
        title: 'Почта Банк'
    },
    'smp-bank': {
        id: 'smp-bank',
        title: 'СМП Банк'
    },
    'novikombank': {
        id: 'novikombank',
        title: 'Новикомбанк'
    },
    'mib': {
        id: 'mib',
        title: 'МИБ'
    },
    'russkiy-standart': {
        id: 'russkiy-standart',
        title: 'Русский Стандарт'
    },
    'peresvet': {
        id: 'peresvet',
        title: 'Пересвет'
    },
    'svyaz-bank': {
        id: 'svyaz-bank',
        title: 'Связь Банк'
    },
    'bank-dom-rf': {
        id: 'bank-dom-rf',
        title: 'Банк ДОМ.ФР'
    },
    'vozrozhdenie': {
        id: 'vozrozhdenie',
        title: 'Возрождение'
    },
    'vostochniy-bank': {
        id: 'vostochniy-bank',
        title: 'Восточный Банк'
    },
    'home-credit-bank': {
        id: 'home-credit-bank',
        title: 'Хоум Кредит Банк'
    },
    'absolut-bank': {
        id: 'absolut-bank',
        title: 'Абсолют Банк'
    },
    'surgutneftegazbank': {
        id: 'surgutneftegazbank',
        title: 'Сургутнефтегазбанк'
    },
    'ubrr': {
        id: 'ubrr',
        title: 'УБРР'
    },
    'ing-bank': {
        id: 'ing-bank',
        title: 'ИНГ Банк'
    },
    'bank-zenit': {
        id: 'bank-zenit',
        title: 'Банк Зенит'
    },
    'nrd': {
        id: 'nrd',
        title: 'НРД'
    },
    'investtorgbank': {
        id: 'investtorgbank',
        title: 'Инвестторгбанк'
    },
    'rnkb': {
        id: 'rnkb',
        title: 'РНКБ'
    },
    'mts-bank': {
        id: 'mts-bank',
        title: 'МТС Банк'
    },
    'renesans-credit': {
        id: 'renesans-credit',
        title: 'Ренесан Кредит'
    },
    'express-volga': {
        id: 'express-volga',
        title: 'Экспресс Волга'
    },
    'otp-bank': {
        id: 'otp-bank',
        title: 'ОТП Банк'
    },
    'credit-europa-bank': {
        id: 'credit-europa-bank',
        title: 'Кредит Европа Банк'
    },
    'roseksimbank': {
        id: 'roseksimbank',
        title: 'Росэксимбанк'
    },
    'transcapitalbank': {
        id: 'transcapitalbank',
        title: 'Транскапиталбанк'
    },
    'rusfinace-bank': {
        id: 'rusfinance-bank',
        title: 'Русфинанс Банк'
    }
}