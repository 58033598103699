import Vue from 'vue'
import firebase from 'firebase'


// ----------------------------------- //
// -------- STATE SECTION ----------- //
const state = {
  revenueList: [],
  directCostsList: [],
  commercialExpnsList: [],
  commonExpnsList: [],
  assetsList: [],
  financingList: [],
  revenueToEdit: null,
  directCostToEdit: null,
  commercialExpnsToEdit: null,
  commonExpnsToEdit: null,
  assetToEdit: null,
  financingToEdit: null,
}
// ----------------------------------- //


// ----------------------------------- //
// -------- GETTERS SECTION ----------- //
const getters = {
  getRevenueToEdit: state => {
    return state.revenueToEdit
  },

  getRevenueList: state => {
    return state.revenueList
  },

  getDirectCostsList: state => {
    return state.directCostsList
  },

  getDirectCostToEdit: state => {
    return state.directCostToEdit
  },

  getCommercialExpnsList: state => {
    return state.commercialExpnsList
  },

  getCommercialExpnsToEdit: state => {
    return state.commercialExpnsToEdit
  },

  getCommonExpnsList: state => {
    return state.commonExpnsList
  },

  getCommonExpnsToEdit: state => {
    return state.commonExpnsToEdit
  },

  getAssetsList: state => {
    return state.assetsList
  },

  getAssetToEdit: state => {
    return state.assetToEdit
  },

  getFinancingToEdit: state => {
    return state.financingToEdit
  },

  getFinancingList: state => {
    return state.financingList
  },

}
// ----------------------------------- //


// ----------------------------------- //
// -------- MUTATIONS SECTION ----------- //
const mutations = {

  fetchRevenueList(state, payload) {
    state.revenueList = payload;
  },

  setRevenueToEdit(state, payload) {
    state.revenueToEdit = payload
  },

  deleteRevenue(state, id) {
    const index = state.revenueList.findIndex(item => item.id == id)
    Vue.delete(state.revenueList, index)
  },

  fetchDirectCostsList(state, payload) {
    state.directCostsList = payload
  },

  createDirectCost(state, payload) {
    state.directCostToEdit = payload
  },
  // Load data after creation into the local list
  createDirectCostDocument(state, payload) {
    state.directCostsList.push(payload)
    // Vue.set(state.directCostsList, state.directCostsList.lenght, payload)
  },

  setDirectCostToEdit(state, payload) {
    state.directCostToEdit = payload
  },

  updateDirectCostDocument(state, payload) {
    const index = state.directCostsList.findIndex(item => item.id == payload.id)
    Vue.set(state.directCostsList, index, payload)
  },

  deleteDirectCost(state, id) {
    const index = state.directCostsList.findIndex(item => item.id == id)
    Vue.delete(state.directCostsList, index)
  },

  fetchCommercialExpnsList(state, payload) {
    state.commercialExpnsList = payload
  },

  createCommercialExpns(state, payload) {
    state.commercialExpnsToEdit = payload
  },
  // Load data after creation into the local list
  createCommercialExpnsDocument(state, payload) {
    state.commercialExpnsList.push(payload)
  },

  setCommercialExpnsToEdit(state, payload) {
    state.commercialExpnsToEdit = payload
  },

  updateCommercialExpnsDocument(state, payload) {
    const index = state.commercialExpnsList.findIndex(item => item.id == payload.id)
    Vue.set(state.commercialExpnsList, index, payload)
  },

  deleteCommercialExpns(state, id) {
    const index = state.commercialExpnsList.findIndex(item => item.id == id)
    Vue.delete(state.commercialExpnsList, index)
  },

  fetchCommonExpnsList(state, payload) {
    state.commonExpnsList = payload
  },

  createCommonExpns(state, payload) {
    state.commonExpnsToEdit = payload
  },
  // Load data after creation into the local list
  createCommonExpnsDocument(state, payload) {
    state.commonExpnsList.push(payload)
  },

  setCommonExpnsToEdit(state, payload) {
    state.commonExpnsToEdit = payload
  },

  updateCommonExpnsDocument(state, payload) {
    const index = state.commonExpnsList.findIndex(item => item.id == payload.id)
    Vue.set(state.commonExpnsList, index, payload)
  },

  deleteCommonExpns(state, id) {
    const index = state.commonExpnsList.findIndex(item => item.id == id)
    Vue.delete(state.commonExpnsList, index)
  },

  setAssetToEdit(state, payload) {
    state.assetToEdit = payload
  },

  createAssetDocument(state, payload) {
    state.assetsList.push(payload)
  },

  fetchAssetsList(state, payload) {
    state.assetsList = payload
  },

  updateAssetDocument(state, payload) {
    const index = state.assetsList.findIndex(item => item.id == payload.id)
    Vue.set(state.assetsList, index, payload)
  },

  deleteAssetDocument(state, id) {
    const index = state.assetsList.findIndex(item => item.id == id)
    Vue.delete(state.assetsList, index)
  },

  setFinancingToEdit(state, payload) {
    state.financingToEdit = payload
  },

  createFinancingDocument(state, payload) {
    state.financingList.push(payload)
  },

  fetchFinancingList(state, payload) {
    state.financingList = payload
  },

  updateFinancingDocument(state, payload) {
    const index = state.financingList.findIndex(item => item.id == payload.id)
    Vue.set(state.financingList, index, payload)
  },

  deleteFinancingDocument(state, id) {
    const index = state.financingList.findIndex(item => item.id == id)

    Vue.delete(state.financingList, index)
  },

}
// ----------------------------------- //


// ----------------------------------- //
//* -------- ACTIONS SECTION ---------- //

const actions = {

  async fetchAllFinancialData(context) {
    if ( context.getters.getRevenueList.length == 0 ) {
      await context.dispatch('fetchRevenueList')
    }
    if ( context.getters.getDirectCostsList.length == 0 ) {
      await context.dispatch('fetchDirectCostsList')
    }
    if ( context.getters.getCommercialExpnsList.length == 0 ) {
      await context.dispatch('fetchCommercialExpnsList')
    }
    if ( context.getters.getCommonExpnsList.length == 0 ) {
      await context.dispatch('fetchCommonExpnsList')
    }
    if ( context.getters.getAssetsList.length == 0 ) {
      await context.dispatch('fetchAssetsList')
    }
    if ( context.getters.getFinancingList.length == 0 ) {
      await context.dispatch('fetchFinancingList')
    }

    return true
  },

  async fetchCurrentBPlan() {
    console.log('запросили активный бизнес-план через fetchCurrentBPlan');
  },
  
  //*      REVENUE PART         *//

  // Create new Income 
  async createRevenueDocument(context, payload) {
    var newRevenueData = null;
    const activeBPlanPath = context.getters.getActiveBPlan.path;    
    const newRevenueRef = firebase.firestore().collection(`${activeBPlanPath}/revenue`).doc()

    switch (payload.type) {
      case 'onlyIncome':
       newRevenueData = {
          title: payload.title,
          type: 'onlyIncome',
          values: {
            0: [null, null, null, null, null, null, null, null, null, null, null, null],
            1: [null, null, null, null, null, null, null, null, null, null, null, null],
            2: [null, null, null, null, null, null, null, null, null, null, null, null],
          }
        }
        break;
      default:
        newRevenueData = {
          title: payload.title,
          type: payload.type,
          values: {
            0: [null, null, null, null, null, null, null, null, null, null, null, null],
            1: [null, null, null, null, null, null, null, null, null, null, null, null],
            2: [null, null, null, null, null, null, null, null, null, null, null, null],
          },
          units: {
            0: [null, null, null, null, null, null, null, null, null, null, null, null],
            1: [null, null, null, null, null, null, null, null, null, null, null, null],
            2: [null, null, null, null, null, null, null, null, null, null, null, null],
          },
          prices: {
            0: [null, null, null, null, null, null, null, null, null, null, null, null],
            1: [null, null, null, null, null, null, null, null, null, null, null, null],
            2: [null, null, null, null, null, null, null, null, null, null, null, null],
          }
        }
        break;
    }
    
    try {
      await newRevenueRef.set(newRevenueData);
    } catch (error) {
      window.console.log('Unable to create new revenue strea: ', error);
    }

    // Добавляем в данные путь до документа и его ID
    newRevenueData.id = newRevenueRef.id;
    newRevenueData.path = newRevenueRef.path;
    // fetch data of the created document to load it into the local memory 
    context.commit('setRevenueToEdit', newRevenueData)

    return newRevenueRef.id;
  },

  // Load list of the incomes streams
  async fetchRevenueList (context) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const revenueListQuery = await firebase.firestore().collection(`${currentBPlanPath}/revenue`).get();
    var revenueList = []
    
    revenueListQuery.forEach(revenue => {
      var payload = revenue.data()
      payload.id = revenue.id
      payload.path = revenue.ref.path
      revenueList.push(payload)
    });

    context.commit('fetchRevenueList', revenueList)
  },

  // Update data
  async updateRevenue (context, payload) {
    const revenueDocRef = firebase.firestore().doc(payload.path)

    try {
     
      switch (payload.type) {
        case 'onlyIncome':
          await revenueDocRef.set({
            title: payload.title,
            values: payload.values
          }, {merge: true})
          break;
        default:
          await revenueDocRef.set({
            title: payload.title,
            values: payload.values,
            units: payload.units,
            prices: payload.prices
          }, {merge: true})
          break;
      }

      context.dispatch('fetchRevenueList')

    } catch (error) {
      window.console.log('Error to Update Revenue Document: ', error)
    }
  },

  // Delete Revenue
  async deleteRevenue (context, payload) {
    const revenueDocRef = firebase.firestore().doc(payload.path)
    
    try {
      
      await revenueDocRef.delete()

      context.commit('deleteRevenue', payload.id)

    } catch (error) {
      window.console.log('Unable to delete revenue document: ', error)
    }
  },


  // * -----------------------------* //
  // *      DIRECT COST PART        * //
  // * ---------------------------- * //
  // Create new Income 
  async createDirectCostDocument(context, payload) {
    if (!context.getters.getActiveBPlan.length > 0) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const newDirectCostRef = firebase.firestore().collection(`${currentBPlanPath}/financeDirectCosts`).doc()

    try {
      await newDirectCostRef.set(payload)
      payload.id = newDirectCostRef.id
      payload.path = newDirectCostRef.path

      await context.commit('createDirectCostDocument', payload)
    
    } catch (error) {
      window.console.log('Unable to create new Direct Cost Document', error)
    }

    return true
  },

  async fetchDirectCostsList (context) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const directCostsListQuery = await firebase.firestore().collection(`${currentBPlanPath}/financeDirectCosts`).get()
    var directCostsList = []
    
    try {
      directCostsListQuery.forEach(directCost => {
        var payload = directCost.data()
        payload.id = directCost.id
        payload.path = directCost.ref.path
        directCostsList.push(payload)
      })
  
      context.commit('fetchDirectCostsList', directCostsList)

    } catch (error) {
      window.console.log('Unable to load Direc Costs list: ', error)
    }
    
    
  },

  async fetchDirecCostToEdit (context, direcCostPath) {
    const direcCostRef = await firebase.firestore().doc(direcCostPath).get()

    try {
      var direcCostDoc = direcCostRef.data()
      direcCostDoc.id = direcCostRef.id
      direcCostDoc.path = direcCostRef.ref.path

      context.commit('fetchIncomeToEdit', direcCostDoc)
      
    } catch (error) {
      window.console.log('Unable to fetch IncomeToEdit: ', error)
    }
  },

  async updateDirectCostDocument (context, payload) {
    const directCostDocRef = firebase.firestore().doc(payload.path)

    delete payload.id
    delete payload.path

    try { 
  
      await directCostDocRef.set(payload, {merge: true})

      payload.id = directCostDocRef.id
      payload.path = directCostDocRef.path
      context.commit('updateDirectCostDocument', payload)

    } catch (error) {
      window.console.log('Unable to update Direct Cost Document: ', error)
    }
  },

  async deleteDirectCostDocument (context, payload) {
    const directCostDocRef = firebase.firestore().doc(payload.path)
    
    try {
      
      await directCostDocRef.delete()

      context.commit('deleteDirectCost', payload.id)

    } catch (error) {
      window.console.log('Unable to delete direct cost document: ', error)
    }
  },


  // * -----------------------------* //
  // *   COMMERCIAL EXPNS SECTION   * //
  // * ---------------------------- * //
  async createCommercialExpnsDocument(context, payload) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const newCommercialExpnsRef = firebase.firestore().collection(`${currentBPlanPath}/financeCommercialExpns`).doc()

    try {
      await newCommercialExpnsRef.set(payload)
      payload.id = newCommercialExpnsRef.id
      payload.path = newCommercialExpnsRef.path

      await context.commit('createCommercialExpnsDocument', payload)
    
    } catch (error) {
      window.console.log('Unable to create new commercial expense Document', error)
    }
  },

  async fetchCommercialExpnsList (context) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const commercialExpnsListQuery = await firebase.firestore().collection(`${currentBPlanPath}/financeCommercialExpns`).get()
    var commercialExpnsList = []
    
    try {
      commercialExpnsListQuery.forEach(expense => {
        var payload = expense.data()
        payload.id = expense.id
        payload.path = expense.ref.path
        commercialExpnsList.push(payload)
      })
  
      context.commit('fetchCommercialExpnsList', commercialExpnsList)

    } catch (error) {
      window.console.log('Unable to load commercial expenses list: ', error)
    }
    
    
  },

  // !TODO: Проверить, необходимость данной функции. В редактирование можно отправлять из текущнго списка (загруженного)
  async fetchCommercialExpnsToEdit (context, commercialExpnsPath) {
    const commercialExpnsRef = await firebase.firestore().doc(commercialExpnsPath).get()

    try {
      var commercialExpnsDoc = commercialExpnsRef.data()
      commercialExpnsDoc.id = commercialExpnsRef.id
      commercialExpnsDoc.path = commercialExpnsRef.ref.path

      context.commit('fetchCommercialExpnsEdit', commercialExpnsDoc)
      
    } catch (error) {
      window.console.log('Unable to fetch commercial expense to edit: ', error)
    }
  },

  async updateCommercialExpnsDocument (context, payload) {
    const commercialExpnsDocRef = firebase.firestore().doc(payload.path)

    delete payload.id
    delete payload.path

    try { 
  
      await commercialExpnsDocRef.set(payload, {merge: true})

      payload.id = commercialExpnsDocRef.id
      payload.path = commercialExpnsDocRef.path
      context.commit('updateCommercialExpnsDocument', payload)

    } catch (error) {
      window.console.log('Unable to update commercial expense document: ', error)
    }
  },

  async deleteCommercialExpnsDocument (context, payload) {
    const commercialExpnsDocRef = firebase.firestore().doc(payload.path)
    
    try {
      await commercialExpnsDocRef.delete()
      context.commit('deleteCommercialExpns', payload.id)
    } catch (error) {
      window.console.log('Unable to delete commercial expense document: ', error)
    }
  },


  // * -----------------------------* //
  // *    COMMON EXPNS SECTION      * //
  // * ---------------------------- * //
  async createCommonExpnsDocument(context, payload) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const newCommonExpnsRef = firebase.firestore().collection(`${currentBPlanPath}/financeCommonExpns`).doc()
    const newCommonExpnsRef2 = firebase.firestore().collection(`${currentBPlanPath}/expenses`).doc()

    try {
      await newCommonExpnsRef.set(payload)
      await newCommonExpnsRef2.set(payload)
      payload.id = newCommonExpnsRef.id
      payload.path = newCommonExpnsRef.path

      await context.commit('createCommonExpnsDocument', payload)
    
    } catch (error) {
      window.console.log('Unable to create new common expense Document', error)
    }
  },

  async fetchCommonExpnsList (context) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const commonExpnsListQuery = await firebase.firestore().collection(`${currentBPlanPath}/financeCommonExpns`).get()
    var commonExpnsList = []
    
    try {
      commonExpnsListQuery.forEach(expense => {
        var payload = expense.data()
        payload.id = expense.id
        payload.path = expense.ref.path
        commonExpnsList.push(payload)
      })
  
      context.commit('fetchCommonExpnsList', commonExpnsList)

    } catch (error) {
      window.console.log('Unable to load common expenses list: ', error)
    }
    
    
  },

  // !TODO: Проверить, необходимость данной функции. В редактирование можно отправлять из текущнго списка (загруженного)
  async fetchCommonExpnsToEdit (context, commonExpnsPath) {
    const commonExpnsRef = await firebase.firestore().doc(commonExpnsPath).get()

    try {
      var commonExpnsDoc = commonExpnsRef.data()
      commonExpnsDoc.id = commonExpnsRef.id
      commonExpnsDoc.path = commonExpnsRef.ref.path

      context.commit('fetchCommonExpnsEdit', commonExpnsDoc)
      
    } catch (error) {
      window.console.log('Unable to fetch common expense to edit: ', error)
    }
  },

  async updateCommonExpnsDocument (context, payload) {
    const commonExpnsDocRef = firebase.firestore().doc(payload.path)

    delete payload.id
    delete payload.path

    try { 
  
      await commonExpnsDocRef.set(payload, {merge: true})

      payload.id = commonExpnsDocRef.id
      payload.path = commonExpnsDocRef.path
      context.commit('updateCommonExpnsDocument', payload)

    } catch (error) {
      window.console.log('Unable to update common expense document: ', error)
    }
  },

  async deleteCommonExpnsDocument (context, payload) {
    const commonExpnsDocRef = firebase.firestore().doc(payload.path)
    
    try {
      
      await commonExpnsDocRef.delete()
      context.commit('deleteCommonExpns', payload.id)

    } catch (error) {
      window.console.log('Unable to delete common expense document: ', error)
    }
  },


  /* ASSET SECTION */

  async createAssetDocument(context, payload) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const newAssetRef = firebase.firestore().collection(`${currentBPlanPath}/assets`).doc()

    try {
      await newAssetRef.set(payload)
      payload.id = newAssetRef.id
      payload.path = newAssetRef.path

      await context.commit('createAssetDocument', payload)
    
    } catch (error) {
      window.console.log('Unable to create new casset Document', error)
    }
  },

  async fetchAssetsList (context) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const assetsListQuery = await firebase.firestore().collection(`${currentBPlanPath}/assets`).get()
    var assetsList = []
    
    try {
      assetsListQuery.forEach(asset => {
        var newAsset = asset.data()
        newAsset.id = asset.id
        newAsset.path = asset.ref.path
        assetsList.push(newAsset)
      })
  
      context.commit('fetchAssetsList', assetsList)

    } catch (error) {
      window.console.log('Unable to load assets list: ', error)
    }
    
    
  },

  async updateAssetDocument (context, payload) {
    const assetsDocRef = firebase.firestore().doc(payload.path)

    delete payload.id
    delete payload.path

    try { 
  
      await assetsDocRef.set(payload, {merge: true})

      payload.id = assetsDocRef.id
      payload.path = assetsDocRef.path
      context.commit('updateAssetDocument', payload)

    } catch (error) {
      window.console.log('Unable to update asset document: ', error)
    }
  },

  async deleteAssetDocument (context, payload) {
    const assetDocRef = firebase.firestore().doc(payload.path)
    
    try {
      
      await assetDocRef.delete()

      context.commit('deleteAssetDocument', payload.id)

    } catch (error) {
      window.console.log('Unable to delete asset document: ', error)
    }
  },

  /* FINANCING SECTION */

  async createFinancingDocument(context, payload) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const newFinancingRef = firebase.firestore().collection(`${currentBPlanPath}/financing`).doc()

    try {
      await newFinancingRef.set(payload)
      payload.id = newFinancingRef.id
      payload.path = newFinancingRef.path

      await context.commit('createFinancingDocument', payload)
    
    } catch (error) {
      window.console.log('Unable to create new financing document', error)
    }
  },

  async fetchFinancingList (context) {
    if (!context.getters.getActiveBPlan) {
      await context.dispatch('fetchActiveBPlan');
    }
    const currentBPlanPath = context.getters.getActiveBPlan.path;
    const financingListQuery = await firebase.firestore().collection(`${currentBPlanPath}/financing`).get()
    var financingList = []
    
    try {
      financingListQuery.forEach(financing => {
        var newItem = financing.data()
        newItem.id = financing.id
        newItem.path = financing.ref.path
        financingList.push(newItem)
      })
      
      context.commit('fetchFinancingList', financingList)

    } catch (error) {
      window.console.log('Unable to load financing list: ', error)
    }
    
    
  },

  async updateFinancingDocument (context, payload) {
    const financingDocRef = firebase.firestore().doc(payload.path)

    delete payload.id
    delete payload.path

    try { 
  
      await financingDocRef.set(payload, {merge: true})

      payload.id = financingDocRef.id
      payload.path = financingDocRef.path
      context.commit('updateFinancingDocument', payload)

    } catch (error) {
      window.console.log('Unable to update financing document: ', error)
    }
  },

  async deleteFinancingDocument (context, payload) {
    const financingDocRef = firebase.firestore().doc(payload.path)
    
    try {
      
      await financingDocRef.delete()

      context.commit('deleteFinancingDocument', payload.id)

    } catch (error) {
      window.console.log('Unable to delete financing document: ', error)
    }
  },
}
// ----------------------------------- //


// ----------------------------------- //
// -------- EXPORT SECTION ----------- //

export default {
    state, getters, mutations, actions
  }