import firebase from 'firebase'
import {incomeCategories, spendingCategories } from '../categoriesList'
 
const state = {
  categories: []
 }

const getters = {
  getCategories: state => {
    return state.categories
  }
}

const mutations = {
  fetchCategories(state, payload) {
    state.categories = payload
  }
}

const actions = {
  async fetchCategories(context) {
    try {
      const uid = await context.dispatch('getUserId')
      var categories = []
      var incCategories, spendCategories = []
      let incomeQuerySnapshop = await firebase.firestore().collection(`users/${uid}/categories`).doc('incomeCategories').get()
      let spendQuerySnapshop = await firebase.firestore().collection(`users/${uid}/categories`).doc('spendingCategories').get()
      
      incCategories = Object.values(incomeQuerySnapshop.data())
      spendCategories = Object.values(spendQuerySnapshop.data())  
      
      categories = [...new Set([...incCategories, ...spendCategories])]

      context.commit('fetchCategories', categories)
    } catch (error) {
      console.log('Ошибка загрузки категорий', error)
      context.commit('setError', error)
      throw error
    }
  },

  async createCategory(context, {userCategories, categoryId, categoryIndex, userCtegoriesIdCount}) {
    try {
      const uid = await context.dispatch('getUserId')
      firebase.firestore().collection(`/users/${uid}/categories`).doc(categoryId)
        .set({
          userCategories,
          userCategoriesIdCount: userCtegoriesIdCount
        },{
          merge: true
        }).then(
          context.commit('createCategory', {
            userCategories: userCategories, 
            index: categoryIndex
        })
      )
    } catch (error) {
      context.commit('setError', error)
      throw error
    }
  },

  async deleteUserCategory(context, {index, categoryId, categoryIndex}) {
    try {
      const uid = await context.dispatch('getUserId')
      var catRef = firebase.firestore().collection(`/users/${uid}/categories`).doc(categoryId);
      var removeCategory = catRef.update({
        [`userCategories.${index}`]: firebase.firestore.FieldValue.delete()
      })
    } catch (error) {
      console.log(error)
    }

    var data = {
      categoryIndex: categoryIndex, 
      userCategoryIndex: index
    }

    context.commit('deleteUserCategory', data)
  },

  async createCategoriesList(context) {
    try {
      const uid = await context.dispatch('getUserId')
      await firebase.firestore().collection(`/users/${uid}/categories`).doc('incomeCategories')
      .set(incomeCategories, {merge: true})
      await firebase.firestore().collection(`/users/${uid}/categories`).doc('spendingCategories')
      .set(spendingCategories, {merge: true})
      
      return true
    } catch (error) {
      console.log('Error to create categories list', error)
    }
  }
}

export default {
  state, getters, mutations, actions
}