import numeral from 'numeral';

const state = {
  tableBalance: []
}

const getters = {
  getTableBalance: state => {
    return state.tableBalance;
  }
}

const mutations = {
  setTableBalance (state, payload) {
    state.tableBalance = payload
  }
}

const actions = {
  // Формирование таблицы Баланса
  async calculateTableBalance(context, payload) {
    
    var tableData = [];

    const nonCurentAssetsData = await context.dispatch('nonCurrentAssets', payload);
    const currentAssestsData = await context.dispatch('currentAssets', payload);

    const assetsSegmentTotal = this._vm.$func.colSum([
      nonCurentAssetsData.rows[0].values,
      currentAssestsData.rows[0].values
    ]);

    tableData.push({
      type: 'segmentTotal',
      title: 'Активы',
      values: assetsSegmentTotal,
    });

    tableData = tableData.concat(nonCurentAssetsData.rows)
    .concat(currentAssestsData.rows);


    const capitalData = await context.dispatch('capital', payload);
    const longTermLiabilitiesData = await context.dispatch('longTermLiabilities', payload);
    const shortTermLiabilitiesData = await context.dispatch('shortTermLiabilities', payload);

    const liabilitiesSegmentTotal = this._vm.$func.colSum([
      capitalData.rows[0].values,
      longTermLiabilitiesData.rows[0].values,
      shortTermLiabilitiesData.rows[0].values
    ]);

    tableData.push({
      type: 'segmentTotal',
      title: 'Пассивы',
      values: liabilitiesSegmentTotal,
    });

    tableData = tableData.concat(capitalData.rows)
    .concat(longTermLiabilitiesData.rows)
    .concat(shortTermLiabilitiesData.rows);

    var balanceData = []
    balanceData.push({
      type: 'categoryTotalResult',
      title: 'Валюта баланса',
      values: assetsSegmentTotal,
    });

    context.commit('setTableBalance', { data: tableData, balance: balanceData })

    return { data: tableData, balance: balanceData }
  },

  // Расчет раздела "Внеоборотных активов"
  async nonCurrentAssets(context, payload) {
    var segmentRows = []
    
    // Фильтруем данные остаточной стоимости актива во времени
    const assets = context.getters.getAssetsList.map(item => {
      return {
        title: item.title,
        values: item.assetCurrentValue
      }
    });

    // Получаем данные для таблицы
    const assetsData = await context.dispatch('balanceSectionRender', {
      list: assets,
      title: 'Основные средства',
      activeYear: payload.activeYear,
    });

    const segmentTotal = this._vm.$func.colSum([
      assetsData.rows[0].values
    ])

    segmentRows.push({
      type: 'categoryTotal',
      title: 'Внеоборотные активы',
      values: segmentTotal,
    }, {
      type: 'categoryItem',
      title: 'Нематериальные активы',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Основные средства',
      values: assetsData.rows[0].values,
    });

    return { rows: segmentRows }
  },

  // Расчет раздела оборотных активов
  async currentAssets(context, payload) {
    var segmentRows = []

    var cash = await context.dispatch('calculateTableCF', { activeYear: payload.activeYear })
    cash = cash.cashBalance[2].values
    if (payload.activeYear > -1) {
      cash.splice(payload.activeYear + 4, 1)
    }
    

    const segmentTotal = this._vm.$func.colSum([
      cash 
    ]);
    
    segmentRows.push({
      type: 'categoryTotal',
      title: 'Оборотные активы',
      values: segmentTotal,
    }, {
      type: 'categoryItem',
      title: 'Запасы',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Дебиторская задолженность',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Денежные средства',
      values: cash,
    }, {
      type: 'categoryItem',
      title: 'Прочие оборотные активы',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    });

    return { rows: segmentRows }
  },

  async capital(context, payload) {
    var segmentRows = []

    const tablePL = await context.dispatch('calculateTablePL', payload)
    var netProfitValues = tablePL.netProfit

    if (payload.activeYear > -1) {
      netProfitValues.splice(payload.activeYear + 3, 1) // Оставили только оботражение по квартала в аквтивном году
    }

    // Выстичвыем результрующий показатель, накопительным итогом
    var cummulativeNetProfit = []
    netProfitValues.map( (item, index) => {
      if (index == 0) {
        cummulativeNetProfit.push(item)
      } else {
        cummulativeNetProfit.push(cummulativeNetProfit[index-1] + item)
      }
      
    });
    
    const segmentTotal = this._vm.$func.colSum([
      cummulativeNetProfit 
    ]);

    segmentRows.push({
      type: 'categoryTotal',
      title: 'Капитал и резервы',
      values: segmentTotal,
    }, {
      type: 'categoryItem',
      title: 'Уставный капитал',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Добавочный капитал ',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Резервный капитал',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Нераспределенная прибыль (непокрытый убыток)',
      values: cummulativeNetProfit,
    });

    return { rows: segmentRows }
  },

  async longTermLiabilities(context, payload) {
    var segmentRows = []

    // Загружаем данные по долгосрочным кредитам
    const longDebtItems = context.getters.getFinancingList
    .filter(function(item) {
      return  item.paymentPeriods > 12
    });

    
    
    const longDebtData = await context.dispatch('balanceSectionRender', {
      list: longDebtItems,
      title: 'Кредиты и займы (основной долг)',
      activeYear: payload.activeYear,
    });

    const segmentTotal = this._vm.$func.colSum([
      longDebtData.rows[0].values 
    ])
    
    segmentRows.push({
      type: 'categoryTotal',
      title: 'Долгосрочные обязательства',
      values: segmentTotal,
    }, {
      type: 'categoryItem',
      title: 'Кредиты и займы (основной долг)',
      values: longDebtData.total,
    }, {
      type: 'categoryItem',
      title: 'Отложенные налоговые обязательства',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Авансы полученные ',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Прочие долгосрочные обязательства',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    });

    return { rows: segmentRows }
  },

  async shortTermLiabilities(context, payload) {
    var segmentRows = []

    // Краткосрочные обязательства по кредитам
    const shortDebtItems = context.getters.getFinancingList
    .filter(function(item) {
      return  item.paymentPeriods < 13
    });

    const shortDebtData = await context.dispatch('balanceSectionRender', {
      list: shortDebtItems,
      title: 'Кредиты и займы (основной долг)',
      activeYear: payload.activeYear,
    });
    
    
    // Краткосрочные обязательства по оплате активов
    const assetsShortDebt = context.getters.getAssetsList.map(item => {
      if (item.paymentType.id == 2 || item.paymentType.id == 3) {
        return {
          title: item.title,
          values: item.debtAmount,
        } 
      } else {
        return []
      }
    });

    const assetsShortDebtData = await context.dispatch('balanceSectionRender', {
      list: assetsShortDebt,
      title: 'Задолженность по оплате активов',
      activeYear: payload.activeYear,
    });

    // Расчитываем итоговое значение по разделу
    const segmentTotal = this._vm.$func.colSum([
      shortDebtData.rows[0].values,
      assetsShortDebtData.rows[0].values,
    ]);
    
    segmentRows.push({
      type: 'categoryTotal',
      title: 'Краткосрочные обязательства',
      values: segmentTotal,
    }, {
      type: 'categoryItem',
      title: 'Кредиты и займы (основной долг)',
      values: shortDebtData.rows[0].values || (payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0]),
    }, {
      type: 'categoryItem',
      title: 'Кредиторская задолженность',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Доходы будущих периодов',
      values: payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0],
    }, {
      type: 'categoryItem',
      title: 'Прочие краткосрочные обязательства',
      values: assetsShortDebtData.rows[0].values,
    });

    return { rows: segmentRows }
  },
  
  balanceSectionRender(context, payload) {
    var list = payload.list || []
    var valueRows = []
    var rows = []

    for (var i = 0; i < list.length; i++) {     
      var qValues = []

      for (var y = 0; y < Object.values(list[i].values).length; y++) {
        // Выбираем значения над которыми работаем
        const values = Object.values(list[i].values)[y]
        // Выбранный год детализируется поквартально
        if (y == payload.activeYear - 1) {  
          qValues.push(values[2]);
          qValues.push(values[5]);
          qValues.push(values[8]);
          qValues.push(values[11]);
        } else {
          qValues.push(values[11]);
        }
      }
       
      const data = {
        type: 'categoryItem',
        title: list[i].title,
        values: qValues
      }

      valueRows.push(qValues)
      rows.push(data)
    }

    var colsSum    
    if (valueRows.length > 1) {
      colsSum = this._vm.$func.colSum(valueRows)
    } else {
      colsSum = payload.activeYear > -1 ? [0,0,0,0,0,0] : [0,0,0]
    }
    
    rows.unshift({
      type: 'categoryTotal',
      title: payload.title,
      values: colsSum
    })
    
    return {rows, total: colsSum}
  },

  async tableBalancePdfData(context) {
    const activeBPlan = context.getters.getActiveBPlan;
    // Инициируем таблицу с готовым заголовком
    var table = [[
      { text: 'Наименование', style: 'tableHeader'}, 
      { text: [`${activeBPlan.startYear}`, {text: ' год', fontSize: 8}], style: 'tableHeader' }, 
      { text: [`${activeBPlan.startYear + 1}`, {text: ' год', fontSize: 8}], style: 'tableHeader' },
      { text: [`${activeBPlan.startYear + 2}`, {text: ' год', fontSize: 8}], style: 'tableHeader' },
    ]];

    // Получаем данные таблицы
    const rawTableData = await context.dispatch('calculateTableBalance', { activeYear: -1 })

     // Задаем стили для каждого типа отображаемых строк
     const styleSegmentTotalTitle = { // стиль для отображения строк с названиями разделов таблицы
      fontSize: 11,
      bold: true,
      fillColor: '#edeff3',
    }

    const styleSegmentTotaltValue = { // стиль для отображения значений разделов таблицы
      fontSize: 11,
      bold: true,
      alignment: 'right',
      fillColor: '#edeff3'

    }

    const styleCategoryTotalTitle = { // стиль для отрбражения названия строк с итогами категории
      fontSize: 9,
      bold: true,
      fillColor: '#f9fafd'
    }

    const styleCategoryTotalValue = { // стиль для отображения итоговых значений категории
      fontSize: 9,
      bold: true,
      alignment: 'right',
      fillColor: '#f9fafd'
    }
    
    
    const styleCategoryItemTitle = { // стиль для отрбражения названия обычных строк
      fontSize: 9,
      // margin: [5, 0, 0, 0],
    }

    const styleCategoryItemValue = { // стиль для отрбражения значений обычных строк
      fontSize: 9,
      alignment: 'right',
    }

    const styleCategoryTotalResultFooterTitle = { // стиль для отображения названия итогов таблицы
      fontSize: 11,
      bold: true,
      fillColor: '#d8e2ef'
    };

    const styleCategoryTotalResultFooterValue = {  // стиль для отображения значений итогов таблицы
      fontSize: 11,
      bold: true,
      alignment: 'right',
      fillColor: '#d8e2ef'
    };


    // Определяем временные переменные для хранения стилей
    var styleTitle, styleValue;

    // Переформатируем данные под формат для печати PDF
    rawTableData.data.map(item => {
      // Переключаем временные стили в соответвии с типом текущей строки
      // и сохраняем индексы для последующего форматирования
      switch (item.type) {
        case 'segmentTotal':
          styleTitle = styleSegmentTotalTitle;
          styleValue = styleSegmentTotaltValue;
          break;
        case 'categoryItem':
          styleTitle = styleCategoryItemTitle;
          styleValue = styleCategoryItemValue;
          break;
        case 'categoryTotal':
          styleTitle = styleCategoryTotalTitle;
          styleValue = styleCategoryTotalValue;
          break;
        default:
          break;
      }

      // Добавляем строки в таблицы и проводим их форматирование
      table.push([
        { 
          text: item.title, 
          style: styleTitle,
        }, { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[0]).format('0,0'), 
          style: styleValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[0] < 0 && item.type == 'segmentTotal' ? 'red' : 'black',
        }, 
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[1]).format('0,0'), 
          style: styleValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[1] < 0 && item.type == 'segmentTotal' ? 'red' : 'black',
        },
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[2]).format('0,0'), 
          style: styleValue,
          color: item.values[2] < 0 && item.type == 'segmentTotal' ? 'red' : 'black',
        },
      ]);
    });

    // Добавляем информацию по Валюте баланса
    table.push([
      { 
        text: rawTableData.balance[0].title, 
        style: styleCategoryTotalResultFooterTitle,
      }, { 
        // Форматируем полученное значение в соответсвии с типом отображаемых данных
        text: numeral(rawTableData.balance[0].values[0]).format('0,0'), 
        style: styleCategoryTotalResultFooterValue,
        // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
        color: rawTableData.balance[0].values[0] < 0  ? 'red' : 'black',
      }, 
      { 
        // Форматируем полученное значение в соответсвии с типом отображаемых данных
        text: numeral(rawTableData.balance[0].values[1]).format('0,0'), 
        style: styleCategoryTotalResultFooterValue,
        // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
        color: rawTableData.balance[0].values[1] < 0  ? 'red' : 'black',
      },
      { 
        // Форматируем полученное значение в соответсвии с типом отображаемых данных
        text: numeral(rawTableData.balance[0].values[2]).format('0,0'), 
        style: styleCategoryTotalResultFooterValue,
        color: rawTableData.balance[0].values[2] < 0 ? 'red' : 'black',
      },
    ]);

    return ({ tableData: table, widths: ['*', 70, 70, 70] })
  },
}

// Експортируем информацию в основной модуль VUEX
export default {
  state, getters, mutations, actions
}