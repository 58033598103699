export const incomeCategories = {
	imc01: {
		id: 'imc01',
		title: 'Работа',
		type: 'income',
		useCounter: 0,
		systemCategories: {
			imc01sc1: 'Зарплата',
			imc01sc2: 'Премия',
			imc01sc4: 'Бонусы',
			imc01sc5: 'Отпускные',
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	imc02: {
		id: 'imc02',
		title: 'Бизнес проекты',
		type: 'income',
		useCounter: 0,
		systemCategories: {
			imc02sc1: 'Прибыль',
			imc02sc2: 'Девиденды',
			imc02sc4: 'Выплата Совету директоров',
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	imc03: {
		id: 'imc03',
		title: 'Инвестиционный доход',
		type: 'income',
		useCounter: 0,
		systemCategories: {
			imc03sc1: 'Доход от облегаций',
			imc03sc2: 'Доход от акций',
			imc03sc4: 'Прирост капитала',
			imc03sc5: 'Трейдерская комиссия',
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	imc99: {
		id: 'imc99',
		title: 'Прочие доходы',
		type: 'income',
		userCounter: 0,
		systemCategories: {
			imc99sc1: 'Непредвиденные доходы',
			imc99sc2: 'Помощь'
		},
		userCategories: {}
	}
}

export const spendingCategories = {
	mc01: {
		id: 'mc01',
		title: 'Машина',
		icon: 'fas fa-car',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc01sc1: {
				title: 'ОСАГО',
				icon: 'far fa-file-alt'
			},
			mc01sc2: {
				title: 'КАСКО',
				icon: 'far fa-file-alt'
			},
			mc01sc4: {
				title: 'Топливо',
				icon: 'fas fa-gas-pump'
			},
			mc01sc5: {
				title: 'Парковка',
				icon: 'fas fa-parking'
			},
			mc01sc6: {
				title: 'Штрафы',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc01sc7: {
				title: 'Ремонт и ТО',
				icon: 'fas fa-tools'
			},
			mc01sc8: {
				title: 'Запчасти',
				icon: 'fas fa-tools'
			},
			mc01sc9: {
				title: 'Кашеринг',
				icon: 'fas fa-car-alt'
			},
		},
		userCategoriesIdCount: 0,
		userCategories: {},
	},
	mc02: {
		id: 'mc02',
		title: 'Общественный траспорт',
		icon: 'fas fa-bus-alt',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc02sc1:  {
				title: 'Автобус',
				icon: 'fas fa-bus'
			},
			mc02sc2: {
				title: 'Трамвай',
				icon: 'fas fa-tram'
			},
			mc02sc4: {
				title: 'Метро',
				icon: 'fas fa-subway'
			},
			mc02sc5: {
				title: 'Электричка',
				icon: 'fas fa-train'
			},
			mc02sc6: {
				title: 'Такси',
				icon: 'fas fa-taxi'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {
		}
	},
	mc03: {
		id: 'mc03',
		title: 'Связь',
		icon: 'fas fa-wifi',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc03sc1: {
				title: 'Интернет',
				icon: 'fas fa-wifi'
			},
			mc03sc2: {
				title: 'Стовая связь',
				icon: 'fas fa-mobile-alt'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc04: {
		id: 'mc04',
		title: 'Сервисные услуги',
		icon: 'fas fa-concierge-bell',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc04sc1: {
				title: 'Платные объявления',
				icon: 'far fa-newspaper'
			},
			mc04sc2: {
				title: 'Юридическая помощь',
				icon: 'fas fa-balance-scale'
			},
			mc04sc4: {
				title: 'Печать документов',
				icon: 'fas fa-print'
			},
			mc04sc5: {
				title: 'Курьерская доставка',
				icon: 'fas fa-people-carry'
			},
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc05: {
		id: 'mc05',
		title: 'Обучение',
		icon: 'fas fa-user-graduate',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc05sc1: {
				title: 'Книги',
				icon: 'fas fa-book'
			},
			mc05sc2: {
				title: 'Учебники',
				icon: 'fas fa-book'
			},
			mc05sc4: {
				title: 'Обучающие курсы',
				icon: 'fas fa-book-reader'
			},
			mc05sc5: {
				title: 'Репититор',
				icon: 'fas fa-chalkboard-teacher'
			},
			mc05sc6: {
				title: 'Канцелярия',
				icon: 'fas fa-paperclip'
			},
			mc05sc7: {
				title: 'Оплата обучения',
				icon: 'fas fa-receipt'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {
		}
	},
	mc06: {
		id: 'mc06',
		type: 'spending',
		useCounter: 0,
		title: 'Развлечения',
		icon: 'fas fa-theater-masks',
		systemCategories: {
			mc06sc1: {
				title: 'Кино',
				icon: 'fas fa-film'
			},
			mc06sc2: {
				title: 'Выставки',
				icon: 'far fa-calendar-alt',
			},
			mc06sc4: {
				title: 'Театр',
				icon: 'fas fa-theater-masks',
			},
			mc06sc5: {
				title: 'Рестораны',
				icon: 'fas fa-glass-cheers'
			},
			mc06sc6: {
				title: 'Бары',
				icon: 'fas fa-beer'
			},
			mc06sc7: {
				title: 'Журналы и газеты',
				icon: 'far fa-newspaper'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc07: {
		id: 'mc07',
		title: 'Банки',
		icon: 'fas fa-landmark',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc07sc1: {
				title: 'Банковские услуги',
				icon: 'fas fa-hand-holding-usd'
			},
			mc07sc2: {
				title: 'Штрафы',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc07sc3: {
				title: 'Пеня',
				icon: 'fas fa-file-invoice-dollar'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc08: {
		id: 'mc08',
		title: 'Финансовые услуги',
		icon: 'fas fa-money-check-alt',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc08sc1: {
				title: 'Финансовый консультант',
				icon: 'fas fa-chart-line'
			},
			mc08sc2: {
				title: 'Страхование жизни и здоровья',
				icon: 'fas fa-heartbeat'
			},
			mc08sc4: {
				title: 'Страхование дома (квартиры)',
				icon: 'fas fa-house-damage'
			},
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc09: {
		id: 'mc09',
		title: 'Еда и Продукты',
		icon: 'fas fa-pizza-slice',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc09sc2: {
				title: 'Продукты',
				icon: 'fas fa-shopping-basket'
			},
			mc09sc4: {
				title: 'Кафешки',
				icon: 'fas fa-coffee'
			},
			mc09sc5: {
				title: 'Фастфуд',
				icon: 'fas fa-hamburger'
			},
			mc09sc6: {
				title: 'Рестораны',
				icon: 'fas fa-glass-cheers'
			},
			mc09sc7: {
				title: 'Прочее',
				icon: 'fas fa-grip-horizontal'
			},
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc091: {
		id: 'mc091',
		title: 'Алкоголь',
		icon: 'fas fa-wine-bottle',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc091sc1: {
				title: 'Пиво',
				icon: 'fas fa-beer'
			},
			mc091sc2: {
				title: 'Вино и шампанское',
				icon: 'fas fa-wine-glass'
			},
			mc091sc4: {
				title: 'Водка',
				icon: 'fas fa-glass-whiskey'
			},
			mc091sc5: {
				title: 'Виски',
				icon: 'fas fa-glass-whiskey'
			},
			mc091sc6: {
				title: 'Коньяк',
				icon: 'fas fa-glass-whiskey'
			},
			mc091sc7: {
				title: 'Прочий некрепкий',
				icon: 'fas fa-cocktail'
			},
			mc091sc8: {
				title: 'Прочий крепкий',
				icon: 'fas fa-glass-whiskey'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc10: {
		id: 'mc10',
		title: 'Подарки и пожертвования',
		icon: 'fas fa-gifts',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc10sc1: {
				title: 'Пожертвования',
				icon: 'fas fa-gift'
			},
			mc10sc2: {
				title: 'Подарки',
				icon: 'fas fa-hand-holding-heart'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc11: {
		id: 'mc11',
		title: 'Здоровье и тренировки',
		icon: 'fas fa-heartbeat',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc11sc1: {
				title: 'Стоматология',
				icon: 'fas fa-tooth'
			},
			mc11sc2: {
				title: 'Офтальмология',
				icon: 'fas fa-glasses'
			},
			mc11sc4: {
				title: 'Прочие доктора',
				icon: 'fas fa-user-md'
			},
			mc11sc5: {
				title: 'Лекарства',
				icon: 'fas fa-pills'
			},
			mc11sc6: {
				title: 'Фитнес центр и СПА',
				icon: 'fas fa-heartbeat'
			},
			mc11sc7: {
				title: 'Бассейн',
				icon: 'fas fa-swimmer'
			},
			mc11sc8: {
				title: 'Спортивное питание',
				icon: 'fas fa-prescription-bottle'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc12: {
		id: 'mc12',
		title: 'Дом (квартира)',
		icon: 'fas fa-home',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc12sc1: {
				title: 'Арендная плата',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc12sc2: {
				title: 'Коммунальные платежи',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc12sc3: {
				title: 'Электричество',
				icon: 'fas fa-bolt'
			},
			mc12sc4: {
				title: 'Вода',
				icon: 'fas fa-shower'
			},
			mc12sc5: {
				title: 'Газ',
				icon: 'fas fa-burn'
			},
			mc12sc6: {
				title: 'Отопление',
				icon: 'fas fa-temperature-high'
			},
			mc12sc7: {
				title: 'Ремонт',
				icon: 'fas fa-hammer'
			},
			mc12sc8: {
				title: 'Мебель',
				icon: 'fas fa-chair'
			},
			mc12sc9: {
				title: 'Выплаты по ипотеке',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc12sc10: {
				title: 'Озеление территории',
				icon: 'fab fa-mix'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc13: {
		id: 'mc13',
		title: 'Дети',
		icon: 'fas fa-baby',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc13sc1: {
				title: 'Алименты',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc13sc2: {
				title: 'Карманные деньги',
				icon: 'fas fa-ruble-sign'
			},
			mc13sc3: {
				title: 'Детские товары',
				icon: 'fas fa-baby-carriage'
			},
			mc13sc4: {
				title: 'Игрушки',
				icon: 'fab fa-earlybirds'
			},
			mc13sc5: {
				title: 'Няня',
				icon: 'fas fa-user-astronaut'
			},
			mc13sc6: {
				title: 'Секции, кружки',
				icon: 'fas fa-puzzle-piece'
			},
			mc13sc7: {
				title: 'Детский сад',
				icon: 'fas fa-puzzle-piece'
			},
			mc13sc8: {
				title: 'Школа',
				icon: 'fas fa-school'
			},
			mc13sc9: {
				title: 'Учебники, канцелярия',
				icon: 'fas fa-paperclip'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc131: {
		id: 'mc131',
		title: 'Родители',
		icon: 'fas fa-blind',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc131sc1: {
				title: 'Подарки',
				icon: 'fas fa-gift'
			},
			mc131sc2: {
				title: 'Поддержка',
				icon: 'fas fa-ruble-sign'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc14: {
		id: 'mc14',
		title: 'Кредиты',
		icon: 'fas fa-money-check-alt',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc14sc1: {
				title: 'Комиссии и сборы',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc14sc2: {
				title: 'Кредитная страховка',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc14sc4: {
				title: 'Кредитный платеж',
				icon: 'fas fa-file-invoice-dollar'
			},
			mc14sc5: {
				title: 'Проценты по кредиту',
				icon: 'fas fa-percent'
			},
			mc14sc6: {
				title: 'Тело кредита',
				icon: 'fas fa-file-invoice-dollar'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc15: {
		id: 'mc15',
		title: 'Уход за собой',
		icon: 'fas fa-cut',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc15sc1: {
				title: 'Стрижка',
				icon: 'fas fa-cut'
			},
			mc15sc2: {
				title: 'Прачечная',
				icon: 'fas fa-tshirt'
			},
			mc15sc4: {
				title: 'СПА и массаж',
				icon: 'fas fa-hot-tub'
			},
			mc15sc5: {
				title: 'Косметолог',
				icon: 'fas fa-user-nurse'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc16: {
		id: 'mc16',
		title: 'Домашние животные',
		icon: 'fas fa-paw',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc16sc1: {
				title: 'Еда и принадлежности',
				icon: 'fas fa-bone'
			},
			mc16sc2: {
				title: 'Игрушки',
				icon: 'fas fa-bacon'
			},
			mc16sc4: {
				title: 'Стрижка питомца',
				icon: 'fas fa-cut'
			},
			mc16sc5: {
				title: 'Ветеринар',
				icon: 'fas fa-user-md'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc17: {
		id: 'mc17',
		title: 'Покупки',
		icon: 'fas fa-shopping-basket',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc17sc1: {
				title: 'Книги',
				icon: 'fas fa-book'
			},
			mc17sc2: {
				title: 'Одежда',
				icon: 'fas fa-tshirt'
			},
			mc17sc4: {
				title: 'Обувь',
				icon: 'fas fa-shoe-prints'
			},
			mc17sc5: {
				title: 'Аксессуары',
				icon: 'fas fa-ring'
			},
			mc17sc6: {
				title: 'Электроника',
				icon: 'fas fa-desktop'
			},
			mc17sc7: {
				title: 'ПО и iTunes',
				icon: 'fab fa-itunes'
			},
			mc17sc8: {
				title: 'Спортивные товары',
				icon: 'fas fa-basketball-ball'
			},
			mc17sc9: {
				title: 'Товары для хобби',
				icon: 'fas fa-puzzle-piece'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc18: {
		id: 'mc18',
		title: 'Налоги',
		icon: 'fas fa-percentage',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc18sc1: {
				title: 'НДФЛ',
				icon: 'fas fa-percentage'
			},
			mc18sc2: {
				title: 'Налог на имущество',
				icon: 'fas fa-percentage'
			},
			mc18sc4: {
				title: 'Налог на самозанятых',
				icon: 'fas fa-percentage'
			},
			mc18sc5: {
				title: 'Земельный налог',
				icon: 'fas fa-percentage'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc19: {
		id: 'mc19',
		title: 'Путешествия',
		icon: 'fas fa-suitcase-rolling',
		type: 'spending',
		useCounter: 0,
		systemCategories: {
			mc19sc1: {
				title: 'Ж/д билеты',
				icon: 'fas fa-train'
			},
			mc19sc2: {
				title: 'Авиабителы',
				icon: 'fas fa-plane'
			},
			mc19sc4: {
				title: 'Гостиница',
				icon: 'fas fa-hotel'
			},
			mc19sc5: {
				title: 'Аренда машины',
				icon: 'fas fa-car-alt'
			},
			mc19sc6: {
				title: 'Турпутевка',
				icon: 'fas fa-file-invoice'
			}
		},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
	mc20: {
		id: 'mc20',
		title: 'Прочее',
		icon: 'fas fa-grip-horizontal',
		type: 'spending',
		useCounter: 0,
		systemCategories: {},
		userCategoriesIdCount: 0,
		userCategories: {}
	},
}