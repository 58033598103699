import firebase from 'firebase'
import moment from 'moment'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
    async fetchTransactions(context) {
      const uid = await context.dispatch('getUserId')
      const querySnapshop = await firebase.firestore().collection(`users/${uid}/transactions`).get()
      const transactions = []
      querySnapshop.forEach(transaction => {
        transactions.push({
          id: transaction.id,
          date: transaction.data().date,
          account: transaction.data().account,
          type: transaction.data().type, 
          amount: transaction.data().amount,
          categoryTitle: transaction.data().categoryTitle,
          categories: transaction.data().categories,
          autoComplete: transaction.data().autoComplete,
          description: transaction.data().description,
          toAccount: transaction.data().toAccount
        })
      })
      const sortedTransactions = transactions.sort((a, b) => {
        return b.date - a.date
      })
      context.commit('fetchTransactions', transactions)
    },

    async createTransaction(context, transactionData) {
      console.log('create transaction', transactionData)
      let year = moment(transactionData.date).year()
      let month = moment(transactionData.date).month() + 1
      let currentMonth = moment().month() + 1
      var batch = firebase.firestore().batch()
      console.log('create transaction', year, month)
      try {
        const uid = await context.dispatch('getUserId')
        // Write the transaction to db if it's new transaction or write changes if it's exist
        if (transactionData.id == null) {
          firebase.firestore().collection(`/users/${uid}/transactions`)
          .add(transactionData).then(
            docRef => {
              transactionData.id = docRef.id
              console.log(transactionData)
              context.commit('addTransaction', transactionData)
          })
        } else {
          console.log('transaction is exist', transactionData)
          var oldTxRef = await firebase.firestore().collection(`/users/${uid}/transactions`).doc(transactionData.id).get()
          let oldTxData = oldTxRef.data()

          //* Delete old data from registers
          //* обновляем данные счета
          //todo: transfer this code to the accounts.js
          
          var accRef = await firebase.firestore().collection(`/users/${uid}/accounts`).doc(oldTxData.accountIndex)  
          batch.set(accRef, {
            'amount': firebase.firestore.FieldValue.increment(oldTxData.amount * -1)
          }, {merge: true})
          
          // Update Account incSpRegisters
          if (oldTxData.type == 'income' || oldTxData.type == 'spending') {
            batch.set(accRef, { // spend or income registers update
              'incSpRegisters': {
                [year]: {
                  [month]: {
                    [`${oldTxData.type}Sum`]: firebase.firestore.FieldValue.increment(oldTxData.amount * -1)
                  }
                }
              }
            }, {merge: true})
          } 
          
          if (oldTxData.type == 'transfer') {
            var toAccRef = await firebase.firestore().collection(`/users/${uid}/accounts`).doc(oldTxData.toAccount.id)  
            batch.set(accRef, { // update fromAccount with transferOut
              'incSpRegisters': {
                [year]: {
                  [month]: {
                    [`transferOut`]: firebase.firestore.FieldValue.increment(oldTxData.amount  * -1)
                  }
                }
              }
            }, {merge: true})
            batch.set(toAccRef, { // update toAccount amount
              'amount': firebase.firestore.FieldValue.increment(oldTxData.amount * 1)
            }, {merge: true})
            batch.set(toAccRef, { // update toAccount with transferIn
              'incSpRegisters': {
                [year]: {
                  [month]: {
                    [`transferIn`]: firebase.firestore.FieldValue.increment(oldTxData.amount * 1)
                  }
                }
              }
            }, {merge: true})
          }
          
          if (oldTxData.type == 'balance') {
            batch.set(accRef, { // spend or income registers update
              'incSpRegisters': {
                [year]: {
                  [month]: {
                    'balance': firebase.firestore.FieldValue.increment(oldTxData.amount * -1)
                  }
                }
              }
            }, {merge: true})
          }
          // Delete statistic data
          context.dispatch('decreaseCategorySpendingsStat', oldTxData)

          // Update transactin information
          var txRef = await firebase.firestore().collection(`/users/${uid}/transactions`).doc(transactionData.id)
          batch.set(txRef, transactionData, {merge: true})
          context.commit('removeTransaction', oldTxData.id)
          context.commit('addTransaction', transactionData)
        }


        //* обновляем данные счета
        //todo: transfer this code to the accounts.js
        
        var accRef = await firebase.firestore().collection(`/users/${uid}/accounts`).doc(transactionData.accountIndex)  
        batch.set(accRef, {
          'amount': firebase.firestore.FieldValue.increment(transactionData.amount)
        }, {merge: true})
        
        // Update Account incSpRegisters
        if (transactionData.type == 'income' || transactionData.type == 'spending') {
          batch.set(accRef, { // spend or income registers update
            'incSpRegisters': {
              [year]: {
                [month]: {
                  [`${transactionData.type}Sum`]: firebase.firestore.FieldValue.increment(transactionData.amount)
                }
              }
            }
          }, {merge: true})
        } 
        
        if (transactionData.type == 'transfer') {
          var toAccRef = await firebase.firestore().collection(`/users/${uid}/accounts`).doc(transactionData.toAccount.id)  
          batch.set(accRef, { // update fromAccount with transferOut
            'incSpRegisters': {
              [year]: {
                [month]: {
                  [`transferOut`]: firebase.firestore.FieldValue.increment(transactionData.amount)
                }
              }
            }
          }, {merge: true})
          batch.set(toAccRef, { // update toAccount amount
            'amount': firebase.firestore.FieldValue.increment(transactionData.amount * -1)
          }, {merge: true})
          batch.set(toAccRef, { // update toAccount with transferIn
            'incSpRegisters': {
              [year]: {
                [month]: {
                  [`transferIn`]: firebase.firestore.FieldValue.increment(transactionData.amount * -1)
                }
              }
            }
          }, {merge: true})
        }
        
        if (transactionData.type == 'balance') {
          batch.set(accRef, { // spend or income registers update
            'incSpRegisters': {
              [year]: {
                [month]: {
                  'balance': firebase.firestore.FieldValue.increment(transactionData.amount)
                }
              }
            }
          }, {merge: true})
        }

        batch.commit().then(function() {
          context.dispatch('fetchAccounts')
        })

        //* Update Income or Spendings statistics
        context.dispatch('increaseCategorySpendingsStat', transactionData) 
      
      } catch (error) {
        console.log('Ощибка записи транзакции!', error)
        context.commit('setError', error)
        throw error
      }
    },

    async removeTransaction(context, transactionData) {
      let year = moment(transactionData.date).year()
      let month = moment(transactionData.date).month() + 1
      let currentMonth = moment().month() + 1
      try {
        const uid = await context.dispatch('getUserId')

        // Delete transaction from db
        firebase.firestore().collection(`users/${uid}/transactions`)
        .doc(transactionData.id).delete().then(
          // Delete transaction from local storage
          context.commit('removeTransaction', transactionData.id)
        )
        console.log(transactionData, year)
        // Update transaction's Account amount in db
        //todo: transfer this code to accounts.js 
        var batch = firebase.firestore().batch()
        var accRef = firebase.firestore().collection(`/users/${uid}/accounts`).doc(transactionData.accountId)  
        
        batch.set(accRef, { // update amount
          'amount': firebase.firestore.FieldValue.increment(transactionData.amount)
        }, {merge: true})

        // Update Account incSpRegisters
        if (transactionData.transactionType == 'income' || transactionData.transactionType == 'spending') {
          batch.set(accRef, { // spend or income registers update
            'incSpRegisters': {
              [2019]: {
                [month]: {
                  [`${transactionData.transactionType}Sum`]: firebase.firestore.FieldValue.increment(transactionData.amount)
                  }
                }
              }
            }, {merge: true})
        } 

        if (transactionData.transactionType == 'transfer') {
          var toAccRef = await firebase.firestore().collection(`/users/${uid}/accounts`).doc(transactionData.toAccount.id)  
          batch.set(accRef, { // substract transaction amount in fromAccount from transferOut
            'incSpRegisters': {
              [2019]: {
                [month]: {
                  [`transferOut`]: firebase.firestore.FieldValue.increment(transactionData.amount)
                }
              }
            }
          }, {merge: true})

          batch.set(toAccRef, { // substract transaction amount in toAccount amount
            'amount': firebase.firestore.FieldValue.increment(transactionData.amount * -1)
          }, {merge: true})
          
          batch.set(toAccRef, { // substract transaction amount in toAccount with transferIn
            'incSpRegisters': {
              [year]: {
                [month]: {
                  [`transferIn`]: firebase.firestore.FieldValue.increment(transactionData.amount * -1)
                }
              }
            }
          }, {merge: true})
        }

        if (transactionData.transactionType == 'balance') {
          batch.set(accRef, { // spend or income registers update
            'incSpRegisters': {
              [year]: {
                [month]: {
                  'balance': firebase.firestore.FieldValue.increment(transactionData.amount)
                  }
                }
              }
            }, {merge: true})
        } 

        batch.commit().then(function() {
          context.dispatch('fetchAccounts')
        })

        console.log(transactionData)
        
        // Update statistics
        context.dispatch('decreaseCategorySpendingsStat', transactionData)

      } catch (error) {
        context.commit('setError', error)
        throw error
      }
    }
  }

export default {
  state, getters, mutations, actions
}
