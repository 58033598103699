import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'

import auth from './auth'
import demo from './demo'
import outline from './modules/outline'
import finance from './modules/finance'
import milestones from './modules/milestines'
import blog from './modules/blog'
import pitch from './modules/pitch'
import tablePL from './modules/tablePL'
import tableCF from './modules/tableCF'
import tableBalance from './modules/tableBalance'
import charts from './modules/charts'
import categories from './modules/categories'
import accounts from './modules/accounts'
import transactions from './modules/transactions'
import netWealth from './modules/netWealth'
import budget from './modules/budget'
import statistics from './modules/statistics' 

// initial setup data
import outlineTopics from './setupData/outlineTopics';
import currentOutline from './setupData/currentOutline';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null,
    userInfo: null,
    activeBPlan: null,
  },
  getters: {
    // Получаем состояние ошибки
    error: state => state.error,
    userInfo: state => state.userInfo,
    getActiveBPlan: state => state.activeBPlan,
  },

  mutations: {
    setError(state, error) {
      state.error = error
    },

    // Сбрасываем ошибку 
    clearError(state) {
      state.error = null
    },

    setUserInfo(state, payload) {
      state.userInfo = payload
    },

    userRegCompleted(state) {
      Vue.$set(state.userInfo, 'regCompleted', true);
    },

    clearUserInfo(state) {
      state.userInfo = null
      state.error = null
    },

    setActiveBPlan(state, payload) {
      state.activeBPlan = payload;
    },

  },
  actions: {
    // Создание бизнес-плана
    async createBPlan(context, payload) {
      const uid = await context.dispatch('getUserId');
      try {
        // Записываем установочные данные о новом Бизнес-плане
        const newBPlanRef = firebase.firestore().collection(`/users/${uid}/bplans`).doc();
        await newBPlanRef.set(payload);
        
        // Записываем данные доступных системных топиков
        const outlineTopicsRef = firebase.firestore().collection(`${newBPlanRef.path}/outlineTopics`);
        for (const topic of outlineTopics)  {
          await outlineTopicsRef.doc().set(topic);
        }

        try {
           // Создаем стартовый тематический план
          const currentOutlineRef = firebase.firestore().collection(`${newBPlanRef.path}/currentOutline`);
          for (const chapter of currentOutline.chapters) {
            // Записываем данные о текущей главе
            const currentOutlineChapterDoc = currentOutlineRef.doc();
            await currentOutlineChapterDoc.set({
              id: currentOutlineChapterDoc.id,
              index: chapter.index,
              title: chapter.title
            });
            // Записываем данные о разделах текущей главы
            const newSectionsRef = firebase.firestore().collection(`${currentOutlineChapterDoc.path}/sections`);
            for (const section of chapter.sections) {
              // Записываем данные о текущей секции
              const newSectionDoc = newSectionsRef.doc();
              await newSectionDoc.set({
                id: newSectionDoc.id,
                index: section.index,
                title: section.title
              });
              // Записываем данные об абзацах текущего раздела
              const newTopicsRef = firebase.firestore().collection(`${newSectionDoc.path}/topics`);
              for (const topic of section.topics) {
                await newTopicsRef.doc().set(topic);
              }
            }
          }
        } catch (error) {
          console.log('Не получается создать начльний тематический план: ', error);
        }
       

        // Погдготавливаем данные для установки настроект текущего Бизнес-плана
        payload.id = newBPlanRef.id;
        payload.path = newBPlanRef.path;
        // Устанавливаем текущий Бизнес-план в локальную зону работы
        context.commit('setActiveBPlan', payload);
      } catch (error) {
        window.console.log('Не получается создать бизнес-план', error);
      }
      
    },

    // Get the path of the active (current) BPlan
    async fetchActiveBPlan(context) {
      const uid = await context.dispatch('getUserId')
      var activeBPlnaPath = ''
      const bplansSnapshop = await firebase.firestore().collection(`/users/${uid}/bplans`)
      .where("active", "==", true).get();
      
      // get current BPlan path
      bplansSnapshop.forEach((bplan) => {
        if (bplan.data().active) {
          activeBPlnaPath = bplan.ref.path;
          // Погдготавливаем данные для установки настроект текущего Бизнес-плана
          var bplanData = bplan.data();
          bplanData.id = bplan.id;
          bplanData.path = bplan.ref.path;
          // Устанавливаем текущий Бизнес-план в локальную зону работы
          context.commit('setActiveBPlan', bplanData);
        }
      })

      // возвращаем путь Бизнес-плана, для дальнейшей работы
      return activeBPlnaPath
    },

    // Обновление данных Бизнес-плана
    async updateBPlan(context, payload) {
      try {
        // Записываем установочные данные о новом Бизнес-плане
        const bplanRef = firebase.firestore().doc(payload.path);
        // Стираем ненужную информацию
        delete payload.id;
        delete payload.path;
        
        await bplanRef.set(payload, { merge: true });
        // Погдготавливаем данные для установки настроект текущего Бизнес-плана
        payload.id = bplanRef.id;
        payload.path = bplanRef.path;
        // Устанавливаем текущий Бизнес-план в локальную зону работы
        context.commit('setActiveBPlan', payload);
      } catch (error) {
        window.console.log('Не получается обновить данные бизнес-плана - ', error);
      }
      
    },

    async deleteBPlan(context, bplanPath) {
      // Удаляем документ
      try {
        /* 
          Бизнес план не удаляется, а для него устанавливаются флаги удаления
          и поэтому он не виден в текущем дереве, но есть в базе данных
        */
        await firebase.firestore().doc(bplanPath).set({
          active: false,
          archived: true,
          archivated: new Date()
        }, {
          merge: true
        });
        // Удаляем текущий Бизнес-план из локальной зоны работы
        context.commit('setActiveBPlan', null);
      } catch (error) {
        window.console.log('Не могу переместить Бизнес-план в архив:', error)
      }
      context.commit('setActiveBPlan', null);
    }
  },
  
  modules: {
    auth,
    demo, 
    outline,
    finance,
    milestones,
    blog,
    pitch,
    tablePL,
    tableCF,
    tableBalance,
    charts,
    categories, 
    accounts, 
    transactions,
    netWealth,
    budget,
    statistics,
  }
})