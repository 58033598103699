export default {
  install(Vue) {
    Vue.prototype.$message = function(text) {
      this.$bvToast.toast(text, {
        title: 'Внимание!',
        variant: 'primary',
        autoHideDelay: 2000
      })
    }

    Vue.prototype.$error = function(text) {
      this.$bvToast.toast(text, {
        title: 'Ошибка...',
        variant: 'danger',
        autoHideDelay: 2000
      })
    }
  }
}