import firebase from 'firebase'

// -----------------------------------------

const state = {
  blogPostsList: null,
  currentBlogPost: null
}

// -----------------------------------------

const getters = {
  getBlogPostsList: state => state.blogPostsList,
  getCurrentBlogPost: state => state.currentBlogPost,
}

// -----------------------------------------

const mutations = {

  fetchBlogPostsList(state, payload) {
    state.blogPostsList = payload;
  },

  setCurrentBlogPost(state, id) {
    const index = state.blogPostsList.findIndex(item => item.id == id)
    state.currentBlogPost = state.blogPostsList[index]
  },

}

// -----------------------------------------

const actions = {

  async fetchBlogPostsList (context) {

    const blogPostsListQuery = await firebase.firestore().collection('blog').get();
    var blogImagesRef = firebase.storage().ref().child('blog');
    var blogPostsList = []
    
    console.log(blogPostsListQuery)

    try {
      blogPostsListQuery.forEach(post => {
        var newPost = post.data();
        // Загружаем путь до фото для блога
        newPost.imageUrl = blogImagesRef.child(`${post.id}.jpg`).getDownloadURL();
        newPost.id = post.id;
        newPost.path = post.ref.path;
        blogPostsList.push(newPost);
      });
  
      context.commit('fetchBlogPostsList', blogPostsList);

    } catch (error) {
      window.console.log('Unable to load blog posts list from db: ', error)
    }
    
  },

}



export default {
    state, getters, mutations, actions
  }