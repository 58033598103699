import Vue from 'vue'
import firebase from 'firebase'
import moment from 'moment'

const state = {
	budgets: {},
	selectedBudgetPeriod: {
		year: moment().year(),
		month: moment().month() + 1,
	},
}

const getters = {
	budgetsData: state => {
		return state.budgets
	},
	selectedMonthBudgetData: state => {
		if (state.budgets != {}) {
			if (state.budgets.months != undefined) {
				return state.budgets.months[state.selectedBudgetPeriod.month]
			}
		}
	},
	selectedBudgetPeriod: state => {
		return state.selectedBudgetPeriod
	},
	totalBudgetSum: state => {
		var sum = 0
		if ('months' in state.budgets) {
			if (state.budgets.months[state.selectedBudgetPeriod.month] != null) {
				var monthData = Object.values(state.budgets.months[state.selectedBudgetPeriod.month])
				sum = 0
				for (var i = 0; i < monthData.length; i++) {
					sum += monthData[i].categoryLimit
				}
			} else {
				sum = 0
			}
		} else {
			sum = 0
		} 
		console.log(sum)
		return sum
	}
}

const mutations = {
	fetchBudgets(store, payload) {
		store.budgets = payload
	},
	createBudget(store, payload) {
		const month = new Date().getMonth() + 1
		Vue.set(store.budgets.months[month], payload.categoryId, payload)
	},
	setSelectedBudgetPeriod(store, payload) {
		Vue.set(store.selectedBudgetPeriod, 'year', moment(payload).year())
		Vue.set(store.selectedBudgetPeriod, 'month', moment(payload).month() + 1)
	},
	deleteBudget(state, payload) {
		const month = new Date().getMonth() + 1
		Vue.delete(state.budgets.months[month], payload.categoryId) 
	},

}

const actions = {
    // Create new budget category
	async createBudget(context, payload) {
		const month = new Date().getMonth() + 1
		const uid = await context.dispatch('getUserId')
		firebase.firestore().collection(`/users/${uid}/budgets`).doc('2019').set({
			months: {
				[month]: {
					[payload.categoryId]: payload
				}
			}
		}, {merge: true}).then(
			context.commit('createBudget', payload)
		)
	},
	async fetchBudgets(context, payload) {
		const uid = await context.dispatch('getUserId')
		firebase.firestore().collection(`users/${uid}/budgets/`).doc(payload.year)
		.get().then(querySnapshot => {
			context.commit('fetchBudgets', querySnapshot.data())
		})
	}, 
	async deleteBudget(context, payload) {
		const year = new Date().getFullYear().toString()
		const month = new Date().getMonth() + 1
		console.log(payload)
		const uid = await context.dispatch('getUserId')
		const budgetsRef = firebase.firestore().collection(`users/${uid}/budgets`).doc(year)
		budgetsRef.update({
				[`months.8.${payload.categoryId}`]: firebase.firestore.FieldValue.delete()
		}).then(
			context.commit('deleteBudget', payload)
		)
	}
}

export default {
	state, getters, mutations, actions
}