<template>
  <div class="site-newsletter bg-100 mt-4">
      <div class="container">
        <div class="text-center">
          <h3 class="h4 mb-2 pt-3">Подпишитесь на новости</h3>
          <p class="text-muted">

            Присоединяйтесь к нашей новостной рассылке, чтобы быть в курсе новостей и обновлений 
          </p>

          <div class="row">
            <div class="col-xs-12 col-sm-9 col-md-7 col-lg-5 ml-auto mr-auto">
              <div class="input-group mb-5 mt-3">
                <b-input-group>
                  <b-form-input type="email"
                    placeholder="введите адрес электронной почты">
                  </b-form-input>

                  <b-input-group-append>
                    <b-button variant="secondary">
                      Подписаться
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>