import firebase from 'firebase/app'

const demoUser = 'YpmVVzskbKWfTmBmXXkT8uy9RuH2'

export default {
    actions: {
      // load demo accounts
      async fetchDemoAccounts(context) {
        try {
          firebase.firestore().collection(`/users/${demoUser}/accounts`)
          .get().then(querySnapshot => {
            if (querySnapshot) {
              querySnapshot.forEach(item => {
                context.commit('fetchAccounts', {
                  type: item.id,
                  account: item.data()
                })
              })            
            } else {
              console.log('Can\'t get the Accounts collection!')
            }
          })   
        } catch (error) {
          context.commit('setError', error)
          throw error
        }
      },
      // load demo categories
      async fetchDemoCategories(context) {
        try {
          var categories = []
          var incCategories, spendCategories = []
          let incomeQuerySnapshop = await firebase.firestore().collection(`users/${demoUser}/categories`).doc('incomeCategories').get()
          let spendQuerySnapshop = await firebase.firestore().collection(`users/${demoUser}/categories`).doc('spendingCategories').get()
          
          incCategories = Object.values(incomeQuerySnapshop.data())
          spendCategories = Object.values(spendQuerySnapshop.data())  
          
          categories = [...new Set([...incCategories, ...spendCategories])]
    
          context.commit('fetchCategories', categories)
        } catch (error) {
          console.log('Ошибка загрузки категорий', error)
          context.commit('setError', error)
          throw error
        }
      },
      // load demo transactions
      async fetchDemoTransactions(context) {
        const querySnapshop = await firebase.firestore().collection(`users/${demoUser}/transactions`).get()
        const transactions = []
        querySnapshop.forEach(transaction => {
          transactions.push({
            id: transaction.id,
            date: transaction.data().date,
            account: transaction.data().account,
            type: transaction.data().type, 
            amount: transaction.data().amount,
            categoryTitle: transaction.data().categoryTitle,
            categories: transaction.data().categories,
            autoComplete: transaction.data().autoComplete,
            description: transaction.data().description,
            toAccount: transaction.data().toAccount
          })
        })
        const sortedTransactions = transactions.sort((a, b) => {
          return b.date - a.date
        })
        context.commit('fetchTransactions', transactions)
      },
      // load demo bugets
      async fetchDemoBudgets(context, payload) {
        firebase.firestore().collection(`users/${demoUser}/budgets/`).doc(payload.year)
        .get().then(querySnapshot => {
          context.commit('fetchBudgets', querySnapshot.data())
        })
      },
      // load demo statisctics
      async fetchDemoStatistics(context) {
        try {
          firebase.firestore().collection(`users/${demoUser}/statistics`).get()
          .then(querySnapshot => {
            if (querySnapshot) {
              querySnapshot.forEach(item => {
                context.commit('fetchStatistics', {
                  id: item.id,
                  spendings: item.data().spendings,
                  incomes: item.data().incomes
                })
              })
            }
          }) 
        } catch (error) {
          console.log('Fetch statistic error:', error)
        }
      },
    }
}

