import Vue from 'vue'
import firebase from 'firebase'

const state = {
  outlineDragging: {status: false, group: {name: 'topic'}},
  outlineTopics: [],
  outlineTables: [],
  outlineCharts: [],
  currentOutline: [],
  selectedOutlineChapter: []
}

const getters = {
  getOutlineDraggingStatus: state => {
    return state.outlineDragging
  },

  getOutlineItem: state => {
    return state.outlineItem
  },

  currentOutline: state => {
    return state.currentOutline
  },

  outlineTopics: state => {
    return state.outlineTopics
  },

  outlineTables: state => {
    return state.outlineTables
  },

  outlineCharts: state => {
    return state.outlineCharts
  },
  
  getSelectedOutlineChapter: state => {
    return state.selectedOutlineChapter
  }

}

const mutations = { 
  fetchCurrentOutline(state, payload) {
    state.currentOutline = payload
  },

  fetchOutlineTopics(state, payload) {
    state.outlineTopics = payload
  },

  fetchOutlineTables(state, payload) {
    state.outlineTables = payload
  },

  fetchOutlineCharts(state, payload) {
    state.outlineCharts = payload
  },

  changeOutlineDraggingStatus(state, payload) {
    state.outlineDragging = payload
  },

  selectOutlineChapter(state, chapterIndex) {
    state.selectedOutlineChapter = state.currentOutline[chapterIndex]
  },

  addNewChapter(state, payload) {
    state.currentOutline.push(payload.chapterData)
  },

  deleteUserTopic(state, index) {
    Vue.delete(state.outlineTopics, index)
  }
  
}

const actions = {
  async fetchCurrentOutline(context) {
    const uid = await context.dispatch('getUserId')
    const bplansSnapshop = await firebase.firestore().collection(`/users/${uid}/bplans`).get()
    const currentOutline = []

    if (bplansSnapshop.docs.length > 0) {
      const currentBPlan = bplansSnapshop.docs[0].ref.path
      const querySnapshop = await firebase.firestore().collection(`${currentBPlan}/currentOutline`).orderBy('index').get()
      
      querySnapshop.forEach(chapter => {
        const sections = []
        firebase.firestore().collection(`${chapter.ref.path}/sections`).orderBy('index').get()
          .then(function (sectionsSnapshot) {
            sectionsSnapshot.forEach(async (section) => {
              const topics = []
              firebase.firestore().collection(`${section.ref.path}/topics`).orderBy('index').get()
              .then(function (topicsSnapshot) {
                topicsSnapshot.forEach(topic => {
                  var newTopic = topic.data()
                  newTopic.id = topic.id,
                  newTopic.path = topic.ref.path,
                  newTopic.parentId = section.id,
                  topics.push(newTopic)
                });
              });
              sections.push({
                id: section.id,
                path: section.ref.path,
                title: section.data().title,
                topics: topics
              });
            });
          });
        currentOutline.push({
          id: chapter.id,
          path: chapter.ref.path,
          title: chapter.data().title,
          sections: sections
        })
      })
    }
    
    context.commit('fetchCurrentOutline', currentOutline)
  },

  //* Загружаем Параграфы, Таблицы, Графики одним запросом
  //*TODO: Обновить название, для более точного отображения функции
  async fetchOutlineTopics(context) {
    var outlineTopics = [];
    var outlineTables = [];
    var outlineCharts = [];
    var activeBPlnaRef = ''

    try {
      const uid = await context.dispatch('getUserId')
      const bplansSnapshop = await firebase.firestore().collection(`/users/${uid}/bplans`).get()
      bplansSnapshop.forEach((bplan) => {
        if (bplan.data().active) {
          activeBPlnaRef = bplan.ref.path
        }
      })

      // Загружаем Параграфы для добавления в разделы бизнес-плана
      const querySnapshop = await firebase.firestore().collection(`${activeBPlnaRef}/outlineTopics`).orderBy('title').get()
      querySnapshop.forEach(topic => {
        outlineTopics.push({
          id: topic.id,
          path: topic.ref.path,
          title: topic.data().title,
          type: topic.data().type,
          system: topic.data().system != undefined ? topic.data().system : false,
          body: topic.data().body
        })
      })
      
      // Загружаем Таблицы для добавления в разделы бизнес-плана
      const tablesSnapshop = await firebase.firestore().collection(`${activeBPlnaRef}/outlineTables`).orderBy('title').get()
      tablesSnapshop.forEach(table => {
          var newTableItem = table.data()
          newTableItem.id = table.id,
          newTableItem.path = table.ref.path,
          
          outlineTables.push(newTableItem)
      })

      // Загружаем Графики для добавления в разделы бизнес-плана
      const chartsSnapshop = await firebase.firestore().collection(`${activeBPlnaRef}/outlineCharts`).orderBy('title').get()
      chartsSnapshop.forEach(chart => {
          var newChartItem = chart.data()
          newChartItem.id = chart.id,
          newChartItem.path = chart.ref.path,
          
          outlineCharts.push(newChartItem)
      })

      context.commit('fetchOutlineTopics', outlineTopics)
      context.commit('fetchOutlineTables', outlineTables)
      context.commit('fetchOutlineCharts', outlineCharts)

    } catch (error) {

      window.console.log('Error to fetch outlineTopics', error) 

    }
  },

  // ANY DOCUMENT.title update
  async updateDocTitle(context, payload) {
    const docRef = firebase.firestore().doc(payload.path)

    try {
      docRef.set({
        title: payload.title
      }, {merge: true})
    } catch (error) {
      window.console.log('Title change error:', error)
    }
  },

  // ---- CHAPTERS EDIT ZONE ----- //
  // Добавляем новую главу
  async addChapter(context, {chapter, index}) {
    var id = '0'
    var path = ''
    
    try { 
      const uid = await context.dispatch('getUserId')
      const bplansRef = await firebase.firestore().collection(`users/${uid}/bplans`).get()
      var bplanPath = ''

      bplansRef.forEach(bplan => { 
        if (bplan.data().active) {bplanPath = bplan.ref.path}
      })

      const newChapterRef = firebase.firestore().collection(`${bplanPath}/currentOutline`).doc()
      
      id = newChapterRef.id
      path = newChapterRef.path

      await newChapterRef.set({
        id: newChapterRef.id,
        title: chapter.title,
        index: index,
      })
      
    } catch (error) {
      window.console.log(error)
    }

    return {id, path}
  },

  // Обновляем индексы глав при изменнии их понядка
  async updateChaptersIndexes(context, chapters) {
    var batch = firebase.firestore().batch()

    try {      
      for (var i=0; i < chapters.length; i++) {
        var chapterRef = firebase.firestore().doc(chapters[i].path)
        batch.update(chapterRef, {index: i})
      }
    } catch (error) {
      window.console.log(error)
    }

    batch.commit().then(function() {
      console.log('Индексы глав обновлены!')
    })
  },

  // Remove CHAPTER and return Topics to the system's list
  async currentOutlineChapterRemove(context, chapter) {
    console.log(chapter)
    const chapterRef = firebase.firestore().doc(`${chapter.path}`)
    var topics = []

    try {
      chapter.sections.forEach(async section => {
       
        try {
          const sectionQuery = await firebase.firestore().collection(`${section.path}/topics`).get()
        
          sectionQuery.forEach(topic => {
            var topicData = ''
            topicData = topic.data()
            topicData.id = topic.id
            topicData.path = topic.ref.path
            topics.push(topicData)
          })

        } catch (error) {
          console.log('Error to get topics inside chapter remover', error)
        }

        const sectionToDelete = firebase.firestore().doc(section.path)
        await sectionToDelete.delete()

      })

      context.dispatch('currentOutlineTopicsRemove', topics)
      chapterRef.delete()

    } catch (error) {
      console.log('Unadble to remove current outline topic: ', error)
    }
  },

  


  // ---- SECTION EDIT ZONE ---- //
  // Обновляем индексы секций при изменнии их понядка внутри главы
  async updateSectionsIndexes(context, sections) {
    var batch = firebase.firestore().batch()

    try {      
      for (var i=0; i < sections.length; i++) {
        var sectionRef = firebase.firestore().doc(sections[i].path)
        batch.update(sectionRef, {index: i})
      }
    } catch (error) {
      window.console.log(error)
    }

    batch.commit().then(function() {
      console.log('Индексы секций обновлены!')
    })
  },

  // Add SECTION to the CHAPTER
  async addSection(context, {section, index, chapterPath}) {
    var newSectionRef

    if (section.id != '0') {
      try { 
        var oldSectionRef = firebase.firestore().doc(`${section.path}`)
        newSectionRef = firebase.firestore().collection(`${chapterPath}/sections`).doc(`${section.id}`)
             
        try {
          var oldSectionData = await oldSectionRef.get()
          var querySnapshot = await firebase.firestore().collection(`${section.path}/topics`).get()
          
          newSectionRef.set((oldSectionData.data())).then(() => {
            querySnapshot.forEach(topic => {
              var newTopicRef = firebase.firestore().doc(`${chapterPath}/sections/${section.id}/topics/${topic.id}`)
              newTopicRef.set(topic.data())
            })
            
            oldSectionRef.delete()
          })
        } catch (error) {
          window.console.log(error)
        }
  
      } catch (error) {
        window.console.log(error)
      }
    } else {
      var id, path = ''
      
      try { 
        newSectionRef = firebase.firestore().collection(`${chapterPath}/sections`).doc()
        
        id = newSectionRef.id
        path = newSectionRef.path
  
        await newSectionRef.set({
          id: id,
          title: section.title,
          index: index,
        })
 
      } catch (error) {
        window.console.log(error)
      }
      
      return {id, path}
    }
  },

  // Remove Section and return Topics to the system's list
  async currentOutlineSectionRemove(context, section) {
    const sectionRef = firebase.firestore().doc(`${section.path}`)
    var topics = []

    try {
      const topicsQuery = await firebase.firestore().collection(`${section.path}/topics`).get()
      
      topicsQuery.forEach(topic => {
        var topicData = ''
        topicData = topic.data()
        topicData.id = topic.id
        topicData.path = topic.ref.path
        topics.push(topicData)
      })

      context.dispatch('currentOutlineTopicsRemove', topics)
      sectionRef.delete()

    } catch (error) {
      console.log('Unadble to remove current outline topic: ', error)
    }
  },

  // -----------------------------------------
  //  TOPIC operations section
  // Обновляем индексы Параграфов при изменнии их понядка внутри секции
  async updateTopicsIndexes(context, documents) {
    var batch = firebase.firestore().batch()
        
    try {      
      for (var i=0; i < documents.length; i++) {
        var topicRef = firebase.firestore().doc(documents[i].path)
        batch.update(topicRef, {index: i})
      }
    } catch (error) {
      window.console.log('Ошибка обновления индексов топиков', error)
    }

    batch.commit().then(function() {
      window.console.log('Индексы параграфов обновлены!')
    })
  },

  // Изменяем Section для Topic (переносим существующий Topic в другую Section)
  async addTopic(context, {topic, index, sectionPath}) {
    if (topic.id == '0') { 
      const newTopicRef = firebase.firestore().collection(`${sectionPath}/topics`).doc()
      
      try {
        newTopicRef.set({
          id: newTopicRef.id,
          type: topic.type,
          index: index,
          title: topic.title,
          showTitle: true,
          body: ''
        })
        return {id: newTopicRef.id, path: newTopicRef.path}
      } catch (error) {
        window.console.log(error)
      }
    } else {
      const oldTopicRef = firebase.firestore().doc(`${topic.path}`)
      const newTopicRef = firebase.firestore().collection(`${sectionPath}/topics`).doc(`${topic.id}`)
  
      try {
        newTopicRef.set((await oldTopicRef.get()).data()).then(() => {
          oldTopicRef.delete()
        })
      } catch (error) {
        window.console.log(error)
      }
    }
    
    
  },

  // Return Topics to the system's list
  async currentOutlineTopicsRemove(context, topics) {
    var activeBPlnaRef =''
    var topicType = 'outlineTextTopics'

    console.log('Current outline topic remover', topics)

    try {
      const uid = await context.dispatch('getUserId')
      const bplansSnapshop = await firebase.firestore().collection(`/users/${uid}/bplans`).get()
    
      // get current BPlan path
      bplansSnapshop.forEach((bplan) => {
        if (bplan.data().active) {
          activeBPlnaRef = bplan.ref.path
        }
      })

      // Move topics to current BPlan main list
      for (var i=0; i < topics.length; i++) {
        const oldTopicRef = firebase.firestore().doc(`${topics[i].path}`)
        
        switch (topics[i].type) {
          case 'textTopic':
            topicType = 'outlineTopics'
            break;
            case 'table':
              topicType = 'outlineTables'
            break;
            case 'chart':
              topicType = 'outlineCharts'
            break;
          default:
            topicType = 'outlineTopics'
            break;
        }

        firebase.firestore().collection(`${activeBPlnaRef}/${topicType}`).doc().set(
          (await oldTopicRef.get()).data()
        ).then(
          await oldTopicRef.delete()
        )
      }
     
      context.dispatch('fetchOutlineTopics')
    
    } catch (error) {
      console.log('Unadble to remove current outline topic: ', error)
    }
  },

  // Save TOPIC data from the Editor
  async saveTopicData(context, topic) {
    try {
      await firebase.firestore().doc(topic.path).set({
        title: topic.title,
        showTitle: topic.showTitle,
        body: topic.body,
      }, {merge: true})
    } catch (error) {
      window.console.log('Unable save topic updated data: ', error)      
    }
  },

  // Deleting User's custom TOPIC from the BPlan
  async deleteUserTopic(context, {topic, index}) {
    try {
      await firebase.firestore().doc(topic.path).delete()
      context.commit('deleteUserTopic', index)
    } catch (error) {  
      window.console.log('Unable save topic updated data: ', error)      
    }
  }

}



// ----------------------------------- //
// -------- EXPORT SECTION ----------- //

export default {
  state, getters, mutations, actions
}