import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase/app'

import store from './store';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Секция лендинга и связанных с ним страниц
    {
      path: '/',
      name: 'landing',
      meta: {layout: 'empty'},
      component: () => import('./views/Landing.vue')
    }, {
      path: '/features',
      name: 'features',
      meta: {layout: 'empty'},
      component: () => import('./views/landing/Features.vue')
    },
    // Секция работы с авторизацией
    {
      path: '/registration',
      name: 'registration',
      component: () => import('./views/Auth/RegistrationWizard.vue')
    }, {
      path: '/login',
      name: 'login',
      meta: {layout: 'empty'},
      component: () => import('./views/Auth/Login.vue')
    }, {
      path: '/login/forgot',
      name: 'forgot',
      meta: {layout: 'empty'},
      component: () => import('./views/Auth/LoginPasswordForgot.vue')
    }, {
      path: '/login/reset',
      name: 'reset',
      meta: {layout: 'empty'},
      component: () => import('./views/Auth/LoginPasswordReset.vue')
    }, {
      path: '/login/confirm-email',
      name: 'confirm-email',
      component: () => import('./views/Auth/LoginPasswordResetMailSentConfirm.vue')
    }, {
      path: '/logout',
      name: 'logout',
      meta: {layout: 'empty'},
      component: () => import('./views/Auth/Logout.vue')
    }, {
      path: '/user-profile',
      name: 'user-profile',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/UserProfile.vue')
    }, {
      path: '/plans',
      name: 'plans',
      meta: {layout: 'empty'},
      component: () => import('./views/Plans.vue')
    }, {
      path: '/subscription/success',
      name: 'subscription-success',
      meta: {layout: 'empty'},
      component: () => import('./views/PaymentSuccess.vue')
    }, {
      path: '/subscription/error',
      name: 'subscription-error',
      meta: {layout: 'empty'},
      component: () => import('./views/PaymentError.vue')
    }, {
      path: '/user/plans',
      name: 'user-plans',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Plans.vue')
    }, {
      path: '/checkout',
      name: 'checkout',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Checkout.vue')
    }, {
      path: '/dashboard',
      name: 'dashboard',
      meta: {layout: 'main', auth: true,  menu: ''},
      component: () => import('./views/Dashboard.vue')
    }, {
      path: '/bplan',
      name: 'bplan',
      meta: {layout: 'main', auth: true, menu: 'bplan'},
      component: () => import('./views/OutlineRender.vue')
    }, {
      path: '/bplan/outline',
      name: 'outline',
      meta: {layout: 'main', auth: true, menu: 'outline'},
      component: () => import('./views/OutlineEdit.vue')
    }, {
      path: '/bplan/download',
      name: 'bplan-download',
      meta: {layout: 'main', auth: true, menu: ''},
      component: () => import('./views/BPlanDownload.vue')
    }, {
      path: '/finance',
      name: 'finance',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/Revenue.vue')
    }, {
      path: '/finance/revenue',
      name: 'finance',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/Revenue.vue')
    }, {
      path: '/finance/direct-costs',
      name: 'direct-costs',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/DirectCosts.vue')
    }, {
      path: '/finance/commercial-expenses',
      name: 'commercial-expenses',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/CommercialExpenses.vue')
    }, {
      path: '/finance/common-expenses',
      name: 'common-expenses',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/CommonExpenses.vue')
    }, {
      path: '/finance/profit-and-loss',
      name: 'profit-and-loss',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/ProfitAndLoss.vue')
    }, {
      path: '/finance/cash-flow',
      name: 'cash-flow',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/CashFlow.vue')
    }, {
      path: '/finance/balance',
      name: 'balance',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/Balance.vue')
    }, {
      path: '/finance/assets',
      name: 'assets',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/Assets.vue')
    }, {
      path: '/finance/financing',
      name: 'financing',
      meta: {layout: 'main', auth: true, menu: 'finance'},
      component: () => import('./views/Finance/Financing.vue')
    }, {
      path: '/investments',
      name: 'investments',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Errors/500.vue')
    }, {
      path: '/pricing',
      name: 'pricing',
      meta: {layout: 'empty', auth: false},
      component: () => import('./views/HomePricing.vue')
    }, {
      path: '/test',
      name: 'test',
      meta: {layout: 'main', auth: true},
      component: () => import('./components/app/Test.vue')
    }, {
      path: '/projects',
      name: 'projects',
      meta: {layout: 'main', auth: true,  menu: 'projects'},
      component: () => import('./views/Projects.vue')
    }, {
      path: '/milestones',
      name: 'milestones',
      meta: {layout: 'main', auth: true,  menu: 'milestones'},
      component: () => import('./views/Milestones.vue')
    }, 
    // Презентация
    {
      path: '/pitch',
      name: 'pitch',
      meta: {layout: 'main', auth: true,   menu: ''},
      component: () => import('./views/PitchNew.vue')
    }, {
      path: '/new-pitch',
      name: 'pitch',
      meta: {layout: 'main', auth: true,   menu: 'pitch'},
      component: () => import('./views/PitchNew.vue')
    }, {
      path: '/pitch/preview',
      name: 'pitch-preview',
      meta: {layout: 'main', auth: true,   menu: ''},
      component: () => import('./views/pitch/PitchPreview.vue')
    }, {
      path: '/pitch/view',
      name: 'pitch-view',
      meta: {layout: 'empty', menu: ''},
      component: () => import('./views/pitch/PitchPublic.vue')
    },
    // Блогосфера
    {
      path: '/blog',
      name: 'blog-main',
      meta: {layout: 'blog', auth: false,   menu: ''},
      component: () => import('./views/blog/BlogMain.vue')
    }, {
      path: '/blog/post',
      name: 'blog-main',
      meta: {layout: 'blog', auth: false,   menu: ''},
      component: () => import('./views/blog/BlogPost.vue')
    }, {
      path: '/blog/newPost',
      name: 'blog-main',
      meta: {layout: 'blog', auth: true,   menu: ''},
      component: () => import('./views/blog/BlogNewPost.vue')
    }
  ]
})

/* eslint-enable no-alert */

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)
  // const userInfo = store.getters.userInfo
  
  // if (requireAuth && userInfo) {
  //   if (!userInfo.regFinished) {
  //     next('/registration')
  //   }
  // } else {
  //   next()
  // }

  if (requireAuth && !currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
