import Vue from 'vue';
import firebase from 'firebase';

// -----------------------------------------

const state = {
  pitch: null,
  publicPitch: null,
  marketChartSegmentToEdit: null,
  competitorToEdit: null,
  teamMemberToEdit: null,
  resourceToEdit: null
}

// -----------------------------------------

const getters = {
  getPitch: state => state.pitch,
  getPublicPitch: state => state.publicPitch,
  getMarketChartSegmentToEdit: state => state.marketChartSegmentToEdit,
  getCompetitorToEdit: state => state.competitorToEdit,
  getTeamMemberToEdit: state => state.teamMemberToEdit,
  getResourceToEdit: state => state.resourceToEdit,
}

// -----------------------------------------

const mutations = {

  setPitch(state, payload) {
    state.pitch = payload;
  },

  setPublicPitch(state, payload) {
    state.publicPitch = payload;
  },

  setPitchCompanyDesc(state, payload) {
    state.pitch.company.desc = payload;
  },

  setPitchCompanyLogo(state, payload) {
    state.pitch.company.logo = payload;
  },

  setPitchProblemSelectedType(state, payload) {
    state.pitch.problem.selectedType = payload;
  },

  setPitchProblemDesc(state, payload) {
    state.pitch.problem.desc = payload;
  },

  setPitchProblemList(state, payload) {
    state.pitch.problem.list = payload;
  },

  setPitchSolutionSelectedType(state, payload) {
    state.pitch.solution.selectedType = payload;
  },

  setPitchSolutionDesc(state, text) {
    state.pitch.solution.desc = text;
  },

  setPitchSolutionList(state, payload) {
    state.pitch.solution.list = payload;
  },

  setPitchMarketSelectedType(state, payload) {
    state.pitch.market.selectedType = payload;
  },

  addPitchMarketChartSegment(state, item) {
    Vue.set(state.pitch.market.chart, item.index, item);
  },

  setMarketChartSegmentToEdit(state, payload) {
    if (!payload) { // если нет входящих данных, создаем новый элемент
      state.marketChartSegmentToEdit = {
        index: state.pitch.market.chart.length,
        title: '',
        prospectus: 0,
        spendings: 0,
      }
    } else {
      state.marketChartSegmentToEdit = {
        index: payload.index,
        title: payload.title,
        prospectus: payload.prospectus,
        spendings: payload.spendings,
      }
    }
  },

  deleteMarcketChartSegment(state, index) {
    Vue.delete(state.pitch.market.chart, index);
  },

  setPitchMarketList(state, payload) {
    state.pitch.market.list = payload;
  },

  setPitchCompetitorsListItem(state, item) {
    Vue.set(state.pitch.competitors.list, item.index, item);
  },

  setPitchCompetitorToEdit(state, payload) {
    if (!payload) { // если нет входящих данных, создаем новый элемент
      state.competitorToEdit = {
        index: state.pitch.competitors.list.length,
        title: '',
        desc: '',
      }
    } else {
      state.competitorToEdit = {
        index: payload.index,
        title: payload.title,
        desc: payload.desc,
      }
    }
  },

  deletePitchCompetitorsListItem(state, index) {
    Vue.delete(state.pitch.competitors.list, index);
  },

  setPitchInvestmentsAmount(state, amount) {
    state.pitch.investments.amount = amount;
  },

  setPitchInvestmentsDesc(state, text) {
    state.pitch.investments.desc = text;
  },

  setPitchInvestmentsNeedFinance(state, payload) {
    state.pitch.investments.needFinance = payload;
  },

  setPitchSalesSelectedType(state, payload) {
    state.pitch.sales.selectedType = payload;
  },

  setPitchSalesDesc(state, text) {
    state.pitch.sales.desc = text;
  },

  setPitchSalesList(state, payload) {
    state.pitch.sales.list = payload;
  },

  setPitchMarketingSelectedType(state, payload) {
    state.pitch.marketing.selectedType = payload;
  },

  setPitchMarketingDesc(state, text) {
    state.pitch.marketing.desc = text;
  },

  setPitchMarketingList(state, payload) {
    state.pitch.marketing.list = payload;
  },

  setPitchForecastSelectedType(state, payload) {
    state.pitch.forecast.selectedType = payload;
  },

  setPitchForecastRevenueSelectedType(state, payload) {
    state.pitch.forecast.desc.revenueSelected = payload;
  },

  setPitchForecastRevenueDesc(state, text) {
    state.pitch.firecast.desc.revenueDesc = text;
  },

  setPitchForecastRevenueList(state, payload) {
    state.pitch.forecast.desc.revenueList = payload;
  },

  setPitchForecastExpnsSelectedType(state, payload) {
    state.pitch.forecast.desc.expnsSelected = payload;
  },

  setPitchForecastExpnsDesc(state, text) {
    state.pitch.firecast.desc.expnsDesc = text;
  },

  setPitchForecastExpnsList(state, payload) {
    state.pitch.forecast.desc.expnsList = payload;
  },

  addPitchTeamListItem(state, item) {
    Vue.set(state.pitch.team.list, item.index, item);
  },

  setPitchTeamMemberToEdit(state, payload) {
    if (!payload) { // если нет входящих данных, создаем новый элемент
      state.teamMemberToEdit = {
        index: state.pitch.team.list.length,
        avatar: null,
        fio: '',
        position: '',
        desc: '',
      }
    } else {
      state.teamMemberToEdit = {
        index: payload.index,
        avatar: payload.avatar,
        fio: payload.fio,
        position: payload.position,
        desc: payload.desc,
      }
    }
  },

  deletePitchTeamListItem(state, index) {
    Vue.delete(state.pitch.team.list, index);
  },

  addPitchResourcesListItem(state, item) {
    Vue.set(state.pitch.resources.list, item.index, item);
  },

  setPitchResourceToEdit(state, payload) {
    if (!payload) { // если нет входящих данных, создаем новый элемент
      state.resourceToEdit = {
        index: state.pitch.resources.list.length,
        image: null,
        title: '',
        desc: '',
      }
    } else {
      state.resourceToEdit = {
        index: payload.index,
        image: payload.image,
        title: payload.title,
        desc: payload.desc,
      }
    }
  },

  deletePitchResourcesListItem(state, index) {
    Vue.delete(state.pitch.resources.list, index);
  },

}

// -----------------------------------------

const actions = {

  async fetchPublicPitch (context, payload) {
    
    try {
      const pitchDoc = await firebase.firestore().doc(`publicPitch/${payload}`).get();
      var pitchData = pitchDoc.data();
      
      pitchData.id = pitchDoc.id;
      pitchData.path = pitchDoc.ref.path;

      context.commit('setPublicPitch', pitchData);

    } catch (error) {
      window.console.log('Unable to load pitch data from DB: ', error);
    }

      
  },

  async fetchPitch (context) {
    
    var bplan = context.getters.getActiveBPlan;

    if (!bplan) {
      bplan = await context.dispatch('fetchActiveBPlan');
      bplan = context.getters.getActiveBPlan;
    }
    
    try {
      const pitchDoc = await firebase.firestore().doc(`${bplan.path}/pitch/main`).get();
      var pitchData = pitchDoc.data();
      pitchData.id = pitchDoc.id;
      pitchData.path = pitchDoc.ref.path;

      context.commit('setPitch', pitchData);

    } catch (error) {
      window.console.log('Unable to load pitch data from DB: ', error);
    }

      
  },

  async savePitch (context) {
    const uid = await context.dispatch('getUserId');
    var bplan = context.getters.getActiveBPlan;
    var pitchData = context.getters.getPitch;

    if (!bplan) {
      bplan = await context.dispatch('fetchActiveBPlan');
      bplan = context.getters.getActiveBPlan;
    }

    delete pitchData.path;

    try {
      await firebase.firestore().doc(`${bplan.path}/pitch/main`).set(
        pitchData,
        { merge: true }
      );

      // Формируем данные для внешней публикации
      var publicPitch = pitchData;
      const chartRevenue = await context.dispatch(`revenueChartAllTimeData`, {width: null, height: 300});
      const chartFinancialHighlights = await context.dispatch(`financialHighlightsByYearData`, {width: null, height: 300});
      const milestones = context.getters.getMilestonesList;

      publicPitch.company.title = bplan.title;
      publicPitch.company.startYear = bplan.startYear;
      publicPitch.milestones = milestones;
      publicPitch.charts = {}  
      publicPitch.charts.revenue = chartRevenue.series[0].data;
      publicPitch.charts.financialHighlights = chartFinancialHighlights.series;

      await firebase.firestore().doc(`publicPitch/${uid}`).set(
        publicPitch,
        { merge: true }
      )

    } catch (error) {
      window.console.log('Unable to save pitch data to DB: ', error);
    }

  }

}



export default {
    state, getters, mutations, actions
  }