var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-vertical navbar-expand-xl mt-8 border-top border-600"},[_c('div',{staticClass:"mt-3",attrs:{"id":"navbarVerticalCollapse"}},[_c('ul',{staticClass:"navbar-nav flex-column mt-3"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(0),_c('router-link',{attrs:{"to":"/finance/revenue"}},[_c('span',[_vm._v("Выручка")])])],1)])]),_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"nav-link"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(1),_c('router-link',{attrs:{"to":"/finance/direct-costs","exact":""}},[_c('span',[_vm._v("Себестоимость")])])],1)])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(2),_c('router-link',{attrs:{"to":"/finance/commercial-expenses"}},[_c('span',[_vm._v("Коммерческие расходы")])])],1)])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(3),_c('router-link',{attrs:{"to":"/finance/common-expenses"}},[_c('span',[_vm._v("Общехозяйственные расходы")])])],1)])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(4),_c('router-link',{attrs:{"to":"/finance/assets"}},[_c('span',[_vm._v("Основные средства")])])],1)])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(5),_c('router-link',{attrs:{"to":"/finance/financing"}},[_c('span',[_vm._v("Финансирование")])])],1)])])]),_vm._m(6),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-link-icon"},[_c('span',{staticClass:"fas fa-chart-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-link-icon"},[_c('i',{staticClass:"fas fa-industry"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-link-icon"},[_c('i',{staticClass:"fas fa-poll"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-link-icon"},[_c('i',{staticClass:"far fa-building"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-link-icon"},[_c('i',{staticClass:"fas fa-car"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-link-icon"},[_c('i',{staticClass:"fas fa-landmark"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 px-xl-0"},[_c('hr',{staticClass:"border-300 my-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-light mt-5"},[_c('h6',{staticClass:"alert-heading font-weight-bold border-bottom mb-2 pb-2"},[_vm._v(" Финансовый прогноз ")]),_c('p',{staticClass:"fs--2 mb-2"},[_vm._v(" Финансовый прогноз один из ключевых элементов бизнес-плана. ")]),_c('p',{staticClass:"fs--2 mb-2"},[_vm._v(" От вас не требуется быть финансовым экспертом, просто последовательно заполните каждый из разделов (если требуется). ")]),_c('hr'),_c('p',{staticClass:"fs--2 mb-0"},[_vm._v(" Мы позаботимся обо всем остальном! Составим прогнозные финансовые отчеты и расчетаем ключевые показатели проекта. ")])])
}]

export { render, staticRenderFns }