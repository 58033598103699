<template>
  <draggable
    :class="{
      'dragArea': topics.length == 0,
      'ml-4': mainClass == 'ml-4'
      }" 
    :list="topics"
    :sort="false"
    :group="{ name: 'topic', put: false }">
    <div class="d-flex"
      :class="itemClass"
      v-for="(topic, index) in topics" :key="topic.id">
      <div style="white-space: nowrap; width: 255px; overflow: hidden;text-overflow: ellipsis;">
        <i v-if="topic.type == 'textTopic'" class="far fa-file-alt fs-1 mr-2"></i>
        <i v-if="topic.type == 'table'" class="fas fa-th-list fs-1 mr-2"></i>
        <span class="font-weight-normal fs--1">
          {{ topic.title }}
        </span>
      </div>
      <div v-if="!topic.system"
        class="btn btn-outline-dark btn-sm delete-btn ml-2 px-2"
        @click="deleteTopic(topic, index)">
        <i class="far fa-trash-alt"></i>
      </div>
    </div>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
  props: {
    topics: {
      required: true,
      type: Array
    },
    mainClass: {
      required: false,
      type: String
    },
    itemClass: {
      required: false,
      type: String
    }
  },
  components: {
    draggable
  },
  methods: {
    deleteTopic(topic, index) {
      if (!topic.system) {
        this.$store.dispatch('deleteUserTopic', {topic, index})
      }
    }
  }

};
</script>
<style lang="scss" scoped>

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
  }
}

.dragArea {
  min-height: 30px;
  border: 1px dashed rgba(255,255,255,0.1);
  border-radius: 3px;
  margin-top: 1rem;
}

.element {
  border: 1px solid rgba(145, 145, 145, 0.1);
}



</style>