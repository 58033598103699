<template>

  <footer class="bg-dark py-2 border-top border-600">
    <div class="container">
      <div class="row no-gutters justify-content-between fs--1 mt-0 pt-0 mb-1">
      <div class="col-12 col-sm-auto text-center">
        <p class="mb-0 text-600">
          Все права защищены <span class="d-none d-sm-inline-block">| </span>
          <br class="d-sm-none" /> 2018 - 2020 &copy; 
          <a href="https://rsystems.io">
            R'systems
          </a>
          - передовые технологии!
        </p>
      </div>
      <div class="col-12 col-sm-auto text-center">
        <p class="mb-0 text-600">beta v0.27.5</p>
      </div>
    </div>
    </div>
    
  </footer>
  
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  z-index: 10000;
}

</style>