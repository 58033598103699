export const func = { 
  arrSum: (arr) => {
    return arr.reduce((a,b) => a + b, 0) 
  },

  itemsSum: (arr) => {
    var itemsSum = []
    
    for (var i=0; i < arr.length; i++) {
      itemsSum = arr[i].map((value, index) => {
        if (itemsSum[index]) {
          return itemsSum[index] + value
        } else {
          return value
        }
      })
    }

    return itemsSum
  },

  colSum: (arrs) => {
    var arrsSum = []
    
    for (var i=0; i < arrs.length; i++) {
      arrsSum = arrs[i].map((value, index) => {
        if (arrsSum[index]) {
          return arrsSum[index] + value
        } else {
          return value
        }
      })
    }
    return arrsSum
  },

  cfBalance: (periods, balance, changesArr, activeYear = 0) => {
    var startResult = []
    var endResult = []
    for (var i = 0; i < periods; i++) {
      if (i == activeYear + 4) {
        balance = startResult[activeYear]
      }

      startResult.push(balance)
      balance += changesArr[i]
      endResult[i] = balance;
    }

    return {start: startResult, end: endResult}
  },

  taxShare: (arr, share) => {
    var result = []
    var taxableAmount = 0;
    
    result = arr.map((value) => {
      
      if (value < 0 || taxableAmount < 0) {
        taxableAmount = taxableAmount + value
      } else {
        taxableAmount = value
      }

      if (taxableAmount > 0) {
        if (share) {
          var tax = taxableAmount * share
          taxableAmount = 0
          return tax
        } else {
          return 0
        }
      } else {
        return 0
      }
    })
    
      return result
  },

  itemsShare: (arr, share) => {
    var result = []
    result = arr.map((value) => {
      if (share) {
        return (value * share)
      } else {
        return 0
      }
    })
    
      return result
  },

  ratio: (arr1, arr2) => {
    var ratio = []
    ratio = arr1.map((value, index) => {
      if (arr2[index] && value) {
        return ((arr2[index] / value) * 100)
      } else {
        return null
      }
    })
    
      return ratio
  },

  yearsSum: (yearsData, key) => {
    var sumData = []
    var yearsArr = []
    // Берем поочередно записи по каждому году
    for (var y=0; y < Object.values(yearsData[0][key]).length; y++) {
      var yearArr = []
      // Объединяем записи выручки по годам
      for (var r=0; r < yearsData.length; r++) {
        yearArr.push(Object.values(yearsData[r][key])[y])
      }

      yearsArr.push(yearArr)
    }

    // Суммируем данные по годам
    sumData = yearsArr.map(item => {
      var itemsSum = []
  
      for (var i=0; i < item.length; i++) {
        itemsSum = item[i].map((value, index) => {
          if (itemsSum[index]) {
            return itemsSum[index] + value
          } else {
            return value
          }
        })
      }
      return itemsSum
    })

    return sumData
  },
  
  

}