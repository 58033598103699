import Vue from 'vue'
import firebase from 'firebase'
import moment from 'moment'

const state = {
  statistics: {}
}

const getters = {
  spendingsByMonths: state => {
    if ('2019' in state.statistics) {
      return state.statistics[moment().year()].spendings.months
    }
  },
}
const mutations = {
  fetchStatistics(state, payload) {
    Vue.set(state.statistics, payload.id, payload)
  },
}

const actions = {
  async fetchStatistics(context) {
    try {
      const uid = await context.dispatch('getUserId')
      firebase.firestore().collection(`users/${uid}/statistics`).get()
      .then(querySnapshot => {
        if (querySnapshot) {
          querySnapshot.forEach(item => {
            context.commit('fetchStatistics', {
              id: item.id,
              spendings: item.data().spendings,
              incomes: item.data().incomes
            })
          })
        }
      }) 
    } catch (error) {
      console.log('Fetch statistic error:', error)
    }
  },

  async increaseCategorySpendingsStat(context, payload) {
    const month = (moment(payload.date).month() + 1).toString()
    try {
      const uid = await context.dispatch('getUserId')
      payload.categories.forEach(category => {
        if (category.categoryId != '00' && payload.type != 'transfer') {
          const categoryTitle = category.categoryId == category.parentCategory ? `${category.categoryTitle} - прочее` : category.categoryTitle
          const parentCategory = category.categoryId == category.parentCategory ? {id: category.categoryId, title: category.categoryTitle} : category.parentCategory
          firebase.firestore().collection(`users/${uid}/statistics/`).doc('2019').set({
            [`${payload.type}s`]: {
              'months': {
                [month]: {
                  [category.categoryId]: {
                    categoryId: category.categoryId,
                    categoryTitle: categoryTitle,
                    parentCategory: parentCategory,
                    amount: firebase.firestore.FieldValue.increment(category.amount)
                  }
                }
              }
            }
          }, {merge: true})
          context.dispatch('fetchStatistics')
        }
      })
    } catch (error) {
      
    }
  },
  
  async decreaseCategorySpendingsStat(context, payload) {
    const month = (moment(payload.date).month() + 1).toString()
    try {
      const uid = await context.dispatch('getUserId')
      console.log('decreaseCategorySpendingsStat', payload)
      payload.categories.forEach(category => {
        if (category.categoryId != '00' && payload.transactionType != 'transfer') {
          const categoryTitle = category.categoryId == category.parentCategory ? `${category.categoryTitle} - прочее` : category.categoryTitle
          const parentCategory = category.categoryId == category.parentCategory ? {id: category.categoryId, title: category.categoryTitle} : category.parentCategory  
          firebase.firestore().collection(`users/${uid}/statistics/`).doc('2019').set({
            [`${payload.transactionType}s`]: {
              'months': {
                [month]: {
                  [category.categoryId]: {
                    categoryId: category.categoryId,
                    categoryTitle: categoryTitle,
                    parentCategory: parentCategory,
                    amount: firebase.firestore.FieldValue.increment(category.amount * -1)
                  }
                }
              }
            }
          }, {merge: true})
          context.dispatch('fetchStatistics')
        }
      })
      
    } catch (error) {
      console.log('Error in decreasing stat data', error)
    }
  }
}

export default {
  state, getters, mutations, actions
}