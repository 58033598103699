import numeral from 'numeral'

const state = {
  tablePL: []
}

const getters = {
  getTablePL: state => {
    return state.tablePL;
  }
}

const mutations = {
  setTablePL (state, payload) {
    state.tablePL = payload
  }
}

const actions = {
  async calculateTablePL(context, payload = { activeYear: 0 }) {
    
    try {
      var tableData = [];
     
      //  Вначале расчитываем и форматирум все разделы таблицы

      // Получаем данные по Выручке в форматированном виде
      const incomesTableData = await context.dispatch('incomeSectionData', {
        title: 'Выручка от реализации', 
        activeYear: payload.activeYear 
      });

      // Получаем данные по Себестоимости в форматированном виде
      const directCostTableData = await context.dispatch('sectionRender', { 
        list: context.getters.getDirectCostsList, 
        title: 'Себестоимость',
        activeYear: payload.activeYear,
      });

      // Получаем данные по Коммерческим расходам в форматированном виде
      const commercialExpnsTableData = await context.dispatch('sectionRender', { 
        list: context.getters.getCommercialExpnsList, 
        title: 'Коммерческие расходы',
        activeYear: payload.activeYear,
      });

      // Получаем данные по Общехозяйственным расходам в форматированном виде
      var commonExpnsTableData = await context.dispatch('sectionRender', { 
        list: context.getters.getCommonExpnsList, 
        title: 'Общехозяйственные расходы',
        activeYear: payload.activeYear, 
      });

      // Получаем данные по Амортизации
      const depreciationData = context.getters.getAssetsList.map( item => {
        return {
          title: item.title,
          values: item.depreciationValues,
        }
      });
      // Получаем данные по Амортизации в форматированном виде
      const depreciationTableData = await context.dispatch('sectionRender', { 
        list: depreciationData, 
        title: 'Аммортизация',
        activeYear: payload.activeYear, 
      });

      // Корретируем итоговое значение Основных расходов на амортизацию
      //! Сумма Амортизации включается в Общехозяйственные расходы! Внимательно при расчете основных показатлей!
      const commonWithDepreciation = this._vm.$func.colSum([commonExpnsTableData.total, depreciationTableData.total])      
      commonExpnsTableData.rows[0].values = commonWithDepreciation
      commonExpnsTableData.total = commonWithDepreciation

      // Получаем данные по Прочим доходам в форматированном виде
      //TODO Пока тут пустые значения, но в дальнейшем необходимо их расчитвыть
      const otherIncomesTableData = await context.dispatch('sectionRender', { 
        list: [{
          values: {
            0: [null, null, null, null, null, null, null, null, null, null, null, null],
            1: [null, null, null, null, null, null, null, null, null, null, null, null],
            2: [null, null, null, null, null, null, null, null, null, null, null, null],
          }
        }], 
        title: 'Прочие доходы, всего',
        activeYear: payload.activeYear, 
      });

      
      // Расходы по % платежам включаем в прочие расходы
      const interestData = context.getters.getFinancingList.map( item => {
        return {
          title: item.title,
          values: item.interestPayments,
        }
      });

      const interestTableData = await context.dispatch('sectionRender', { 
        list: interestData, 
        title: 'Проценты к уплате',
        activeYear: payload.activeYear, 
      });
      
      // Получаем данные по Прочим расходам в форматированном виде
      var otherExpnsTableData = []
      if (interestTableData.rows[0].values.length > 0) {
        otherExpnsTableData = interestTableData.rows[0].values
      } else {
        otherExpnsTableData = payload.activeYear > -1 ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0]
      }
     
      // Расчитываем Маржинальную прибыль
      const marginProfit = this._vm.$func.colSum([incomesTableData.total, directCostTableData.total])

      // Расчитываем прибыль от продаж (включает амортизацию, которая отражена в Общехозяйственных расходах)
      const salesProfit = this._vm.$func.colSum([marginProfit, commercialExpnsTableData.total, commonExpnsTableData.total])

      // Расчитываем доход до налогооблажения
      const EBIT = this._vm.$func.colSum([salesProfit, otherExpnsTableData])
      // Расчитываем налог на прибыль
      const incomeTax = this._vm.$func.taxShare(EBIT, -0.15)

      //TODO Сейчас в налогах учитывается только налог на прибыль, нужно добавить остальные.
      const totalTaxes = this._vm.$func.colSum([incomeTax])      
      
      tableData = tableData.concat(incomesTableData.rows)
      tableData = tableData.concat(directCostTableData.rows)

      tableData.push({
        type: 'categoryTotalResult',
        title: 'Валовая прибыль',
        values: marginProfit
      }, {
        type: 'subCategoryTotalResult',
        title: 'рентабельность по Валовой прибыли',
        values: this._vm.$func.ratio(incomesTableData.total, marginProfit)
      })

      tableData = tableData.concat(commercialExpnsTableData.rows)
      tableData = tableData.concat(commonExpnsTableData.rows)
      
      // Добавыляем данные по амортизации в Общехозяйственные расходы
      tableData.push({
        type: 'categoryItem',
        title: 'Амортизация ОС и НМА',
        values: depreciationTableData.total
      });
      
      tableData.push({
        type: 'categoryTotalResult',
        title: 'Прибыль (убыток) от продаж',
        values: salesProfit
      }, {
        type: 'subCategoryTotalResult',
        title: 'рентабельность Продаж',
        values: this._vm.$func.ratio(incomesTableData.total, salesProfit)
      })
      
      // Добавляем отображение разделов Прочих доходов и Прочиех расходов
      tableData = tableData.concat(otherIncomesTableData.rows[0]) // только итоговое значение
      tableData.push({
        type: 'categoryTotal',
        title: 'Прочие расходы',
        values: otherExpnsTableData
      }); 
      
      if (interestTableData.rows.length > 1) {
        tableData.push({
          type: 'categoryItem',
          title: 'Проценты к уплате',
          values: interestTableData.rows[0].values
        });
      } 
        

      // Значение, в дальнейшем, тоже необходимо будет расчитывать 
      tableData.push({
        type: 'categoryTotalResult',
        title: 'Прибыль (убыток) до налогообложения',
        values: EBIT
      })

      tableData.push({
        type: 'categoryTotal',
        title: 'Налоговые платежи',
        values: totalTaxes
      }, {
        type: 'categoryItem',
        title: 'текущий налог на прибыль',
        values: incomeTax
      });

      //TODO нужно вынести в отдельную функцию
      const netProfit = this._vm.$func.colSum([EBIT, totalTaxes])

      tableData.push({
        type: 'categoryTotalResult',
        title: 'Чистая прибыль (убыток)',
        values: netProfit
      }, {
        type: 'subCategoryTotalResult',
        title: 'рентабельность по Чистой прибыли',
        values: this._vm.$func.ratio(incomesTableData.total, netProfit)
      })

      context.commit('setTablePL', tableData)

      return {tableData, netProfit, incomeTax } 

    } catch (error) {
      window.console.log(error)
    }
  },

  incomeSectionData(context, payload) {
    var incomes = context.getters.getRevenueList
    var valueRows = []
    var rows = []
    var colsSum = []

    for (var i = 0; i < incomes.length; i++) {
        
      var qValues = []

     
      // Суммируем данные для представления по годам
      for (var y = 0; y < Object.values(incomes[i].values).length; y++) {
        const values = Object.values(incomes[i].values)[y]
        // Выбранный год детализируется поквартально
        

        if (y == payload.activeYear) {  
          qValues.push(this._vm.$func.arrSum(values.slice(0, 3)));
          qValues.push(this._vm.$func.arrSum(values.slice(3, 6)));
          qValues.push(this._vm.$func.arrSum(values.slice(6, 9)));
          qValues.push(this._vm.$func.arrSum(values.slice(9, 12)));
          qValues.push(this._vm.$func.arrSum(values));
        } else {
          qValues.push(this._vm.$func.arrSum(values));
        }
      }
            
      const data = {
        type: 'categoryItem',
        title: incomes[i].title,
        values: qValues
      }
      
      valueRows.push(qValues)
      rows.push(data)
    }

    if (incomes.length == 0) {
      
      colsSum = payload.activeYear > -1 ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0];
      
      rows.unshift({
        type: 'categoryTotal',
        title: payload.title,
        values: colsSum
      });
    } else {
      
      colsSum = this._vm.$func.colSum(valueRows);

      rows.unshift({
        type: 'categoryTotal',
        title: payload.title,
        values: colsSum
      });
    }
      
    return {rows, total: colsSum}
  },

  sectionRender(context, payload) {
    var list = payload.list || []
    var rows = []
    var valueRows = []
    var colsSum = []
    var negative = payload.notNegative ? 1 : -1

    for (var i = 0; i < list.length; i++) {
      
      var qValues = []

      for (var y = 0; y < Object.values(list[i].values).length; y++) {
        // Выбираем значения над которыми работаем
        const values = Object.values(list[i].values)[y]
        // Выбранный год детализируется поквартально
        if (y == payload.activeYear) {  
          qValues.push(this._vm.$func.arrSum(values.slice(0, 3)) * negative);
          qValues.push(this._vm.$func.arrSum(values.slice(3, 6)) * negative);
          qValues.push(this._vm.$func.arrSum(values.slice(6, 9)) * negative);
          qValues.push(this._vm.$func.arrSum(values.slice(9, 12)) * negative);
          qValues.push(this._vm.$func.arrSum(values) * negative);
        } else {
          qValues.push(this._vm.$func.arrSum(values) * negative);
        }
      }
       
      const data = {
        type: 'categoryItem',
        title: list[i].title,
        values: qValues
      }

      valueRows.push(qValues)
      rows.push(data)
    }

    if (list.length == 0) {
      
      payload.activeYear > -1 ? colsSum = [0, 0, 0, 0, 0, 0, 0] : colsSum = [0, 0, 0];
      
      
      rows.unshift({
        type: 'categoryTotal',
        title: payload.title,
        values: colsSum
      });
    } else {
      
      colsSum = this._vm.$func.colSum(valueRows);

      rows.unshift({
        type: 'categoryTotal',
        title: payload.title,
        values: colsSum
      });
    }
    
    return {rows, total: colsSum}
  },

  async incomeTaxCalculation(context, payload) {
    
    if ( context.getters.getIncomesList.length == 0 ) {
      await context.dispatch('fetchIncomesList')
    }
    if ( context.getters.getDirectCostsList.length == 0 ) {
      await context.dispatch('fetchDirectCostsList')
    }
    if ( context.getters.getCommercialExpnsList.length == 0 ) {
      await context.dispatch('fetchCommercialExpnsList')
    }
    if ( context.getters.getCommonExpnsList.length == 0 ) {
      await context.dispatch('fetchCommonExpnsList')
    }
    if ( context.getters.getAssetsList.length == 0 ) {
      await context.dispatch('fetchAssetsList')
    }
    // Получаем данные по Выручке в форматированном виде
    const incomesTableData = await context.dispatch('incomeSectionData', {
      title: 'Выручка от реализации', 
      activeYear: payload.activeYear 
    });

    // Получаем данные по Себестоимости в форматированном виде
    const directCostTableData = await context.dispatch('sectionRender', { 
      list: context.getters.getDirectCostsList, 
      title: 'Себестоимость',
      activeYear: payload.activeYear,
    });

    // Получаем данные по Коммерческим расходам в форматированном виде
    const commercialExpnsTableData = await context.dispatch('sectionRender', { 
      list: context.getters.getCommercialExpnsList, 
      title: 'Коммерческие расходы',
      activeYear: payload.activeYear,
    });

    // Получаем данные по Общехозяйственным расходам в форматированном виде
    var commonExpnsTableData = await context.dispatch('sectionRender', { 
      list: context.getters.getCommonExpnsList, 
      title: 'Общехозяйственные расходы',
      activeYear: payload.activeYear, 
    });

    // Получаем данные по Амортизации в форматированном виде
    const depreciationTableData = await context.dispatch('sectionRender', { 
      list: context.getters.getAssetsList, 
      title: 'Аммортизация',
      activeYear: payload.activeYear, 
    });

    // Расчитываем Маржинальную прибыль
    const marginProfit = this._vm.$func.colSum([incomesTableData.total, directCostTableData.total])

    // Расчитываем прибыль от продаж (включает амортизацию, которая отражена в Общехозяйственных расходах)
    // ! Без учета расходов на выплату % по кредитам и займам!!!
    const salesProfit = this._vm.$func.colSum([marginProfit, commercialExpnsTableData.total, commonExpnsTableData.total, depreciationTableData.total])

    // Расчитываем налог на прибыль
    const incomeTax = this._vm.$func.taxShare(salesProfit, -0.15)

    return incomeTax
  },

  async tablePLPdfData(context) {
    const activeBPlan = context.getters.getActiveBPlan;
    // Инициируем таблицу с готовым заголовком
    var table = [[
      { text: 'Наименование', style: 'tableHeader'}, 
      { text: [`${activeBPlan.startYear}`, {text: ' год', fontSize: 8}], style: 'tableHeader' }, 
      { text: [`${activeBPlan.startYear + 1}`, {text: ' год', fontSize: 8}], style: 'tableHeader' },
      { text: [`${activeBPlan.startYear + 2}`, {text: ' год', fontSize: 8}], style: 'tableHeader' },
    ]];

    // Собирает индексы строк в зависимости от типа
    var tableRowTypeIndexes = {
      segmentTotal: [],
      categoryItem: [],
      categoryTotal: [],
      categoryTotalResult: []
    }

    // Получаем данные таблицы
    const rawTableData = await context.dispatch('calculateTablePL', { activeYear: -1 })

    // Задаем стили для каждого типа отображаемых строк
    const styleCategoryItemTitle = { // стиль для отрбражения названия обычных строк
      fontSize: 9,
      margin: [5, 0, 0, 0],
    }

    const styleCategoryItemValue = { // стиль для отрбражения значений обычных строк
      fontSize: 9,
      alignment: 'right',
    }

    const styleCategoryTotalTitle = { // стиль для отрбражения названия строк с итогами категории
      fontSize: 9,
      bold: true,
      fillColor: '#f9fafd'
    }

    const styleCategoryTotalValue = { // стиль для отображения итоговых значений категории
      fontSize: 9,
      bold: true,
      alignment: 'right',
      fillColor: '#f9fafd'
    }

    const styleCategoryTotalResultTitle = { // стиль для отображения строк с названиями разделов таблицы
      fontSize: 10,
      bold: true,
      fillColor: '#edeff3'
    }

    const styleCategoryTotalResultValue = { // стиль для отображения значений разделов таблицы
      fontSize: 10,
      bold: true,
      alignment: 'right',
      fillColor: '#edeff3'
    }

    const styleSubCategoryTotalResultTitle = { // стиль для отображения строк с названиями подразделов таблицы
      fontSize: 9,
      alignment: 'right',
      margin: [0, 0, 20, 0]
    }

    const styleSubCategoryTotalResultValue = { // стиль для отображения значений подразделов таблицы
      fontSize: 9,
      alignment: 'right'
    }

    const styleCategoryTotalResultFooterTitle = { // стиль для отображения названия итогов таблицы
      fontSize: 11,
      bold: true,
      fillColor: '#d8e2ef'
    };

    const styleCategoryTotalResultFooterValue = {  // стиль для отображения значений итогов таблицы
      fontSize: 11,
      bold: true,
      alignment: 'right',
      fillColor: '#d8e2ef'
    };

    const styleSubCategoryTotalResultFooterTitle = { // стиль для отображения названия подитогов таблицы
      fontSize: 9,
      bold: true,
      alignment: 'right',
      margin: [0, 0, 20, 0],
      fillColor: '#d8e2ef'

    };

    const styleSubCategoryTotalResultFooterValue = {  // стиль для отображения значений подитогов таблицы
      fontSize: 9,
      bold: true,
      alignment: 'right',
      fillColor: '#d8e2ef'
    };

    // Определяем временные переменные для хранения стилей
    var styleTitle, styleValue;

    // Переформатируем данные под формат для печати PDF
    rawTableData.tableData.map((item, index) => {
      // Переключаем временные стили в соответвии с типом текущей строки
      // и сохраняем индексы для последующего форматирования
      switch (item.type) {
        case 'categoryItem':
          styleTitle = styleCategoryItemTitle;
          styleValue = styleCategoryItemValue;
          tableRowTypeIndexes.categoryItem.push(index);
          break;
        case 'categoryTotal':
          styleTitle = styleCategoryTotalTitle;
          styleValue = styleCategoryTotalValue;
          tableRowTypeIndexes.categoryTotal.push(index);
          break;
        case 'categoryTotalResult':
          if (index < rawTableData.length - 2) {
            styleTitle = styleCategoryTotalResultTitle;
            styleValue = styleCategoryTotalResultValue;
            tableRowTypeIndexes.categoryTotalResult.push(index);
          } else {
            styleTitle = styleCategoryTotalResultFooterTitle;
            styleValue = styleCategoryTotalResultFooterValue;
          }
          break;
          case 'subCategoryTotalResult':
            if (index < rawTableData.length - 2) {
              styleTitle = styleSubCategoryTotalResultTitle;
              styleValue = styleSubCategoryTotalResultValue;
              tableRowTypeIndexes.categoryTotalResult.push(index);
            } else {
              styleTitle = styleSubCategoryTotalResultFooterTitle;
              styleValue = styleSubCategoryTotalResultFooterValue;
            }
            break;
      
        default:
          break;
      }

      // Добавляем строки в таблицы и проводим их форматирование
      table.push([
        { 
          text: item.title, 
          style: styleTitle,
        }, { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: item.type != 'subCategoryTotalResult' ? numeral(item.values[0]).format('0,0') : [numeral(item.values[0]).format('0,0.0'), {text: '%', fontSize: 8}], 
          style: styleValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[0] < 0 && (item.type == 'categoryTotalResult' || item.type == 'subCategoryTotalResult') ? 'red' : 'black',
        }, 
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text:item.type != 'subCategoryTotalResult' ? numeral(item.values[1]).format('0,0') : [numeral(item.values[1]).format('0,0.0'), {text: '%', fontSize: 8}], 
          style: styleValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[1] < 0 && (item.type == 'categoryTotalResult' || item.type == 'subCategoryTotalResult') ? 'red' : 'black',
        },
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: item.type != 'subCategoryTotalResult' ? numeral(item.values[2]).format('0,0') : [numeral(item.values[2]).format('0,0.0'), {text: '%', fontSize: 8}], 
          style: styleValue,
          color: item.values[2] < 0 && (item.type == 'categoryTotalResult' || item.type == 'subCategoryTotalResult') ? 'red' : 'black',
        },
      ]);
    })

    return ({ tableData: table, widths: ['*', 70, 70, 70] })
  },

}

// Експортируем информацию в основной модуль VUEX
export default {
  state, getters, mutations, actions
}