import numeral from 'numeral';

const state = {
  tableCF: []
}

const getters = {
  getTableCF: state => {
    return state.tableCF;
  }
}

const mutations = {
  setTableCF (state, payload) {
    state.tableCF = payload
  }
}

const actions = {
  // Формирование таблицы ДДС
  async calculateTableCF(context, payload) {

    var tableData = []

    const mainActivitySection = await context.dispatch('mainActivitySectionRender', payload);
    const investmentActivitySection = await context.dispatch('investmentActivitySectionRender', payload);
    const financialActivitySection = await context.dispatch('financialActivitySectionRender', payload);

    tableData = tableData.concat(mainActivitySection.rows)
    .concat(investmentActivitySection.rows)
    .concat(financialActivitySection.rows);

    //* ИТОГО ПО ТАБЛИЦЫ
    
    const balanceChange = this._vm.$func.colSum([ 
      mainActivitySection.rows[0].values, 
      investmentActivitySection.rows[0].values, 
      financialActivitySection.rows[0].values 
    ]);

    // Расчитываем показания баланса
    var balance = null;
    if (payload.activeYear > -1) {
      balance = this._vm.$func.cfBalance(7, 0, balanceChange, payload.activeYear);
    } else {
      balance = this._vm.$func.cfBalance(3, 0, balanceChange, 1);
    }

    var cashBalanceData = []
      
    cashBalanceData.push({
      type: 'subCategoryTotalResult',
      title: 'На начало периода',
      values: balance.start
    }, {
      type: 'subCategoryTotalResult',
      title: 'Изменение за период',
      values: balanceChange
    }, {
      type: 'subCategoryTotalResult',
      title: 'На конец периода',
      values: balance.end
    }) 

    context.commit('setTableCF', { data: tableData, cashBalance: cashBalanceData })

    return { data: tableData, cashBalance: cashBalanceData }
  },

  // Отвечает за формирование раздела "ТЕКУЩАЯ ДЕЯТЕЛЬНОСТЬ"
  async mainActivitySectionRender(context, payload) {
    
    var sectionRows = []

    // Получаем данные по выручке для секции 1.1
    var incomesTableData = await context.dispatch('incomeSectionData', {
      title: 'Средства полученные',
      activeYear: payload.activeYear 
    });
    
    // Добавляем секцию 1.1 в таблицу
    sectionRows = sectionRows.concat(incomesTableData.rows)

    // Получаем данные оплат Прямых расходов для раздела 1.2
    const filteredDirectCost = context.getters.getDirectCostsList
    .filter(function(item) {
      if (item.cfData) {
        return  item.cfData.section == '1.2'
      }
    });
    
    const directCosts = await context.dispatch('sectionRender', {
      list: filteredDirectCost,
      title: 'Платежи по производственным расходам',
      activeYear: payload.activeYear 
    });

    // Добавляем секцию 1.2 в таблицу
    sectionRows = sectionRows.concat(directCosts.rows);


    // * СЕКЦИЯ 1.3
    // Получаем данные оплат "ФОТ без НДФЛ", расходы для раздела 1.3
    // ФОТ объединяем из 3-х разделов
    var spendingData = []
    spendingData = spendingData.concat(context.getters.getDirectCostsList)
    .concat(context.getters.getCommercialExpnsList)
    .concat(context.getters.getCommonExpnsList)
    // Выделяем расходы на ФОТ
    const laborSpendings = spendingData.filter(function(item) {
      if (item.cfData) {
        return  item.cfData.section == '1.3'
      }
    });
    // Получаем форматированные данные
    const laborTableData = await context.dispatch('sectionRender', { 
      list: laborSpendings, 
      title: 'На оплату труда (без НДФЛ)',
      activeYear: payload.activeYear,
    });

    const laborPaymentsWithoutTax = this._vm.$func.itemsShare(laborTableData.rows[0].values, 0.87)
    
    // Добавляем раздел 1.3 в тбалицу (только суммирующую статью и очищенную от НДФЛ)
    sectionRows.push({
      type: 'categoryTotal',
      title: 'На оплату труда (без НДФЛ)',
      values: laborPaymentsWithoutTax,
    });

    //* СЕКЦИЯ 1.4
    // Получаем данные по расходам для секцию 1.4
    // в этот раздел входят позиции из Коммерчеких и Общехозяйственных расходов
    var constantSpendings  = []
    constantSpendings = context.getters.getCommercialExpnsList
    .concat(context.getters.getCommonExpnsList)
    .filter(function(item) {
      if (item.cfData) {
        return  item.cfData.section == '1.4'
      }
    });  
    // Форматируем данные для таблици
    const constantSpendingsData = await context.dispatch('sectionRender', { 
      list: constantSpendings, 
      title: 'Платежи по расходам постоянного характера',
      activeYear: payload.activeYear,
    });
    
    // Добавляем секцию 1.4 в таблицу
    sectionRows = sectionRows.concat(constantSpendingsData.rows)


    //* СЕКЦИЯ 1.5 
    // Получаем данные по расходом на оплату кредитов для раздела 1.5
    // в этот раздел входят позиции из Коммерчеких и Общехозяйственных расходов
    const debtInterestPayments = context.getters.getFinancingList.map(item => {
      return {
        title: item.title,
        values: item.interestPayments
      }
    });
    // Форматируем данные для таблици
    const debtInterestPaymentsData = await context.dispatch('sectionRender', { 
      list: debtInterestPayments, 
      title: 'Платежи на выплату % по кредитам и займам',
      activeYear: payload.activeYear,
    });

    // Добавляем секцию 1.5 в таблицу (только итоговые данные)
    sectionRows = sectionRows.concat(debtInterestPaymentsData.rows[0])
   
    


    //* СЕКЦИЯ 1.6 
    // Расчитываем налоговые платежи
    // Обязательные страховые взносы
    const laborTaxData = await context.dispatch('sectionRender', { 
      // Выбор по разделу 1.7, т.к. планировалось вынести эти данных в отдельный раздел
      list: spendingData.filter(function(item) {
        if (item.cfData) {
          return  item.cfData.section == '1.7'
        }
      }), 
      title: 'Обязательные страховые взносы на ФОТ',
      activeYear: payload.activeYear, 
    }); 
    // НДФЛ
    const ndfl = this._vm.$func.itemsShare(laborTableData.rows[0].values, 0.13)
    
    // Запрашиваем расчет налога на прибыль из таблицы tablePL
    const tablePL = await context.dispatch('calculateTablePL', payload)

    // Расчитываем общие расходы на налоги 
    const taxTotal = this._vm.$func.colSum([
      laborTaxData.rows[0].values, // Страховые взносы итого
      tablePL.incomeTax, // Налог на прибыль
      ndfl, // НДФЛ
    ]);

    // Добавляем раздел с налоговыми платежами для секции 1.6
    sectionRows.push(
      {
        type: 'categoryTotal',
        title: 'Платежи по налогам и сборам',
        values: taxTotal
      }, {
        type: 'categoryItem',
        title: 'Обязательные страховые взнозы на ФОТ',
        values: laborTaxData.rows[0].values
      }, {
        type: 'categoryItem',
        title: 'Налог на прибыль',
        values: tablePL.incomeTax
      }, {
        type: 'categoryItem',
        title: 'НДС',
        values: payload.activeYear > -1 ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0]
      }, {
        type: 'categoryItem',
        title: 'НДФЛ',
        values: ndfl
      }, {
        type: 'categoryItem',
        title: 'Налог на имущество',
        values: payload.activeYear > -1 ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0]
      })
    
    //* ИТОГОВЫЙ РЕЗУЛЬТАТ ПО РАЗДЕЛУ
    // Расчитываем итоговое значение секции
    const segmentTotal = this._vm.$func.colSum([
      incomesTableData.rows[0].values, // Средства полученные, секция 1.1
      directCosts.rows[0].values, // Производственные расходы, секция 1.2
      laborPaymentsWithoutTax, // Оплата труда без НДФЛ, секция 1.3
      constantSpendingsData.rows[0].values, //Постоянные расходы, секция 1.4
      debtInterestPaymentsData.rows.length > 1 ? debtInterestPaymentsData.rows[0].values : (payload.activeYear > -1 ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0]), // Платежи на выплату %, секция 1.5
      taxTotal, // Налоговые выплаты, секция 1.6
    ]);

    sectionRows.unshift(
    {
      type: 'segmentTotal',
      title: 'Текущая деятельность',
      values: segmentTotal
    });

          
    // return {rows, total: segmentTotal}
    return { rows: sectionRows }
  },

  // Отвечает за формирование раздела "ИНВЕСТИЦИОННАЯ ДЕЯТЕЛЬНОСТЬ"
  async investmentActivitySectionRender(context, payload) {
    // Переменная для формтированных данных
    var sectionRows = []

    //* РАЗДЕЛ "ПОСТУПЛЕНИЯ ПО ИНВЕСТИЦИОННОЙ ДЕЯТЕЛЬНОСТИ" 
    // В данной версии поступления не предполагаются, поэтому возвращаем нули
    const investmentIncomesData = await context.dispatch('sectionRender', { 
      list: [{
        values: {
          2020: [null, null, null, null, null, null, null, null, null, null, null, null],
          2021: [null, null, null, null, null, null, null, null, null, null, null, null],
          2022: [null, null, null, null, null, null, null, null, null, null, null, null],
        }
      }], 
      title: 'Посупления по инвестиционной деятельности',
      activeYear: payload.activeYear, 
    });
    // Добавляем раздел в общую таблицу
    sectionRows = sectionRows.concat(investmentIncomesData.rows[0])


    //* РАЗДЕЛ "ПЛАТЕЖИ ПО ИНВЕСТИЦИОННОЙ ДЕЯТЕЛЬНОСТИ" 
    // В данный раздел включаются расходы на приобретение Основных средств
    const investmentPaymentsData = await context.dispatch('sectionRender', {
      list: context.getters.getAssetsList,
      title: 'Платежи по инвестиционной деятельности',
      activeYear: payload.activeYear,
    });
    // Добавляем раздел в общую таблицу
    sectionRows = sectionRows.concat(investmentPaymentsData.rows);

    const segmentTotal = this._vm.$func.colSum([
      investmentIncomesData.rows[0].values, // Поступления по ивестиционной деятельности
      investmentPaymentsData.rows[0].values, // Расходы по инвестиционной деятельности
    ]);

    sectionRows.unshift({
      type: 'segmentTotal',
      title: 'Инвестиционная деятельность',
      values: segmentTotal
    });

  //     payload = payload.concat(incomes.rows)
  //     payload = payload.concat(spendings.rows)


    return { rows: sectionRows }
  },

  // Отвечат за формирования раздела "ФИНАНСОВАЯ ДЕЯТЕЛЬНОСТЬ"
  async financialActivitySectionRender(context, payload) {
    // Переменная для формтированных данных
    var sectionRows = []

    //* ПОСТУПЛЕНИЯ ПО ФИНАНСОВОЙ ДЕЯТЕЛЬНОСТИ
    // Тут отражается информация от поступлений по кредитам и займам
    // Фильтруем данные по кредитам и займам в части поступлений
    const debtIncome = context.getters.getFinancingList.map(item => {
      return {
        title: item.title,
        values: item.debtIncome
      }
    });
 
    // Форматируем раздел для отображения
    const financialIncomesData = await context.dispatch('sectionRender', {
      list: debtIncome,
      title: 'Поступления по кредитам и займам',
      activeYear: payload.activeYear,
      notNegative: true
    });
    
    
    // Добавляем раздел в общую таблицу
    sectionRows.push({
      type: 'categoryTotal',
      title: 'Поступления  по финансовой деятельности',
      values: financialIncomesData.rows.length > 1 ?
        financialIncomesData.rows[0].values : (payload.activeYear > -1 ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0])
    });

    if (financialIncomesData.rows.length > 1) {
      sectionRows.push({
        type: 'categoryItem',
        title: 'Погашение кредитов (основной долг, без процентов)',
        values: financialIncomesData.total
      });
    }


    //* ПЛАТЕЖИ ПО ФИНАНСОВОЙ ДЕЯТЕЛЬНОСТИ
    
    // Фильтруем платежи по возврату основного долга
    const principalPayments = context.getters.getFinancingList.map(item => {
      return {
        title: item.title,
        values: item.principalPayments
      }
    }); 
    // Получаем данные для добавления в таблицу
    const principalPaymentsData = await context.dispatch('sectionRender', {
      list: principalPayments,
      title: 'Погашение кредитов (основной долг, без процентов)',
      activeYear: payload.activeYear,
    });


    const financialPaymentsData = this._vm.$func.colSum([
      principalPaymentsData.rows[0].values
    ]);

    //! В данной версии отображается иформаци только по кредитам. Внимательно, при дальнейшем изменении!
    sectionRows.push({
      type: 'categoryTotal',
      title: 'Платежи по финансовой деятельности',
      values: financialPaymentsData.length != 0 ?
        financialPaymentsData : (payload.activeYear > -1 ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0])
    });

    if (financialPaymentsData.length != 0) {
      sectionRows.push({
        type: 'categoryItem',
        title: 'Погашение кредитов (основной долг, без процентов)',
        values: principalPaymentsData.total
      });
    }
    

    //* ИТОГОВЫЙ РЕЗУЛЬТАТ ПО РАЗДЕЛУ
    // Расчитыавем итоговый результат по секции
    const segmentTotal = this._vm.$func.colSum([
      financialIncomesData.rows[0].values, // Поступления по финансовой деятельности
      financialPaymentsData.length != 0 ? financialPaymentsData : [0, 0, 0, 0, 0, 0, 0], // Расходы по финансовой деятельности
    ]);

    sectionRows.unshift({
      type: 'segmentTotal',
      title: 'Финансовая деятельность',
      values: segmentTotal
    });

  
    return { rows: sectionRows }
  },

  async tableCFPdfData(context) {
    const activeBPlan = context.getters.getActiveBPlan;
    // Инициируем таблицу с готовым заголовком
    var table = [[
      { text: 'Наименование', style: 'tableHeader'}, 
      { text: [`${activeBPlan.startYear}`, {text: ' год', fontSize: 8}], style: 'tableHeader' }, 
      { text: [`${activeBPlan.startYear + 1}`, {text: ' год', fontSize: 8}], style: 'tableHeader' },
      { text: [`${activeBPlan.startYear + 2}`, {text: ' год', fontSize: 8}], style: 'tableHeader' },
    ]];

    // Получаем данные таблицы
    const rawTableData = await context.dispatch('calculateTableCF', { activeYear: -1 })

    // Задаем стили для каждого типа отображаемых строк
    const styleSegmentTotalTitle = { // стиль для отображения строк с названиями разделов таблицы
      fontSize: 11,
      bold: true,
      fillColor: '#edeff3',
    }

    const styleSegmentTotaltValue = { // стиль для отображения значений разделов таблицы
      fontSize: 11,
      bold: true,
      alignment: 'right',
      fillColor: '#edeff3'

    }

    const styleCategoryTotalTitle = { // стиль для отрбражения названия строк с итогами категории
      fontSize: 9,
      bold: true,
      fillColor: '#f9fafd'
    }

    const styleCategoryTotalValue = { // стиль для отображения итоговых значений категории
      fontSize: 9,
      bold: true,
      alignment: 'right',
      fillColor: '#f9fafd'
    }
    
    
    const styleCategoryItemTitle = { // стиль для отрбражения названия обычных строк
      fontSize: 9,
      // margin: [5, 0, 0, 0],
    }

    const styleCategoryItemValue = { // стиль для отрбражения значений обычных строк
      fontSize: 9,
      alignment: 'right',
    }

    
    // const styleCategoryTotalResultFooterTitle = { // стиль для отображения названия итогов таблицы
    //   fontSize: 11,
    //   bold: true,
    //   fillColor: '#d8e2ef'
    // };

    const styleCategoryTotalResultFooterValue = {  // стиль для отображения значений итогов таблицы
      fontSize: 11,
      bold: true,
      alignment: 'right',
      fillColor: '#d8e2ef'
    };

    const styleSubCategoryTotalResultFooterTitle = {  // стиль для отображения значений подитогов таблицы
      fontSize: 9,
      bold: true,
      alignment: 'right',
      fillColor: '#d8e2ef',
    };

    const styleSubCategoryTotalResultFooterValue = {  // стиль для отображения значений подитогов таблицы
      fontSize: 9,
      alignment: 'right',
      fillColor: '#d8e2ef'
    };

    // Определяем временные переменные для хранения стилей
    var styleTitle, styleValue;

    // Переформатируем основные данные под формат для печати PDF
    rawTableData.data.map(item => {
      // Переключаем временные стили в соответвии с типом текущей строки
      // и сохраняем индексы для последующего форматирования
      switch (item.type) {
        case 'segmentTotal':
          styleTitle = styleSegmentTotalTitle;
          styleValue = styleSegmentTotaltValue;
          break;
        case 'categoryItem':
          styleTitle = styleCategoryItemTitle;
          styleValue = styleCategoryItemValue;
          break;
        case 'categoryTotal':
          styleTitle = styleCategoryTotalTitle;
          styleValue = styleCategoryTotalValue;
          break;
        default:
          break;
      }

      // Добавляем строки в таблицы и проводим их форматирование
      table.push([
        { 
          text: item.title, 
          style: styleTitle,
        }, { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[0]).format('0,0'), 
          style: styleValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[0] < 0 && item.type == 'segmentTotal' ? 'red' : 'black',
        }, 
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[1]).format('0,0'), 
          style: styleValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[1] < 0 && item.type == 'segmentTotal' ? 'red' : 'black',
        },
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[2]).format('0,0'), 
          style: styleValue,
          color: item.values[2] < 0 && item.type == 'segmentTotal' ? 'red' : 'black',
        },
      ]);
    });

    // Форматируем тоговы значения таблицы
    // Добавляем название раздела
    // table.push([
    //   { 
    //     text: 'Баланс денежных средств', 
    //     style: styleCategoryTotalResultFooterTitle,
    //   }
    // ]);

    //  Добавляем данные баланса денежных средств
    rawTableData.cashBalance.map((item, index) => {
      table.push([
        { 
          text: item.title, 
          style: styleSubCategoryTotalResultFooterTitle,
        }, { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[0]).format('0,0'), 
          style: index != 1 ? styleCategoryTotalResultFooterValue : styleSubCategoryTotalResultFooterValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[0] < 0 ? 'red' : 'black',
        }, 
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[1]).format('0,0'), 
          style: index != 1 ? styleCategoryTotalResultFooterValue : styleSubCategoryTotalResultFooterValue,
          // Добавляем красный цвет если итоговые значения разделов или таблицы в отрицательной зоне
          color: item.values[1] < 0 ? 'red' : 'black',
        },
        { 
          // Форматируем полученное значение в соответсвии с типом отображаемых данных
          text: numeral(item.values[2]).format('0,0'), 
          style: index != 1 ? styleCategoryTotalResultFooterValue : styleSubCategoryTotalResultFooterValue,
          color: item.values[2] < 0 ? 'red' : 'black',
        },
      ]);
    });
    

    return ({ tableData: table, widths: ['*', 70, 70, 70] })
  },
}

// Експортируем информацию в основной модуль VUEX
export default {
    state, getters, mutations, actions
  }