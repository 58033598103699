import axios from 'axios';
import numeral from 'numeral';

const state = {
  financialHighlightsByYear : []
}

const getters = {
  
}

const mutations = {
  
}

const actions = {
  async financialHighlightsByYearData (context, payload = {width: 250, height: 100}) {
    
    // обновляем данные, если они не были еще загружены
    await context.dispatch('fetchAllFinancialData')
    
    // Получаем данные по текущему бизнес-плану
    const activeBPLan = context.getters.getActiveBPlan;
    // Получаем данные с выручкой по годам
    const revenueData = await context.dispatch('revenueData')
    
    // Получаем данные с раходами по годам
    const directCostsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getDirectCostsList}
    );
    
    const commercialExpnsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getCommercialExpnsList}
    );

    const commonExpnsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getCommonExpnsList}
    );

    const spendingsData = this._vm.$func.colSum([
      directCostsData,
      commercialExpnsData,
      commonExpnsData
    ]);

    /* Для расчета чистой прибыли добаляем отрицальтельный 
      знак в значения, т.к в базе хранятся позитивные данные */
    const negativeSpendingData = spendingsData.map(value => value * -1)

    const netProfit = this._vm.$func.colSum([
      revenueData,
      negativeSpendingData,
    ])
    
    var  options = {
      chart: {
        type: 'column',
        width: payload.width,
        height: payload.height,
        backgroundColor: null
      },
      credits: false,
      title: {
        text: null
      },
      legend: false,
      plotOptions: {
        column: {
          pointPadding: 0.1,
          colors: ['#9da9bb','#9da9bb','#9da9bb'],
          borderColor: false,
          borderWidth: 0
        },
      },
      yAxis: {
        softMin: 0,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 0.2,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#344050',
        plotLines: [{
          value: 0,
          width: 2,
          color: '#808080'
        }],
        labels: {
          align: 'left',
          enabled: true,
          x: 0,
          y: 8,
          style: {
            color: 'lightgray',
            fontSize: 8
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        categories: [
          `${activeBPLan.startYear} год`,
          `${activeBPLan.startYear + 1} год`,
          `${activeBPLan.startYear + 2} год`,
        ],
        labels: {
          style: {
            color: 'lightgray',
            fontSize: 11
          },
        }
      },
      series: [{
        data: revenueData,
        name: 'Выручка',
        color: '#4C9BEE'
      }, {
        data: spendingsData,
        name: 'Расходы',
        color: '#f6a09a'
      }, {
        data: netProfit,
        name: 'Прибыль',
        color: '#2ecc71',
        negativeColor: '#FF0000'
      }]
    }

    return options
  },

  async financialHighlightsByYearSvg (context, payload) {
    
    var options = await context.dispatch('financialHighlightsByYearData')

    // Устанавливаем пользовательские параметры ширины и высоты
    options.chart.width = payload.width;
    options.chart.height = payload.height;
    options.yAxis.labels.style.color = 'gray';
    options.xAxis.labels.style.color = 'gray';
    options.xAxis.labels.style.fontSize = 8;
    options.yAxis.gridLineColor = 'gray';
    options.yAxis.minorGridLineColor = 'lightgray';


    // Опции для запроса
    const req = {
      type: 'svg',
      options
    }

    // Отправляем для рендеринга на сервер
    const res = await axios.post('https://export.highcharts.com/', req)
    window.console.log(res)
    // Возвращаем SVG для отображения
    return res
  },

  async revenueChartAllTimeData (context, payload = {width: 250, height: 100}) {
    await context.dispatch('fetchAllFinancialData');    

    // Получаем данные с выручкой помесячно за весь период
    const revenueData = await context.dispatch('revenueData', {monthly: true});
    
    var options = {
      chart: {
        type: 'spline',
        // marginLeft: 1,
        // marginRight: 1,
        width: payload.width,
        height: payload.height,
        backgroundColor: null,
        events: {
          click: () => {
            if (!this.readonly) {
              this.pointClick(
                {
                  index: Math.round(event.xAxis[0].value),
                  value: Math.ceil(event.yAxis[0].value)
                }
              )
            }
          },
        }
      },
      credits: false,
      title: {
        text: null
      },
      legend: false,
      tooltip: {
        crosshairs: true,
        shared: true,
        formatter: function () {
          const index = this.points[0].point.x
          const prevPointData = this.points[0].series.yData[index - 1]
          const pointData = this.points[0].series.yData[index]
          var change = prevPointData ? (pointData / prevPointData - 1) : 0

          return '<b>' + numeral(this.y).format('(0.0a)') + '</b><br>' + 'изм.: <b>' + numeral(change).format('0%') + '</b>'
        }
      },
      yAxis: {
        min: 0,
        max: (this.yearSum == 0) ? this.defaultMax : null,
        showEmpty: true,
        tickPixelInterval: 50,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#3440502a',
        labels: {
          align: 'left',
          enabled: true,
          x: 0,
          y: 15,
          style: {
            color: '#919FB2',
          },
        },
        title: {
          text: null,
        },
        plotLines: [{
          color: '#3a3939e4',
          width: 1,
          value: 0,
          zIndex: 1
        }]
      },
      xAxis: {
        categories: [
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
        ],
        labels: {
          step: 2,
          style: {
              color: '#9da9bb',
            }
        },
        plotLines: [{
          color: '#3440502a', // Color value
          value: 12, // Value of where the line will appear
          width: 1 // Width of the line    
        }, {
          color: '#3440502a', // Color value
          value: 24, // Value of where the line will appear
          width: 1 // Width of the line    
        }]
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: true
        },
        series: {
          stickyTracking: false,
          dragDrop: {
              draggableY: !this.readonly
          },
          dataLabels: {
            enabled: false,
          },
          marker: {
            fillColor: '#0e1c2f',
            lineWidth: 5,
            radius: 0,
            lineColor: null,
            symbol: 'circle', // inherit from series
          }
        }
      },
      series: [{
        data: revenueData,
        lineWidth: 4,
         point: {
            events: {
              drag: (event) => {
                if (!this.readonly) {
                  this.pointClick(
                    {
                      index: event.target.index,
                      value: Math.round(event.newPoint.y)
                    }
                  )
                }
              }
            }
          },
        color: '#4C9BEE',
        fillColor: '#adcdf7b3',
      }]
    }

    return options;
  },

  async revenueChartByYearData (context, payload = {width: 250, height: 100}) {
    
    // обновляем данные, если они не были еще загружены
    await context.dispatch('fetchAllFinancialData')
    
    // Получаем данные по текущему бизнес-плану
    const activeBPLan = context.getters.getActiveBPlan;
    // Получаем данные с выручкой по годам
    const revenueData = await context.dispatch('revenueData')
    
    var  options = {
      chart: {
        type: 'column',
        width: null,
        height: payload.height,
        backgroundColor: null
      },
      credits: false,
      title: {
        text: 'Выручка',
        margin: 5,
        style: {
          'color': '#D8E2EF',
          'font-family': `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
          'font-weight': '100'
        }
      },
      legend: false,
      plotOptions: {
        column: {
          pointPadding: 0.1,
          colors: ['#9da9bb','#9da9bb','#9da9bb'],
          borderColor: false,
          borderWidth: 0
        },
      },
      yAxis: {
        softMin: 0,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 0.2,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#344050',
        plotLines: [{
          value: 0,
          width: 2,
          color: '#808080'
        }],
        labels: {
          align: 'left',
          enabled: true,
          x: 0,
          y: 8,
          style: {
            color: 'lightgray',
            fontSize: 8
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        categories: [
          `${activeBPLan.startYear} год`,
          `${activeBPLan.startYear + 1} год`,
          `${activeBPLan.startYear + 2} год`,
        ],
        labels: {
          style: {
            color: 'lightgray',
            fontSize: 11
          },
        }
      },
      series: [{
        data: revenueData,
        name: 'Выручка',
        color: '#4C9BEE'
      }]
    }

    return options
  },

  async revenueChartAllTimeSvg (context, payload) {
    
    var options = await context.dispatch('revenueChartAllTimeData')

    // Устанавливаем пользовательские параметры ширины и высоты
    options.chart.width = payload.width;
    options.chart.height = payload.height;
    options.yAxis.labels.style.color = 'gray';
    options.xAxis.labels.style.color = 'gray';
    options.xAxis.labels.style.fontSize = 8;
    options.xAxis.plotLines[0].color = 'lightgray';
    options.xAxis.plotLines[1].color = 'lightgray';
    options.yAxis.gridLineColor = 'gray';
    options.yAxis.minorGridLineColor = 'lightgray';


    // Опции для запроса
    const req = {
      type: 'svg',
      options
    }

    // Отправляем для рендеринга на сервер
    const res = await axios.post('https://export.highcharts.com/', req)
    window.console.log(res)
    // Возвращаем SVG для отображения
    return res
  },

  async expnsChartAllTimeData (context, payload = {width: 250, height: 100}) {
    await context.dispatch('fetchAllFinancialData');

    // Получаем данные с раходами помесячно за весь период
    const directCostsData = await context.dispatch(
      'spendingData', 
      {
        list: context.getters.getDirectCostsList,
        monthly: true
      }
    )
    const commercialExpnsData = await context.dispatch(
      'spendingData', 
      {
        list: context.getters.getCommercialExpnsList,
        monthly: true
      }
    )
    const commonExpnsData = await context.dispatch(
      'spendingData', 
      {
        list: context.getters.getCommonExpnsList,
        monthly: true
      }
    )

    const spendingsData = this._vm.$func.colSum([
      directCostsData,
      commercialExpnsData,
      commonExpnsData
    ])
    
    var options = {
      chart: {
        type: 'spline',
        // marginLeft: 1,
        // marginRight: 1,
        width: payload.width,
        height: payload.height,
        backgroundColor: null,
        events: {
          click: () => {
            if (!this.readonly) {
              this.pointClick(
                {
                  index: Math.round(event.xAxis[0].value),
                  value: Math.ceil(event.yAxis[0].value)
                }
              )
            }
          },
        }
      },
      credits: false,
      title: {
        text: null
      },
      legend: false,
      tooltip: {
        crosshairs: true,
        shared: true,
        formatter: function () {
          const index = this.points[0].point.x
          const prevPointData = this.points[0].series.yData[index - 1]
          const pointData = this.points[0].series.yData[index]
          var change = prevPointData ? (pointData / prevPointData - 1) : 0

          return '<b>' + numeral(this.y).format('(0.0a)') + '</b><br>' + 'изм.: <b>' + numeral(change).format('0%') + '</b>'
        }
      },
      yAxis: {
        min: 0,
        max: (this.yearSum == 0) ? this.defaultMax : null,
        showEmpty: true,
        tickPixelInterval: 50,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#3440502a',
        labels: {
          align: 'left',
          enabled: true,
          x: 0,
          y: 15,
          style: {
            color: '#919FB2',
          },
        },
        title: {
          text: null,
        },
        plotLines: [{
          color: '#3a3939e4',
          width: 1,
          value: 0,
          zIndex: 1
        }]
      },
      xAxis: {
        categories: [
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
        ],
        labels: {
          step: 2,
          style: {
              color: '#9da9bb',
            }
        },
        plotLines: [{
          color: '#3440502a', // Color value
          value: 12, // Value of where the line will appear
          width: 1 // Width of the line    
        }, {
          color: '#3440502a', // Color value
          value: 24, // Value of where the line will appear
          width: 1 // Width of the line    
        }]
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: true
        },
        series: {
          stickyTracking: false,
          dragDrop: {
              draggableY: !this.readonly
          },
          dataLabels: {
            enabled: false,
          },
          marker: {
            fillColor: '#0e1c2f',
            lineWidth: 5,
            radius: 0,
            lineColor: null,
            symbol: 'circle', // inherit from series
          }
        }
      },
      series: [{
        data: spendingsData,
        lineWidth: 3,
         point: {
            events: {
              drag: (event) => {
                if (!this.readonly) {
                  this.pointClick(
                    {
                      index: event.target.index,
                      value: Math.round(event.newPoint.y)
                    }
                  )
                }
              }
            }
          },
        color: '#f6a09a',
        fillColor: '#adcdf7b3',
      }]
    }

    console.log(options)

    return options;
  },

  async expnsChartByYearData (context, payload = {width: 250, height: 100}) {
    
    // обновляем данные, если они не были еще загружены
    await context.dispatch('fetchAllFinancialData')
    
    // Получаем данные по текущему бизнес-плану
    const activeBPLan = context.getters.getActiveBPlan;
   
    
    // Получаем данные с раходами по годам
    const directCostsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getDirectCostsList}
    );
    
    const commercialExpnsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getCommercialExpnsList}
    );

    const commonExpnsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getCommonExpnsList}
    );

    const spendingsData = this._vm.$func.colSum([
      directCostsData,
      commercialExpnsData,
      commonExpnsData
    ]);

  
    
    var  options = {
      chart: {
        type: 'column',
        width: null,
        height: payload.height,
        backgroundColor: null
      },
      credits: false,
      title: {
        text: 'Расходы',
        margin: 5,
        style: {
          'color': '#D8E2EF',
          'font-family': `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
          'font-weight': '100'
        }
      },
      legend: false,
      plotOptions: {
        column: {
          pointPadding: 0.1,
          colors: ['#9da9bb','#9da9bb','#9da9bb'],
          borderColor: false,
          borderWidth: 0
        },
      },
      yAxis: {
        softMin: 0,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 0.2,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#344050',
        plotLines: [{
          value: 0,
          width: 2,
          color: '#808080'
        }],
        labels: {
          align: 'left',
          enabled: true,
          x: 0,
          y: 8,
          style: {
            color: 'lightgray',
            fontSize: 8
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        categories: [
          `${activeBPLan.startYear} год`,
          `${activeBPLan.startYear + 1} год`,
          `${activeBPLan.startYear + 2} год`,
        ],
        labels: {
          style: {
            color: 'lightgray',
            fontSize: 11
          },
        }
      },
      series: [{
        data: spendingsData,
        name: 'Расходы',
        color: '#f6a09a'
      }]
    }

    return options
  },

  async expnsChartAllTimeSvg (context, payload) {
    var options = await context.dispatch('expnsChartAllTimeData')

    // Устанавливаем пользовательские параметры ширины и высоты
    options.chart.width = payload.width;
    options.chart.height = payload.height;
    options.yAxis.labels.style.color = 'gray';
    options.xAxis.labels.style.color = 'gray';
    options.xAxis.labels.style.fontSize = 8;
    options.xAxis.plotLines[0].color = 'lightgray';
    options.xAxis.plotLines[1].color = 'lightgray';
    options.yAxis.gridLineColor = 'gray';
    options.yAxis.minorGridLineColor = 'lightgray';


    // Опции для запроса
    const req = {
      type: 'svg',
      options
    }

    // Отправляем для рендеринга на сервер
    const res = await axios.post('https://export.highcharts.com/', req)
    window.console.log(res)
    // Возвращаем SVG для отображения
    return res
  },

  async netProfitByYearData (context, payload = {width: 250, height: 100}) {
    
    // обновляем данные, если они не были еще загружены
    await context.dispatch('fetchAllFinancialData')
    
    // Получаем данные по текущему бизнес-плану
    const activeBPLan = context.getters.getActiveBPlan;
    // Получаем данные с выручкой по годам
    const revenueData = await context.dispatch('revenueData')
    
    // Получаем данные с раходами по годам
    const directCostsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getDirectCostsList}
    );
    
    const commercialExpnsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getCommercialExpnsList}
    );

    const commonExpnsData = await context.dispatch(
      'spendingData', 
      {list: context.getters.getCommonExpnsList}
    );

    const spendingsData = this._vm.$func.colSum([
      directCostsData,
      commercialExpnsData,
      commonExpnsData
    ]);

    /* Для расчета чистой прибыли добаляем отрицальтельный 
      знак в значения, т.к в базе хранятся позитивные данные */
    const negativeSpendingData = spendingsData.map(value => value * -1)

    const netProfit = this._vm.$func.colSum([
      revenueData,
      negativeSpendingData,
    ])
    
    var  options = {
      chart: {
        type: 'column',
        width: null,
        height: payload.height,
        backgroundColor: null
      },
      credits: false,
      title: {
        text: 'Чистая прибыль',
        margin: 5,
        style: {
          'color': '#D8E2EF',
          'font-family': `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
          'font-weight': '100'
        }
      },
      legend: false,
      plotOptions: {
        column: {
          pointPadding: 0.1,
          colors: ['#9da9bb','#9da9bb','#9da9bb'],
          borderColor: false,
          borderWidth: 0
        },
      },
      yAxis: {
        softMin: 0,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 0.2,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#344050',
        plotLines: [{
          value: 0,
          width: 2,
          color: '#808080'
        }],
        labels: {
          align: 'left',
          enabled: true,
          x: 0,
          y: 8,
          style: {
            color: 'lightgray',
            fontSize: 8
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        categories: [
          `${activeBPLan.startYear} год`,
          `${activeBPLan.startYear + 1} год`,
          `${activeBPLan.startYear + 2} год`,
        ],
        labels: {
          style: {
            color: 'lightgray',
            fontSize: 11
          },
        }
      },
      series: [{
        data: netProfit,
        name: 'Прибыль',
        color: '#2ecc71',
        negativeColor: '#FF0000'
      }]
    }

    return options
  },

  revenueData(context, payload = {monthly: false}) {
    var revenue = context.getters.getRevenueList;
    var valueRows = [];
    const monthlyBlank = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];

    revenue.map(revenueStream => {        
      var qValues = []
      
      Object.values(revenueStream.values).map(values => {
        if (payload.monthly) { // если запросили отобразить помесячно
          qValues = qValues.concat(values);
        } else { // если нет, то суммируем данные по году
          qValues.push(this._vm.$func.arrSum(values));
        }
      });
      
      // Добавляем данные по каждому потоку выручки, для последующего сложения
      valueRows.push(qValues) 
    });
    
    console.log(valueRows)
    var colsSum = this._vm.$func.colSum(valueRows)
      
    return  colsSum.length > 0 ? colsSum : (payload.monthly ? monthlyBlank : [0, 0, 0])
  },

  spendingData(context, payload = {monthly: false}) {
    var spending = payload.list;
    var valueRows = [];
    const monthlyBlank = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];

    spending.map(spendingStream => {        
      var qValues = []
      
      Object.values(spendingStream.values).map(values => {
        if (payload.monthly) { // если запросили отобразить помесячно
          qValues = qValues.concat(values);
        } else { // если нет, то суммируем данные по году
          qValues.push(this._vm.$func.arrSum(values));
        }
      });
      
      // Добавляем данные по каждому потоку выручки, для последующего сложения
      valueRows.push(qValues) 
    });

    var colsSum = this._vm.$func.colSum(valueRows)

    return  colsSum.length > 0 ? colsSum : (payload.monthly ? monthlyBlank : [0, 0, 0])
  },
}

export default {
  state, getters, mutations, actions
}