<template>
  <nav class="navbar navbar-vertical navbar-expand-xl mt-8 border-top border-600">
    <div class="alert alert-light mt-4 p-0">
      <b-calendar 
        block 
        hide-header
        :start-weekday="1"
        locale="ru"
        :readonly="true">
      </b-calendar>
    </div>
    <div class="text-right">
      <div class="btn btn-outline-secondary btn-sm mt-3"
      @click="createMilestone">
        Добавить этап
      </div>
    </div>        
  </nav>

    
</template>

<script>

export default {
  data: () => ({
    isEditing: false
  }),
  created() {
    if (this.currentOutline.length == 0) {
      this.$store.dispatch('fetchCurrentOutline')
    }    
  },
  updated() {
    this.showChapter(0)
  },
  computed: {
    currentOutline() {
       return this.$store.getters.currentOutline
    }
  },
  methods: {
    createMilestone() {
       this.$bvModal.show('milestoneEditModal');
    },

  },
  
}
</script>

<style lang="scss" scoped>
  i {
    &.fa-coins {
      position: absolute; 
      left: 10px; 
      top: 50%; 
      transform: translateY(-50%);
      font-size: 1.5rem;
    }
  }
  .btn-light-green {
    background-color: #00d27a1c;
    border-color: #00d27b30;
    &:hover {
      background-color: #00d27a;
      color: whitesmoke;
    }
  }
</style>

