import Vue from 'vue'
import firebase from 'firebase'

const state = {
  netWealth: {}
}

const getters = {
  // return the income, expenses and netWealth of the current month
  currentMonthData: state => {
    var month = new Date().getMonth()
    var monthsData = Object.values(state.netWealth)[0].netWealth.months
    return Object.values(monthsData)[month]
  },

  // Get NetWealth of the current moment
  currentNetWealth: state => {
    var currentMonth = new Date().getMonth()
    var currentNetWealth = 0
    if (Object.values(state.netWealth).length != 0) {
      var monthsData = Object.values(state.netWealth)[0].netWealth.months
      currentNetWealth = Object.values(state.netWealth)[0].netWealth.startOfTheYear

      for (var i = 1; i <= currentMonth + 1; i++) {
        currentNetWealth += (monthsData[i].incomes - monthsData[i].spendings)
      }
    } else {
      currentNetWealth = 0 
    }

    return currentNetWealth
  },

  netWealthGraphData: state => {
    var currentMonth = new Date().getMonth()
    var netWealth = 0
    var graphData = []
    if (Object.values(state.netWealth).length != 0) {
      var monthsData = Object.values(state.netWealth)[0].netWealth.months
      netWealth = Object.values(state.netWealth)[0].netWealth.startOfTheYear

      for (var i = 1; i <= currentMonth + 1; i++) {
        netWealth += (monthsData[i].incomes - monthsData[i].spendings)
        graphData.push(netWealth)
      }
    } else {
      for (var k = 1; k <= currentMonth + 1; k++) {
        graphData.push(0)
      }    
    }
    
    return graphData
  }
}

const mutations = {

  fetchNetWealth(state, payload) {
    Vue.set(state.netWealth, payload.id, payload)
  },

  updateNetWealth(state, payload) {
    var date = new Date()
    date.setTime(payload.date)
    var month = date.getMonth()
    var monthsData = Object.values(state.netWealth)[0].netWealth.months
    var newNetWealth = Object.values(monthsData)[month].netWealth + parseFloat(payload.amount) 
    
    // Update netWealth register
    Vue.set(Object.values(monthsData)[month], 'netWealth', newNetWealth)

    // Update Incomes & spendings local registers
    console.log(payload)
    if (payload.transactionType == 'income') {
      var newIncomesValue = Object.values(monthsData)[month].incomes + parseFloat(payload.amount)
      Vue.set(Object.values(monthsData)[month], 'incomes', newIncomesValue)
    } else {
      var newSpendingsValue = Object.values(monthsData)[month].spendings + parseFloat(payload.amount * -1)
      Vue.set(Object.values(monthsData)[month], 'spendings', newSpendingsValue)
    }
  },

}

const actions = {

  async fetchNetWealth(context) {
    try {
      const uid = await context.dispatch('getUserId')
      firebase.firestore().collection(`/users/${uid}/netWealth`)
        .get().then(querySnapshot => {
          if (querySnapshot) {
            querySnapshot.forEach(item => {
              context.commit('fetchNetWealth', {
                id: item.id,
                netWealth: item.data()
              })
            }) 
          } else {
            console.log('Can\'t get the Accounts collection!')
          }
        })   
    } catch (error) {
      console.log(error)
    }
  },

  async updateNetWealth(context, payload) {
    const uid = await context.dispatch('getUserId')
    var date = new Date()
    date.setTime(payload.date)
    var month = date.getMonth() + 1
    var year = date.getFullYear()
    var transactionValue = payload.amount
    
    if (payload.transactionType === 'spending') {
      transactionValue = transactionValue * -1
    }
  
    // Update netWealth
    firebase.firestore().collection(`/users/${uid}/netWealth`).doc(`${year}`)
    .set({
      'months': {
        [month]: {
          netWealth: firebase.firestore.FieldValue.increment(payload.amount),
          [payload.transactionType + 's']: firebase.firestore.FieldValue.increment(transactionValue),
        }
      }
    },{merge: true})  

    context.commit('updateNetWealth', payload)
  },

  //!! Creating new test data
  async createNetWealth(context) {
    try {
      const uid = await context.dispatch('getUserId')
      firebase.firestore().collection(`/users/${uid}/netWealth`).doc('2019').set({
        year: 2019,
        startOfTheYear: 0,
        months: {
            1:  { incomes: 3420, spendings: 0, netWealth: 10 }, 
            2:  { incomes: 5560, spendings: 0, netWealth: 13 }, 
            3:  { incomes: 23530, spendings: 0, netWealth: 24 }, 
            4:  { incomes: 21330, spendings: 0, netWealth: 37 }, 
            5:  { incomes: 3430, spendings: 0, netWealth: 39 }, 
            6:  { incomes: 3320, spendings: 0, netWealth: 35 }, 
            7:  { incomes: 4560, spendings: 0, netWealth: 40 }, 
            8:  { incomes: 0, spendings: 0, netWealth: 46 }, 
            9:  { incomes: 0, spendings: 0, netWealth: 0 }, 
            10: { incomes: 0, spendings: 0, netWealth: 0 }, 
            11: { incomes: 0, spendings: 0, netWealth: 0 }, 
            12: { incomes: 0, spendings: 0, netWealth: 0 }, 
        },
        endOfTheYear: 0        
      })
    } catch (error) {
      
    }
  }
}

export default {
  state, getters, mutations, actions
}