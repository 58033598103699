import firebase from 'firebase'

import { banks } from '../banksList.js'

const state = {
}

const getters = {
}

const mutations = {

}

const actions = {
  async fetchAccounts(context) {
    try {
      const uid = await context.dispatch('getUserId')
      firebase.firestore().collection(`/users/${uid}/accounts`)
      .get().then(querySnapshot => {
        if (querySnapshot) {
          querySnapshot.forEach(item => {
            context.commit('fetchAccounts', {
              type: item.id,
              account: item.data()
            })
          })            
        } else {
          console.log('Can\'t get the Accounts collection!')
        }
      })   
    } catch (error) {
      console.log('Accounts load error ', error)
      context.commit('setError', error)
      throw error
    }
  },

  async createAccount(context, accountData) {
    try {
      const uid = await context.dispatch('getUserId')
      var account = ''
      firebase.firestore().collection(`/users/${uid}/accounts`).add(accountData)
      .then(docRef => {
        account = docRef
        firebase.firestore().collection(`/users/${uid}/accounts`).doc(docRef.id)
        .set({id: docRef.id}, {merge: true})
        console.log(account)
        accountData.id = docRef.id
        context.commit('addAccount', {
          accountId: docRef.id, 
          accountData: accountData
        })
        
      })   
      console.log(accountData) 
      return accountData  
    } catch (error) {
      context.commit('setError', error)
      throw error
    }
  },
  
  async editAccount(context, accountData) {
    try {
      const uid = await context.dispatch('getUserId')
      firebase.firestore().collection(`/users/${uid}/accounts`).doc(accountData.id).set({
        type: accountData.type,
        title: accountData.title,
        bank: accountData.bank,
        amount: accountData.amount,
        initialAmount: accountData.initialAmount
      }, {merge: true })
      .then(docRef => {
        context.commit('editAccount', {
          accountId: accountData.id, 
          accountData: accountData
        })
      })
    } catch (error) {
      context.commit('setError', error)
      throw error
    }
  },

  async deleteAccount(context, accountId) {
    try {
      const uid = await context.dispatch('getUserId')
      var batch = await firebase.firestore().batch()
      var accRef = await firebase.firestore().collection(`/users/${uid}/accounts`).doc(accountId)
      
      // Step 1: Add to deletetion all account's transactions
      var querySnapshot = await firebase.firestore().collection(`/users/${uid}/transactions`).where('account.id', '==', accountId).get()
      
      querySnapshot.forEach(doc => { // deletes upto 500 docs
        context.commit('removeTransaction', doc.id)
        batch.delete(doc.ref)
      })

      // Step 2: Add to deletion Account
      batch.delete(accRef)

      // Step 3: Execute deletion

      batch.commit().then(() => {
        context.commit('deleteAccount', accountId)
        context.commit('setEditAccountId', '')
        }
      )

      // todo: Need to add check if all transactions has deleted. Right now we can handle only 500 docs

    } catch (error) {
      context.commit('setError', error)
      throw error
    }
  },

  async fetchBanks(context) {
    try {
      const uid = await context.dispatch('getUserId')
      firebase.firestore().collection(`/banks`)
      .get().then(querySnapshot => {
        if (querySnapshot) {
          querySnapshot.forEach(item => {
            context.commit('fetchAccounts', {
              type: item.id,
              account: item.data()
            })
          })            
        } else {
          console.log('Can\'t get the Accounts collection!')
        }
      })   
    } catch (error) {
      console.log('Accounts load error ', error)
      context.commit('setError', error)
      throw error
    }
  },


  // создаем обновленный список банков
  async createBanksList(context) {
    try {
      await firebase.firestore().collection(`/banks`).doc('banks').set(banks, {merge: true})
      
      return true
    } catch (error) {
      console.log('Error to create banks list', error)
    }
  }
}

export default {
  state, getters, mutations, actions
}
