import Vue from 'vue'
import VueResource from 'vue-resource'
import VueMeta from 'vue-meta'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueAnalytics from 'vue-analytics' 
import BootstrapVue from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper' 
import VueMoment from 'vue-moment'
import Vuelidate from 'vuelidate'
import VueNumerals from 'vue-numerals'
import VueScreenSize from 'vue-screen-size'
import VueCurrencyfilter from 'vue-currency-filter'
import numeral from 'numeral'
import moment from 'moment'
import VueTypedJs from 'vue-typed-js'
import HighchartsVue from 'highcharts-vue'
import Highcharts from "highcharts";
import dataModule from "highcharts/modules/data";
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueNestable from 'vue-nestable'
import PerfectScrollbar from 'vue2-perfect-scrollbar'

import App from './App.vue'
import router from './router'
import store from './store'
import dateFilter from '@/filters/date.filter'
import messagePlugin from '@/utils/message.plugin'
import {func} from '@/utils/functions'
import titlePlugin from '@/utils/title.plugin'



import './assets/scss/theme-dark.scss'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'swiper/css/swiper.css'
// import 'flatpickr/dist/flatpickr.css'
import 'moment/locale/ru'
moment.locale('ru')

import 'numeral/locales/ru'
numeral.locale('ru')

//* Настройка подключения FIREBASE
import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
// var firebaseConfig = {
//   apiKey: "AIzaSyBUaUMNDvzxA6rD9qwCXvxBBJtyxQpniqg",
//   authDomain: "vue-bplan.firebaseapp.com",
//   databaseURL: "https://vue-bplan.firebaseio.com",
//   projectId: "vue-bplan",
//   storageBucket: "vue-bplan.appspot.com",
//   messagingSenderId: "543047472360",
//   appId: "1:543047472360:web:3a05bb12626c9a3eff255d",
//   measurementId: "G-W1F6WG2YMB"
// };
var firebaseConfig = {
  apiKey: "AIzaSyBUaUMNDvzxA6rD9qwCXvxBBJtyxQpniqg",
  authDomain: "vue-bplan.firebaseapp.com",
  databaseURL: "https://vue-bplan.firebaseio.com",
  projectId: "vue-bplan",
  storageBucket: "vue-bplan.appspot.com",
  messagingSenderId: "543047472360",
  appId: "1:543047472360:web:3a05bb12626c9a3eff255d",
  measurementId: "G-W1F6WG2YMB"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

dataModule(Highcharts);

Highcharts.setOptions({
  lang: {
    numericSymbols: [' тыс.', ' млн.']
  }
});

Vue.use(VueResource)
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueScreenSize)
Vue.use(BootstrapVue)
Vue.use(HighchartsVue)
Vue.use(VueTypedJs)
Vue.use(messagePlugin)
Vue.use(titlePlugin)
Vue.use(CKEditor)
Vue.use(VueNestable)
Vue.use(PerfectScrollbar)
Vue.use(numeral)
Vue.use(VueNumerals, { locale: 'ru' })
Vue.use(VueMoment, { moment })
Vue.use(VueAwesomeSwiper)
Vue.use(VueCurrencyfilter, {
  symbol : '',
  thousandsSeparator: " ",
  fractionCount: 1,
  fractionSeparator: ',',
  symbolPosition: 'back',
  symbolSpacing: true
})
Vue.use(VueYandexMetrika, {
  id: 62875723,
  router: router,
  env: process.env.NODE_ENV,
  clickmap:true,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:true
});
Vue.use(VueAnalytics, {
  id: '231761024',
  router,
  autoTracking: {
    screenview: true,
    skipSamePath: true
  }
});


Vue.filter('date', dateFilter)
Vue.prototype.$func = func

Vue.config.productionTip = false

let app

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})