<template>
    <nav class="navbar navbar-vertical navbar-expand-xl mt-8 border-top border-600">

        <div id="navbarVerticalCollapse"  class="mt-3">
            <ul class="navbar-nav flex-column mt-3">
              <li class="nav-item">
                <a class="nav-link">
                  <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                      <span class="fas fa-chart-line"></span>
                    </span>
                    <router-link to="/finance/revenue">
                      <span>Выручка</span>
                    </router-link>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <div class="nav-link" >
                  <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                      <i class="fas fa-industry"></i>
                    </span>
                    <router-link to="/finance/direct-costs" exact>
                      <span>Себестоимость</span>
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                      <i class="fas fa-poll"></i>
                    </span>
                     <router-link to="/finance/commercial-expenses">
                      <span>Коммерческие расходы</span>
                    </router-link>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                      <i class="far fa-building"></i>
                    </span>
                    <router-link to="/finance/common-expenses">
                      <span>Общехозяйственные расходы</span>
                    </router-link>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                      <i class="fas fa-car"></i>
                    </span>
                    <router-link to="/finance/assets">
                      <span>Основные средства</span>
                    </router-link>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                      <i class="fas fa-landmark"></i>
                    </span>
                    <router-link to="/finance/financing">
                      <span>Финансирование</span>
                    </router-link>
                  </div>
                </a>
              </li>
            </ul>
            
            <div class="px-3 px-xl-0">
              <hr class="border-300 my-2">
            </div>
            <div class="alert alert-light mt-5">
              <h6 class="alert-heading font-weight-bold border-bottom mb-2 pb-2">
                Финансовый прогноз
              </h6>
              <p class="fs--2 mb-2">
                Финансовый прогноз один из ключевых элементов бизнес-плана.  
              </p>
              <p class="fs--2 mb-2">
                От вас не требуется быть финансовым экспертом, просто последовательно заполните каждый из разделов (если требуется).
              </p>
              <hr>
              <p class="fs--2 mb-0">
                Мы позаботимся обо всем остальном! Составим прогнозные финансовые отчеты и расчетаем ключевые показатели проекта.
              </p>
            </div>
          </div>          

    </nav>

    
</template>

<script>

export default {
  data: () => ({
    isEditing: false
  }),
  created() {    
  },
  methods: {
    editAccounts() {
      this.isEditing = !this.isEditing
    }
  },
  components: {

    
  }
}
</script>

<style lang="scss" scoped>
  i {
    &.fa-coins {
      position: absolute; 
      left: 10px; 
      top: 50%; 
      transform: translateY(-50%);
      font-size: 1.5rem;
    }
  }
  .btn-light-green {
    background-color: #00d27a1c;
    border-color: #00d27b30;
    &:hover {
      background-color: #00d27a;
      color: whitesmoke;
    }
  }
</style>

