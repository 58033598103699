<template>
    <nav class="navbar navbar-vertical navbar-expand-xl navbar-light mt-8">
             
        <hr class="border-300 my-3" />

        <div class="h-100">
          
          <b-tabs content-class="perfect-scrollbar scrollbar h-100" 
          active-nav-item-class="" 
          justified>
            <b-tab no-body active @click="loadOutline">
              <template v-slot:title>
                <i class="far fa-file-alt"></i>
              </template>
              <perfect-scrollbar class="scroll-area">
              <div >
                <app-section2 
                  :topics="outlineTopics" 
                  itemClass="element px-2 py-1 my-1"
                /> 
              </div>
              </perfect-scrollbar>                
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="fas fa-th-list"></i>
              </template>
              <app-section2 
                  :topics="outlineTables" 
                  itemClass="element px-2 py-1 my-1"
                />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="fas fa-chart-bar"></i>
              </template>
              <app-section2 
                  :topics="outlineCharts" 
                  itemClass="element px-2 py-1 my-1"
                />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="fas fa-tools"></i>
              </template>
              <p class="font-proxima fs--1 mt-3">
                Добавление пользовательских элементов структуры бизнес-плана
              </p>
              <div class="d-flex align-items-center justify-content-between mb-2 pb-2 border-bottom">
                <draggable 
                  :list="customChapter"
                  :group="{ name: 'chapter', pull: 'clone', put: false }">
                  <h2 class="font-proxima font-weight-bold text-primary">
                    Новая глава
                  </h2>               
                </draggable>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2 pb-2 border-bottom">
                <draggable
                  :list="customSection"
                  :group="{ name: 'section',  pull: 'clone', put: false  }"
                  @change="log"> 
                  <h3 class="font-proxima text-900">
                    Новый раздел
                  </h3>                 
                </draggable>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2 mt-3 pb-2 border-bottom">
                <draggable
                  class="w-100"
                  :list="customTopic"
                  :group="{ name: 'topic',  pull: 'clone', put: false }"
                  @change="log"> 
                  <p class="topic py-1 px-2 mb-2">
                    <i class="far fa-file-alt fs-1 mr-2"></i>
                    Новый параграф
                  </p>                
                </draggable>
              </div>
            </b-tab>
            
          </b-tabs>
          
        </div>  
          
    </nav>

    
</template>

<script>
import draggable from "vuedraggable"

import Section2 from '../widgets/test/Section2'

export default {
  data: () => ({
    isEditing: false,
    scrollSettings: {},
    controlOnStart: true,
    customChapter:  [{id: '0',  title: 'Новая глава',     sections: []}],
    customSection:  [{id: '0',  title: 'Новый раздел',    topics: []}],
    customTopic:    [{id: '0',  title: 'Новый параграф',  type: 'textTopic'}],
    tables: [{ id: 'tb0', title: 'Доходы и расходы', type: 'table', system: true }],
    charts: [{ id: 'ch0', title: 'Годовые финансовые показатели', type: 'chart', system: true }]
  }),

  created() {
    if (this.currentOutline.length == 0) {
      this.$store.dispatch('fetchCurrentOutline')
    }
    
    if (this.outlineTopics.length == 0) {
      this.$store.dispatch('fetchOutlineTopics')
    }   
    
    if (this.outlineCharts.length == 0) {
      this.$store.dispatch('fetchOutlineTopics')
    }
  },
  mounted() {
    this.loadOutline()
  },
  components: {
    draggable,
    AppSection2: Section2,

  },

  computed: {
    currentOutline() {
      return this.$store.getters.currentOutline
    },
    outlineTopics() {
      return this.$store.getters.outlineTopics
    },
    outlineTables() {
      return this.$store.getters.outlineTables
    },
    outlineCharts() {
      return this.$store.getters.outlineCharts
    },
  },
  methods: {
    editAccounts() {
      this.isEditing = !this.isEditing
    },
    getOutlineTopics() {
      this.$store.dispatch('fetchOutlineTopics')
    },
    log: function(evt) {
      window.console.log(evt);
    },
    loadOutline() {
      if (this.outlineTopics.length == 0) {
        this.$store.dispatch('fetchOutlineTopics')
      } 
    },
    pullFunction() {
      return this.controlOnStart ? "clone" : true;
    },
    start({ originalEvent }) {
      this.controlOnStart = originalEvent.ctrlKey;
    }
  },
  
}
</script>

<style lang="scss" scoped>

.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 260px);
}

.topic {
  width: 100%;
  border: 1px solid rgba(145, 145, 145, 0.4);
  padding-top: 0.4rem !important;
}


.custom {
  background-color: #4d5969 !important;
}

i {
  &.fa-coins {
    position: absolute; 
    left: 10px; 
    top: 50%; 
    transform: translateY(-50%);
    font-size: 1.5rem;
  }
}
.btn-light-green {
  background-color: #00d27a1c;
  border-color: #00d27b30;
  &:hover {
    background-color: #00d27a;
    color: whitesmoke;
  }
}
</style>