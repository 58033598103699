import firebase from 'firebase/app'
// import moment from 'moment'

export default {
  actions: {
    async registration ({dispatch, commit}, {name, email, password}) {
      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
        const uid = await dispatch('getUserId')
        await firebase.firestore().collection(`/users`).doc(uid).set({
          userInfo: {
            id: uid,
            regDate: Date.now(),
            regCompleted: false,
            firstName: name,
            lastName: '',
            email: email,
            phoneNumber: '',
            avatarUrl: '',
            profileCover: '',
            subscriptionType: null,
            subscriptionTill: null,
            payments: null
          }
        });
        await dispatch('getUserInfo');
       } catch (error) {
        commit('setError', error)
        throw error
      }
    },

    async userRegCompleted(context) {
      const uid = await context.dispatch('getUserId');

      await firebase.firestore().collection(`/users`).doc(uid).set({
        userInfo: { regCompleted: true }
      }, {
        merge: true
      });

      context.commit('userRegCompleted');
    },

    // Вход пользователя по почте
    async login ({dispatch, commit}, {email, password}) { 
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
        await dispatch('getUserInfo')
        // TODO: Добавить уведомление о входе
      } catch (error) {
        commit('setError', error)
        throw error
      }
    },

    // Загружеам ID пользователя
    getUserId() { 
      let currentUser = firebase.auth().currentUser
      return currentUser ? currentUser.uid : null 
    },

    // Загружаем данные пользователя
    async getUserInfo(context) {
      try {
        const uid = await context.dispatch('getUserId')
        const userData = await firebase.firestore().collection(`/users`).doc(uid).get()  
        context.commit('setUserInfo', userData.data().userInfo)
        return (userData.data().userInfo)   
      } catch (error) {
        context.commit('setError', error)
        throw error
      }
    },

    // Обновляем данные пользователя
    async updateUserInfo(context, userInfo) {
      try {
        context.commit('clearDemoData')
        const uid = await context.dispatch('getUserId')
        await firebase.firestore().collection(`/users`).doc(uid).set({userInfo}, {merge: true})
      } catch (error) {
        context.commit('setError', error)
        throw error
      }
    },

    // Добавляем информацию о платеже, при нажатии на кнопку оплаты
    async addUserPayment(context, paymentData) {
      try {
        const uid = await context.dispatch('getUserId')
        
        // Создаем новый платежный документ
        const paymentRef = firebase.firestore().collection('payments').doc()

        paymentData.uid = uid
        await paymentRef.set(paymentData)

        // Записываем данные о платеже в платежи пользователя
        delete paymentData.uid

        console.log(uid)
        await firebase.firestore().collection('/users').doc(uid).set({
          userInfo: {
            payments: { 
              [paymentRef.id]: paymentData
            } 
          }
        }, {
          merge: true
        });

        return paymentRef.id;

      } catch (error) {
        context.commit('setError', error)
        throw error
      }
    },
    
    // Подтверждение проведения платежа
    async confirmUserPayment(context, orderID) {
      // Формируем ссылку на документ
      const paymentRef = firebase.firestore().collection('/payments').doc(orderID);
      window.console.log("Оплата прошла успешно!")
      // Устанавливаем флаг успешной оплаты в реестре платежей
      await paymentRef.set({ 
        confirmed: true
      }, {
        merge: true
      });

      const paymentDoc = await paymentRef.get()


      // Заносим запись об успешной оплате в данные пльзователя
      await firebase.firestore().collection('/users').doc(paymentDoc.data().uid).set({
        userInfo: {
          payments: { 
            [orderID]: {confirmed: true} 
          } 
        }
      }, {
        merge: true
      });


      // Обновляем данные пользователя
      await context.dispatch('getUserInfo')
    },

    async logout(context) {
      await firebase.auth().signOut()
      context.commit('clearUserInfo')
    }
  }
}