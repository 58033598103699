<template>
  <div class="container mobile">

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="bg-dark pt-6 pb-4">

      <div class="container">
        <div class="position-absolute btn-back-to-top" style="top: -4rem;">
          <a class="text-light" href="#banner" data-fancyscroll="data-fancyscroll" style="transform: rotate(45deg);">
            <i class="fas fa-chevron-up" ></i>
          </a>
        </div>
        <div class="row">
          <div class="col-12 col-md-3 mb-3">
            <h6 class="text-uppercase text-secondary mb-3 font-proxima">
              СОВРЕМЕННЫЙ и <br> 
              ИНТУИТИВНО ПОНЯТНЫЙ <br>
              БИЗНЕС-ПЛАНИРОВЩИК
            </h6>
            <p class="text-400 fs--1 pr-3">
              <img 
                class="mr-0" 
                src="@/assets/img/illustrations/bplan-logo.png" style="filter: contrast(120%);" alt="" width="20" />
              <strong>ТВОЙ</strong>
              <small class="text-400 text-uppercase"> бизнес-план</small> - 
              все, что нужно для создания профессионального бизнес-плана. 
            </p>
          </div>
          <div class="col-12 col-md-3 mb-3">
            <h6 class="text-uppercase text-secondary mb-3 font-proxima mr-4" 
            style="border-bottom: 1px solid #5e6e82">
              ИНФОРМАЦИЯ
            </h6>
            <ul class="list-unstyled fs--1 mb-0">
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  О компании
                </router-link> 
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Подробнее о сервисе
                </router-link>
              </li>
              <li class="mb-1">
                <a href="https://blog.your-plan.ru" 
                  class="text-600 text-uppercase" >
                  Блог - "<strong>ТВОЙ</strong><small class="text-500"> бизнес-план</small>"
                </a>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/about">
                  Обратная связь
                </router-link>
              </li>
              <hr class="mr-8">
              <li class="mb-1">
                <strong class="text-600">ИП "Лавренюк Д.И."</strong><br>
                <small class="text-600" style="position: relative; top: -0.4rem;">
                  ИНН: 250206796009 <br>
                  <span>692751, РОССИЯ, ПРИМОРСКИЙ КРАЙ,</span><br>
                  <span style="position: relative; top: -0.4rem;">г.АРТЕМ, ул. НОРИЛЬСКАЯ, д. 14, оф. 31</span><br>
                </small>
                <span class="text-600">
                  Тел: 8 (495) 660-08-70 <br>
                  Email: 
                  <a href="mailto: info@rsystems.io">
                    info@rsystems.io
                  </a>
                </span>                
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-3 mb-3">
            <h6 class="text-uppercase text-secondary mb-3 font-proxima mr-4" 
              style="border-bottom: 1px solid #5e6e82">
              <strong>ТВОЙ</strong><small class="text-500"> бизнес-план</small>
            </h6>
            <ul class="list-unstyled fs--1 mb-0">
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Часто задваемые вопросы (ЧАВо)
                </router-link> 
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Отзывы
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Центр помощи
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/about">
                  Что нового?
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/about">
                  Бюджетирование и управление <br>
                  бизнесом
                </router-link>
              </li>
            </ul>
            <h6 class="d-none text-uppercase text-secondary mb-3 font-proxima mr-4 mt-4" style="border-bottom: 1px solid #5e6e82">
              ПОМОЩЬ ЭКСПЕРТОВ
            </h6>
            <ul class="list-unstyled fs--1 mb-0">
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Профессиональные консультанты
                </router-link> 
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Помощь в написании плана
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-3 mb-3">
            <h6 class="text-uppercase text-secondary mb-3 font-proxima mr-4" style="border-bottom: 1px solid #5e6e82">
              Бизнес планирование
            </h6>
            <ul class="list-unstyled fs--1 mb-0">
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для Mac и Apple
                </router-link> 
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для получение банковского займа
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для открытия нового ресторана
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/about">
                  Шаблон бизнес-плана
                </router-link>
              </li>
            </ul>
            <h6  
              class="text-uppercase text-secondary mb-3 font-proxima mr-4 mt-4" style="border-bottom: 1px solid #5e6e82">
              РЕШЕНИЯ
            </h6>
            <ul class="list-unstyled fs--1 mb-0">
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для владельцев бизнеса
                </router-link> 
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для бизнес консультантов
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для преподавателей
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для мылых и средних компаний
                </router-link>
              </li>
              <li class="mb-1">
                <router-link class="text-600" to="/#">
                  Для стартапов
                </router-link>
              </li>
            </ul>
          </div>
          

        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <footer class="py-0 bg-dark py-1 border-top border-1000">
      <div class="row no-gutters justify-content-between fs--1 mt-2 mb-3">
        <div class="col-12 col-sm-auto text-center">
          <p class="mb-0 text-600">
            Все права защищены <span class="d-none d-sm-inline-block">| </span>
            <br class="d-sm-none" /> 2018 - 2020 &copy; 
            <a href="https://rsystems.io">
              R'systems
            </a>
            - передовые технологии!
          </p>
        </div>
        <div class="col-12 col-sm-auto text-center">
          <p class="mb-0 text-600">beta v0.22.5</p>
        </div>
        <a href="https://freekassa.ru" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn.freekassa.ru/banners/small-dark-1.png" title="Прием платежей">
        </a>
      </div>
    </footer>
  </div>
</template>

<script>


export default {

}
</script>

<style lang="scss" scoped>
@media (max-width: 400px) {
  .mobile {
    padding-left: 0.2rem;
    padding-right: 0.2rem; 
  }
}
</style>