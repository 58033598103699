<template>
  <div>

    

    <main class="main pt-4"
      style="margin-bottom: 0; margin-top: 60px;">
      <div class="container" data-layout="container">
        
        <blog-navbar />

        <div class="row">
          
          <div class="col-md-9 col-sm-12 mobile">
            <router-view></router-view>
          </div>
          
          <div class="col-md-3 col-sm-12 mobile ml-auto">
            <aside class="sidebar">
              <div class="card mb-4">
                <div class="card-body">
                  <p class="card-text fs--1">
                    Советы и рекомендации, которые помогут развивать Ваш бизнес лучше и более умело. 
                  </p>
                </div>
              </div><!-- /.card -->
            </aside>

            <aside class="sidebar sidebar-sticky">
              <div class="card mb-4">
                <div class="card-body px-3">
                  <h4 class="card-title">Категории</h4>
                  <router-link to="/blog?category=management"
                    class="btn btn-outline-secondary btn-sm mb-1 btn-block text-left">
                    <i class="fas fa-business-time mr-2"></i>
                    Управление
                  </router-link>
                  <router-link to="/blog?category=writing"
                    class="btn btn-outline-secondary btn-sm mb-1 btn-block text-left">
                    <i class="fas fa-pen-square mr-2"></i>
                    Написание бизнес-плана
                  </router-link>
                  <router-link to="/blog?category=metrics" 
                    class="btn btn-outline-secondary btn-sm mb-1 btn-block text-left">
                    <i class="fas fa-project-diagram mr-2"></i>
                    Ключевые показатели
                  </router-link>
                  <router-link to="/blog?category=productivity" 
                    class="btn btn-outline-secondary btn-sm mb-1 btn-block text-left">
                    <i class="fas fa-tasks mr-2"></i>
                    Продуктивность
                  </router-link>
                  <router-link to="/blog?category=business-psychology" 
                    class="btn btn-outline-secondary btn-sm mb-1 btn-block text-left">
                    <i class="fas fa-brain mr-2"></i>
                    Психология бизнеса
                  </router-link>
                </div>
              </div><!-- /.card -->
              <div v-if="false"
                class="card mb-4">
                <div class="card-body">
                  <h4 class="card-title">Интересная запись</h4>

                  <a href="post-image.html" class="d-inline-block">
                    <h4 class="h6">The blind man</h4>
                    <img class="card-img" src="img/articles/2.jpg" alt="" />
                  </a>
                  <time class="timeago" datetime="2019-10-03 20:00">3 october 2019</time> in Lifestyle

                  <a href="post-image.html" class="d-inline-block mt-3">
                    <h4 class="h6">Crying on the news</h4>
                    <img class="card-img" src="img/articles/3.jpg" alt="" />
                  </a>
                  <time class="timeago" datetime="2019-07-16 20:00">16 july 2019</time> in Work

                </div>
              </div><!-- /.card -->
            </aside>
          </div>
        </div>
      </div>

    <blog-newsletter />
    <footer-menu />  
  </main>

  

  </div>
</template>

<script>
import BlogNavbar from '../../views/blog/BlogNavbar';
import FooterMenu from '../../views/FooterMenu';
import BlogNewsletter from '../../views/blog/BlogNewsletter';

export default {
  components: {
    BlogNavbar,
    FooterMenu,
    BlogNewsletter
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 400px) {
  .mobile {
    padding-left: 0.2rem;
    padding-right: 0.2rem; 
  }
}
</style>
